import { s } from 'react-native-wind';
import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native';
import { Config } from '../config';

type ParagraphBoldProps = {
  children: ReactNode;
  style?: StyleProp<TextStyle>;
  numberOfLines?: number;
  onPress?: () => void;
};

export const ParagraphBold: React.FC<ParagraphBoldProps> = ({
  children,
  style,
  numberOfLines,
  onPress,
}) => {
  const paragraphStyle = StyleSheet.flatten([styles.text, style]);
  return (
    <Text
      numberOfLines={numberOfLines}
      onPress={onPress}
      style={paragraphStyle}
    >
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: Config.fontMedium,
    ...s`text-base text-text-primary`,
  },
});
