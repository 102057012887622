import { CallbackTranslation } from '../locale/localizationContext';

export function getLeaderboardMessageTranslation(
  t: CallbackTranslation,
  position: number
) {
  if (position === 1) return t('leaderboard.firstArrived');
  if (position === 2) return t('leaderboard.secondArrived');
  if (position === 3) return t('leaderboard.thirdArrived');
  if (position === 0) return null;

  return t('leaderboard.parteciped');
}
