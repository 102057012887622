export type FeedbackValue = 1 | 2 | 3 | 4 | null;

export enum FeedbackCategoryType {
  Selection = 'SELECTION',
}

export type FeedbackCategory = {
  id: string;
  title: string;
  icon: {
    dark: string;
    light: string;
  };
  sorting: number;
};

export type Feedback = {
  categoryFeedbackType: FeedbackCategoryType;
  categoryFeedback: Record<string, FeedbackValue>;
  comment: null;
  companyId: string;
  createdAt: string;
  createdAtUT: string;
  meta: null;
  teamId: string;
  timezone: string;
  updatedAt: string;
  updatedAtUT: string;
  userId: string;
  value: FeedbackValue;
  sentiment?: Sentiment;
  voteAnonymous: 1 | 0;
};

export type CastFeedbackRequest = {
  teamId: string;
  value: FeedbackValue;
  voteAnonymous?: boolean;
  categoryFeedback?: Record<string, FeedbackValue>;
  comment?: string;
};

export interface CastFeedbackResponse {
  item: {
    badgeProgress: BadgeProgress;
  };
}

export interface BadgeProgress {
  from: FromOrTo;
  to: FromOrTo;
}

export interface FromOrTo {
  image: string;
  minProgress: number;
}

export type GetFeedbacksRequest = {
  teamId: string;
  from: string;
  to: string;
  userId?: string;
};

export type GetFeedbacksResponse = {
  items: Feedback[];
  next: string;
};

export type GetUserFeedbacksRequest = {
  from: string;
  to: string;
};

export type GetUserFeedbackResponse = {
  items: Feedback[];
  next: string;
};

export enum SentimentCategory {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE',
  Neutral = 'NEUTRAL',
  Mixed = 'MIXED',
}

export interface Sentiment {
  mainSentiment: SentimentCategory;
  neutral: number;
  negative: number;
  mixed: number;
  positive: number;
  keyPhrases?: KeyPhrasesEntity[] | null;
}
export interface KeyPhrasesEntity {
  EndOffset: number;
  BeginOffset: number;
  Score: number;
  Text: string;
}
