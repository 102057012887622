import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useStorageProvider } from '../../../context/StorageProviderContext/useStorageProvider';
import {
  useDeleteProfile,
  useUpdateProfileMutation,
  useUploadProfileImageMutation,
} from '../../../hooks/api/user.api';
import uuid from 'react-native-uuid';
import { useClearCache } from '../../../hooks/api/utils';
import { EDIT_PROFILE_SCREEN } from '../../../navigation/authenticated/authenticated.types';
import { StorageKeys } from '../../../services/StorageProvider/IStorageProvider';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import { resetGeneralToDefault } from '../../../redux/general/general.slice';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ScreensProps } from '../../../navigation/core/types';

import useUserData from '../../../hooks/useUserData';

export const useProfileController = (
  navigation: NativeStackNavigationProp<ScreensProps, 'Qomprendo | Profile'>
) => {
  const storageProvider = useStorageProvider();
  const dispatch = useDispatch();
  const clearCache = useClearCache();

  const [isDeleteModalOpen, setIsModalOpen] = React.useState(false);
  const [imageUuid, setImageUuid] = React.useState<string>(uuid.v4() as string);
  const [imageProfile, setImageProfile] = React.useState<string>();
  const [isProfileImageExist, setIsProfileImageExist] =
    React.useState<boolean>(true);
  const [isDeleteConfirmedModalOpen, setIsDeleteConfirmedModalOpen] =
    React.useState(false);
  const [isAnonymousVote, setIsAnonymousVote] = React.useState<boolean>();
  const { user: myDetails } = useUserData();
  const { mutateAsync: updateProfileApiCall } = useUpdateProfileMutation();
  const { mutateAsync: deleteProfile } = useDeleteProfile();
  const { mutateAsync: uploadProfileImage } = useUploadProfileImageMutation();

  const toggleAnonymousFeedback = React.useCallback(async () => {
    const tmpNonymousState = isAnonymousVote;
    setIsAnonymousVote(!tmpNonymousState);
    try {
      await updateProfileApiCall({ voteAnonymous: !tmpNonymousState });
    } catch (e) {
      setIsAnonymousVote(tmpNonymousState);
    }
  }, [isAnonymousVote, updateProfileApiCall]);

  React.useEffect(() => {
    setIsAnonymousVote(myDetails?.voteAnonymous);
  }, [myDetails?.voteAnonymous]);

  const toggleDeleteModal = React.useCallback(() => {
    setIsModalOpen((pv) => !pv);
  }, []);

  const toggleConfirmedModal = React.useCallback(() => {
    setIsDeleteConfirmedModalOpen((pv) => !pv);
  }, []);

  const onProfileDelete = React.useCallback(async () => {
    toggleDeleteModal();
    await deleteProfile();
    toggleConfirmedModal();
  }, [deleteProfile, toggleConfirmedModal, toggleDeleteModal]);

  const navigateToEditProfile = React.useCallback(
    () => navigation.navigate(EDIT_PROFILE_SCREEN),
    [navigation]
  );

  const signOut = React.useCallback(async () => {
    await Promise.all([
      storageProvider.removeItem(StorageKeys.ACCESS_TOKEN_KEY),
      storageProvider.removeItem(StorageKeys.REFRESH_TOKEN_KEY),
    ]);
    clearCache();
    dispatch(resetGeneralToDefault());
  }, [clearCache, dispatch, storageProvider]);

  const requestAccountDelete = React.useCallback(() => {
    toggleDeleteModal();
  }, [toggleDeleteModal]);

  const chooseProfileImage = React.useCallback(async () => {
    const pickerResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
      allowsMultipleSelection: false,
    });

    if (!pickerResult.canceled && pickerResult.assets.length > 0) {
      const manipResult = await manipulateAsync(
        pickerResult.assets[0].uri,
        [],
        {
          compress: 0.1,
          format: SaveFormat.PNG,
        }
      );
      await uploadProfileImage({
        fileExt: SaveFormat.PNG,
        localFileUri: manipResult.uri,
      });
      setIsProfileImageExist(true);
      setImageUuid(uuid.v4() as string);
      setImageProfile(manipResult.uri);
    }
  }, [uploadProfileImage]);

  return {
    isDeleteModalOpen,
    chooseProfileImage,
    requestAccountDelete,
    signOut,
    navigateToEditProfile,
    onProfileDelete,
    myDetails,
    imageUuid,
    imageProfile,
    isProfileImageExist,
    isDeleteConfirmedModalOpen,
    toggleConfirmedModal,
    toggleDeleteModal,
    setIsProfileImageExist,
    isAnonymousVote,
    toggleAnonymousFeedback,
  };
};
