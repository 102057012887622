import { s } from 'react-native-wind';
import React, { forwardRef, ReactNode, useCallback, useMemo } from 'react';
import { BottomSheet } from '../portability/components/BottomSheet/BottomSheet';
import { BottomSheetBackdrop } from '../portability/components/BottomSheetBackdrop/BottomSheetBackdrop';
import { BottomSheetView } from '../portability/components/BottomSheetView/BottomSheetView';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useBottomSheetActions } from '../hooks/useBottomSheetActions';
import { StyleProp, View, ViewStyle } from 'react-native';

type Edge = 'top' | 'bottom' | 'right' | 'left';

type BottomSheetBackgroundProps = {
  style?: StyleProp<ViewStyle>;
};

const BottomSheetBackground: React.FC<BottomSheetBackgroundProps> = ({
  style,
}) => {
  return (
    <View
      style={[
        s`rounded-bl-none rounded-br-none rounded-tr-2xl rounded-tl-2xl bg-white`,
        style,
      ]}
    />
  );
};

export type BottomSheetRef = {
  expand: () => void;
  close: () => void;
};

type UmBottomSheetProps = {
  height?: string | number;
  safeAreaEdges?: Edge[];
  isContentHeight?: boolean;
  onClose?: () => void;
  children?: ReactNode | null;
  disableClose?: boolean;
  startOpen?: boolean;
};

export const UmBottomSheet: React.ForwardRefExoticComponent<
  UmBottomSheetProps & React.RefAttributes<BottomSheetRef>
> = forwardRef(
  (
    {
      height = '50%',
      safeAreaEdges,
      onClose,
      children,
      isContentHeight,
      disableClose,
      startOpen,
    },
    forwardedRef
  ) => {
    const { bottomSheetRef } = useBottomSheetActions(forwardedRef);

    const snapPoints = useMemo(() => {
      return isContentHeight ? undefined : [height];
    }, [height, isContentHeight]);

    const customSafeAreaEdges: Edge[] = useMemo(
      () => (safeAreaEdges ? safeAreaEdges : ['bottom', 'right', 'left']),
      [safeAreaEdges]
    );

    const renderBackdrop = useCallback(
      (props) => (
        <BottomSheetBackdrop
          {...props}
          appearsOnIndex={0}
          disappearsOnIndex={-1}
          opacity={0.3}
          pressBehavior={disableClose ? 'none' : undefined}
        />
      ),
      [disableClose]
    );

    return (
      <BottomSheet
        ref={bottomSheetRef}
        enablePanDownToClose={!disableClose}
        style={s`rounded-bl-none rounded-br-none`}
        onClose={onClose}
        index={startOpen ? 0 : -1}
        backgroundComponent={(props) => <BottomSheetBackground {...props} />}
        keyboardBehavior="fillParent"
        backdropComponent={renderBackdrop}
        enableDynamicSizing={isContentHeight}
        snapPoints={snapPoints}
      >
        <BottomSheetView>
          <SafeAreaView
            edges={customSafeAreaEdges}
            style={s`flex px-8 pt-2 pb-4`}
          >
            {children}
          </SafeAreaView>
        </BottomSheetView>
      </BottomSheet>
    );
  }
);
