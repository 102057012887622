import { s } from 'react-native-wind';
import React, { ReactNode } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';
import { Config } from '../config';

type ParagraphProps = {
  children: ReactNode;
  numberOfLines?: number;
  style?: TextStyle;
};

export const Paragraph: React.FC<ParagraphProps> = ({
  children,
  style,
  numberOfLines,
}) => {
  const paragraphStyle = StyleSheet.flatten([styles.text, style]);
  return (
    <Text numberOfLines={numberOfLines} style={paragraphStyle}>
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: Config.fontRegular,
    ...s`text-base text-text-primary`,
  },
});
