import * as React from 'react';
import { View } from 'react-native-animatable';
import {
  CheckIcon,
  MinusIcon,
  XMarkIcon,
} from 'react-native-heroicons/outline';
import { s } from 'react-native-wind';

export enum DotsType {
  Complete = 'complete',
  Partial = 'partial',
  Missing = 'missing',
  Unnecessary = 'unnecessary',
}

type DaysInRowDotsProps = {
  type: DotsType;
};

const DaysInRowDots: React.FC<DaysInRowDotsProps> = ({ type }) => {
  const containerClass = React.useMemo(() => {
    switch (type) {
      case DotsType.Complete:
        return 'bg-primary border-primary';
      case DotsType.Partial:
        return 'bg-secondary border-secondary';
      case DotsType.Missing:
        return 'bg-red-vivid border-red-vivid';
      case DotsType.Unnecessary:
        return 'bg-card border-text-secondary';
    }
  }, [type]);

  const icon = React.useMemo(() => {
    switch (type) {
      case DotsType.Complete:
        return <CheckIcon color="#fff" />;
      case DotsType.Partial:
        return <MinusIcon color="#fff" />;
      case DotsType.Missing:
        return <XMarkIcon color="#fff" />;
      case DotsType.Unnecessary:
        return null;
    }
  }, [type]);

  return (
    <View
      style={s`rounded-full w-8 h-8 items-center justify-center border ${containerClass}`}
    >
      {icon}
    </View>
  );
};

export default DaysInRowDots;
