import * as React from 'react';
import { View } from 'react-native';
import { s } from 'react-native-wind';
import { FeedbackCategory } from '../../../api/feedback/feedback.models';
import { Subtitle, UmFeedbackCategory } from '../../../components';
import { useLanguage } from '../../../locale';
import { Image } from 'expo-image';

type UmFeedbackCategorySectionProps = {
  companyCategories: FeedbackCategory[];
  selectedPositiveCategories: string[];
  selectedNegativeCategories: string[];
  togglePositiveCategoryGenerator: (categoryId: string) => () => void;
  toggleNegativeCategoryGenerator: (categoryId: string) => () => void;
};

const UmFeedbackCategorySection: React.FC<UmFeedbackCategorySectionProps> = ({
  companyCategories,
  selectedNegativeCategories,
  selectedPositiveCategories,
  toggleNegativeCategoryGenerator,
  togglePositiveCategoryGenerator,
}) => {
  const [t] = useLanguage();

  const isCategorySectionCompleted =
    selectedPositiveCategories.length > 0 ||
    selectedNegativeCategories.length > 0;

  return (
    <>
      <Subtitle
        style={s`${
          isCategorySectionCompleted ? 'text-primary' : 'text-text-secondary'
        } text-sm`}
      >
        (20 pt.)
      </Subtitle>
      <Subtitle style={s`mt-2 text-base`}>
        {t('feedback.goodAreasFeedback')}
      </Subtitle>
      <View style={s`flex-row flex-wrap`}>
        {companyCategories.map((c) => {
          const isCategorySelected = selectedPositiveCategories.find(
            (fc) => c.id === fc
          );
          const isCategoryDisabled = selectedNegativeCategories.find(
            (fc) => c.id === fc
          );
          const toggleCategory = togglePositiveCategoryGenerator(c.id);
          return (
            <UmFeedbackCategory
              key={`feedback-category-${c.id}`}
              icon={
                <Image
                  style={s`w-7 h-7`}
                  source={isCategorySelected ? c.icon.light : c.icon.dark}
                  contentFit="cover"
                  transition={300}
                />
              }
              label={c.title}
              value={4}
              containerStyle={s`w-1/3 mt-6 px-1`}
              isSelected={!!isCategorySelected}
              onPress={!isCategoryDisabled ? toggleCategory : undefined}
              isDisabled={!!isCategoryDisabled}
            />
          );
        })}
      </View>
      <Subtitle style={s`mt-4 text-base`}>
        {t('feedback.badAreasFeedback')}
      </Subtitle>
      <View style={s`flex-row flex-wrap`}>
        {companyCategories.map((c) => {
          const isCategorySelected = selectedNegativeCategories.find(
            (fc) => c.id === fc
          );
          const isCategoryDisabled = selectedPositiveCategories.find(
            (fc) => c.id === fc
          );
          const toggleCategory = toggleNegativeCategoryGenerator(c.id);
          return (
            <UmFeedbackCategory
              key={`feedback-category-${c.id}`}
              icon={
                <Image
                  style={s`w-7 h-7`}
                  source={isCategorySelected ? c.icon.light : c.icon.dark}
                  contentFit="cover"
                  transition={300}
                />
              }
              label={c.title}
              value={1}
              containerStyle={s`w-1/3 mt-6 px-1`}
              isSelected={!!isCategorySelected}
              onPress={!isCategoryDisabled ? toggleCategory : undefined}
              isDisabled={!!isCategoryDisabled}
            />
          );
        })}
      </View>
    </>
  );
};

export default UmFeedbackCategorySection;
