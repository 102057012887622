import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
import { IconProps } from './types';

type TreeBadgeIconProps = IconProps & SvgProps;

const TreeBadgeIcon = ({
  width,
  height,
  size,
  ...props
}: TreeBadgeIconProps) => (
  <Svg
    viewBox="0 0 20 20"
    width={width || size}
    height={height || size}
    fill="none"
    {...props}
  >
    <Path
      fill="#6AC982"
      d="M18.956 9.396c0-1.399-.85-2.598-2.063-3.11A3.01 3.01 0 0 0 12.898 2.2a3.01 3.01 0 0 0-5.797 0 3.01 3.01 0 0 0-3.995 4.086 3.374 3.374 0 0 0 1.159 6.48c.25.905 1.215 1.58 2.368 1.58.654 0 1.247-.218 1.683-.57.437.352 1.03.57 1.684.57.653 0 1.246-.218 1.683-.57.437.352 1.03.57 1.684.57 1.153 0 2.118-.675 2.368-1.58a3.374 3.374 0 0 0 3.22-3.37Z"
    />
    <Path
      fill="#097C09"
      d="M9.82.006a2.948 2.948 0 0 0-.111.008l-.034.004a2.981 2.981 0 0 0-.277.043c.79.16 1.53.71 1.998 1.405a1.667 1.667 0 0 0 1.503.733A3.01 3.01 0 0 0 9.856.004l-.037.002Z"
    />
    <Path
      fill="#5CAB70"
      d="M16.894 6.286a3.01 3.01 0 0 0-3.277-4.33 3.011 3.011 0 0 1 2.072 4.33 3.374 3.374 0 0 1-1.159 6.48c-.205.741-.89 1.328-1.766 1.516.193.041.395.063.603.063 1.153 0 2.118-.674 2.368-1.58a3.374 3.374 0 0 0 1.159-6.48Z"
    />
    <Path
      fill="#009000"
      d="M7.845 13.277c-.272.46-1.397.975-1.814 1.004.046.01.092.019.14.026l.01.002c.048.008.096.014.144.02h.004a3 3 0 0 0 .304.016 2.71 2.71 0 0 0 1.564-.48l.075-.054-.378-.62-.05.086Z"
    />
    <Path
      fill="#BF6101"
      d="m11.505 14.046 1.29-2.035a.386.386 0 0 0-.6-.478l-.95.962a.187.187 0 0 1-.318-.156l.28-2.07a.386.386 0 0 0-.357-.436l-.262-.018a.386.386 0 0 0-.405.318l-.5 2.833a.187.187 0 0 1-.328.085l-1.561-1.92a.386.386 0 0 0-.449-.112l-.273.115a.386.386 0 0 0-.181.554l1.604 2.674c.232.387.328.842.27 1.29C8.557 17.255 8.3 18.768 7.242 20h5.516c-1.132-1.319-1.411-2.96-1.56-4.689-.04-.444.068-.889.307-1.265Z"
    />
    <Path
      fill="#9A4D01"
      d="M12.795 12.01a.386.386 0 0 0-.6-.477l-.424.43-.526.532a.187.187 0 0 1-.318-.156l.28-2.07a.385.385 0 0 0-.34-.434l-.785 4.881a2.042 2.042 0 0 0-.036.595c.15 1.73.429 3.37 1.56 4.689h1.152c-1.131-1.319-1.41-2.96-1.56-4.689-.039-.444.069-.889.307-1.265l1.29-2.035Z"
    />
    <Path
      fill="#84DD9A"
      d="M9.427 2.363a2.09 2.09 0 0 0-1.901 1.223 2.09 2.09 0 0 0-3.014 2.752 2.09 2.09 0 0 0 .32 4.155c1.154 0 6.685-4.886 6.685-6.04a2.09 2.09 0 0 0-2.09-2.09Z"
    />
  </Svg>
);
export default TreeBadgeIcon;
