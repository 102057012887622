// --------------------------------
// Types
// --------------------------------

export interface Tree {
  top: number;
  left: number;
  zIndex: number;
}

interface Options {
  count: number;
  maxAttempts?: number;
  minDistance: number;
  xMax: number;
  xMin?: number;
  yMax: number;
  yMin?: number;
}

// --------------------------------
// Functions
// --------------------------------

const shuffle = <T>(_array: Array<T>): Array<T> => {
  const array = [..._array];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const isValidPosition = (
  tree: Tree,
  trees: Array<Tree>,
  minDistance: number
) => {
  for (const point of trees) {
    const distance = Math.sqrt(
      Math.pow(point.top - tree.top, 2) + Math.pow(point.left - tree.left, 2)
    );

    if (distance < minDistance) {
      return false;
    }
  }

  return true;
};

export const placeTrees = (
  {
    count,
    maxAttempts = 5,
    minDistance,
    xMax,
    xMin = 0,
    yMax,
    yMin = 0,
  }: Options,
  initialTrees: Array<Tree> = []
): Array<Tree> => {
  const trees = shuffle(initialTrees).slice(0, count);

  let attemptsLeft = maxAttempts;
  let distance = minDistance;

  while (trees.length < count) {
    distance = attemptsLeft <= 0 ? distance - 10 : distance;

    const top = Math.floor(Math.random() * (yMax - yMin) + yMin);
    const left = Math.floor(Math.random() * (xMax - xMin) + xMin);
    const tree = { top, left, zIndex: 0 };

    if (isValidPosition(tree, trees, distance)) {
      trees.push(tree);
      attemptsLeft = maxAttempts;
      distance = minDistance;
    } else {
      attemptsLeft--;
    }
  }

  return trees
    .sort((p1, p2) => p1.top - p2.top)
    .map((p, i) => ({ ...p, zIndex: i }));
};
