import * as React from 'react';
import { Route, Stack } from '../core/types';
import { createStack } from '../core/createStack';
import {
  AuthenticationPages,
  AUTHENTICATION_SCREEN,
  AUTH_CODE_SCREEN,
  INTRO_SCREEN,
} from './authentication.types';
import { AuthenticationScreen, IntroScreen } from '../../screens';
import { AuthCodeScreen } from '../../screens/authCode/authCode.screen';

export const AUTHENTICATION_STACK_NAME = 'Qomprendo | Welcome';

export const authenticationRoutes: Route<AuthenticationPages>[] = [
  { name: AUTHENTICATION_SCREEN, component: AuthenticationScreen },
  { name: INTRO_SCREEN, component: IntroScreen },
  { name: AUTH_CODE_SCREEN, component: AuthCodeScreen },
];

export const authenticationStack: Stack<AuthenticationPages> = {
  routes: authenticationRoutes,
  initialScreen: INTRO_SCREEN,
};

export const AuthenticationStackComponent = createStack(authenticationStack);

export const AuthenticationStack = () => {
  return <AuthenticationStackComponent />;
};
