import { s } from 'react-native-wind';
import React from 'react';
import { Feedback } from '../api/feedback/feedback.models';
import { DateData } from 'react-native-calendars';
import dayjs from 'dayjs';
import { View, TouchableOpacity, Text } from 'react-native';
import { Paragraph } from './Paragraph';
import { DayProps } from 'react-native-calendars/src/calendar/day';
import { FontAwesome } from '@expo/vector-icons';
import { Config } from '../config';

type CalendarDayProps = {
  monthFeedbacks: Feedback[];
  onDayClick: (feedback: Feedback[]) => void;
};

export const CalendarDay: React.FC<
  DayProps & { date?: DateData | undefined } & CalendarDayProps
> = ({ date, state, monthFeedbacks, onDayClick }) => {
  const isDisabled = state === 'disabled';
  if (isDisabled) return <View />;

  const dateData = date as DateData;
  const dayFeedbacks = monthFeedbacks?.filter(
    (f) => dayjs(f.createdAt).format('YYYY-MM-DD') === dateData.dateString
  );

  const value = Math.round(
    dayFeedbacks?.reduce((acc, curr) => {
      return acc + (curr.value ?? 0) / dayFeedbacks.length;
    }, 0)
  );

  const isSingle = dayFeedbacks?.length === 1;
  const isMultiple = dayFeedbacks?.length > 1;
  const dayFeedback = dayFeedbacks?.[0];
  let viewColorClass = '';
  let iconColor: string = Config.colorPrimary;

  switch (value) {
    case 1:
      viewColorClass = 'bg-danger';
      iconColor = Config.colorDanger;
      break;
    case 2:
      viewColorClass = 'bg-sad';
      iconColor = Config.colorSad;
      break;
    case 3:
      viewColorClass = 'bg-happy';
      iconColor = Config.colorHappy;
      break;
    case 4:
      viewColorClass = 'bg-primary';
      iconColor = Config.colorPrimary;
      break;
    case null:
      viewColorClass = 'bg-card';
      break;
    default:
      viewColorClass = '';
      break;
  }

  return (
    <TouchableOpacity
      onPress={() => (dayFeedbacks?.length ? onDayClick(dayFeedbacks) : null)}
      disabled={!dayFeedbacks?.length}
      style={s`items-center min-h-10`}
    >
      <Paragraph>{date?.day}</Paragraph>

      {isSingle &&
        (dayFeedback?.comment ? (
          <FontAwesome name="comment" size={20} color={iconColor} />
        ) : (
          <View style={s`h-5 w-5 ${viewColorClass} rounded`} />
        ))}
      {isMultiple && (
        <View style={s`${viewColorClass} px-2 py-1  rounded-full`}>
          <Text style={s`text-white font-semibold`}>
            {dayFeedbacks?.length}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  );
};
