import { s } from 'react-native-wind';
import * as React from 'react';
import { useLanguage } from '../locale';
import { useBottomSheetActions } from '../hooks/useBottomSheetActions';
import { BottomSheetRef, UmBottomSheet } from './UmBottomSheet';
import { View } from 'react-native';
import { Subtitle } from './Subtitle';
import { Button, ButtonType } from './Button';
import { Edge } from 'react-native-safe-area-context';
import { SelectableElement } from './SelectebleElement';
import { useSelector } from 'react-redux';
import { selectedCompanySelector } from '../redux/general/general.selectors';
import { useDispatch } from '../redux/utils';
import { setSelectedCompany } from '../redux/general/general.slice';
import { useGetMyCompanies } from '../hooks/api/company.api';

const safeAreaEdges: Edge[] = ['right', 'left'];

type ChangeCompanyBottomSheetProps = {};

export const ChangeCompanyBottomSheet: React.ForwardRefExoticComponent<
  ChangeCompanyBottomSheetProps & React.RefAttributes<BottomSheetRef>
> = React.forwardRef((_, forwardedRef) => {
  const [t] = useLanguage();
  const dispatch = useDispatch();

  const selectedCompany = useSelector(selectedCompanySelector);

  const [localSelectedCompany, setLocalSelectedCompany] =
    React.useState(selectedCompany);

  const { bottomSheetRef, closeBottomSheet } =
    useBottomSheetActions(forwardedRef);

  const { data: companies = [] } = useGetMyCompanies(false);

  const selectCompanyFunctions = React.useMemo(() => {
    const functions: Record<string, () => void> = {};
    companies.forEach((team) => {
      functions[team.id] = () => {
        setLocalSelectedCompany(team);
      };
    });
    return functions;
  }, [companies]);

  React.useEffect(() => {
    setLocalSelectedCompany(selectedCompany);
  }, [selectedCompany]);

  const cancel = React.useCallback(() => {
    setLocalSelectedCompany(selectedCompany);
    closeBottomSheet();
  }, [closeBottomSheet, selectedCompany]);

  const confirm = React.useCallback(() => {
    dispatch(setSelectedCompany(localSelectedCompany));
    closeBottomSheet();
  }, [closeBottomSheet, dispatch, localSelectedCompany]);

  return (
    <UmBottomSheet
      ref={bottomSheetRef}
      isContentHeight
      safeAreaEdges={safeAreaEdges}
    >
      <Subtitle>{t('changeCompanyBottomSheet.title')}</Subtitle>
      <View style={s`my-4`}>
        {companies.map((company, i) => {
          const isFirst = i === 0;
          return (
            <SelectableElement
              key={`change-team-${company.id}`}
              isSelected={company.id === localSelectedCompany?.id}
              onPress={selectCompanyFunctions[company.id]}
              lable={company.name}
              style={s`${isFirst ? '' : 'mt-3'}`}
            />
          );
        })}
      </View>
      <View style={s`flex-row mt-8`}>
        <Button
          buttonType={ButtonType.Tertiary}
          onPress={cancel}
          containerStyle={s`flex-1 mr-4`}
        >
          {t('changeCompanyBottomSheet.cancel')}
        </Button>
        <Button
          onPress={confirm}
          buttonType={ButtonType.Primary}
          containerStyle={s`flex-1`}
        >
          {t('changeCompanyBottomSheet.confirm')}
        </Button>
      </View>
    </UmBottomSheet>
  );
});
