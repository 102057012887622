import { s } from 'react-native-wind';
import React, { useEffect } from 'react';
import { BottomSheetRef, UmBottomSheet } from './UmBottomSheet';
import { Feedback } from '../api/feedback/feedback.models';
import { useBottomSheetActions } from '../hooks/useBottomSheetActions';
import { Subtitle } from './Subtitle';
import dayjs from 'dayjs';
import { ScrollView, View } from 'react-native';
import { Paragraph } from './Paragraph';
import { UmFaces } from './UmFaces';
import { Edge } from 'react-native-safe-area-context';
import { useCategories } from '../hooks/useCategories';
import { UmFeedbackCategory } from './UmFeedbackCategory';
import { Button, ButtonType } from './Button';
import { useGetMyTeams } from '../hooks/api/team.api';
import { TeamStatus } from '../api/team/team.models';
import { useLanguage } from '../locale';
import { Image } from 'expo-image';

type FeedbackDetailsBottomSheetProps = {
  feedbacksDetails?: Feedback[];
};

const feedbackBottomSheetSafeAreaEdges: Edge[] = ['right', 'left'];

export const FeedbackDetailsBottomSheet: React.ForwardRefExoticComponent<
  FeedbackDetailsBottomSheetProps & React.RefAttributes<BottomSheetRef>
> = React.forwardRef(({ feedbacksDetails }, forwardedRef) => {
  const { bottomSheetRef: feedbackDetailsBottomSheetRef } =
    useBottomSheetActions(forwardedRef);

  const categories = useCategories();
  const { data: teams } = useGetMyTeams(TeamStatus.Active);

  const [feedbackDetails, setCurrentFeedback] = React.useState(
    feedbacksDetails?.[0]
  );
  useEffect(() => {
    setCurrentFeedback(feedbacksDetails?.[0]);
  }, [feedbacksDetails]);

  const [t] = useLanguage();

  return (
    <UmBottomSheet
      safeAreaEdges={feedbackBottomSheetSafeAreaEdges}
      ref={feedbackDetailsBottomSheetRef}
      isContentHeight
    >
      {(feedbacksDetails?.length ?? 0) > 1 && (
        <>
          <Subtitle>{t('stats.feedbackDetails.teamLabel')}</Subtitle>
          <ScrollView
            horizontal
            pagingEnabled
            style={s`flex mb-6 mt-2 overflow-visible`}
            showsHorizontalScrollIndicator={false}
          >
            {feedbacksDetails?.map((feedback) => {
              return (
                <Button
                  key={`cteam-${feedback.createdAt}`}
                  buttonType={
                    feedbackDetails?.createdAt === feedback.createdAt
                      ? ButtonType.Primary
                      : ButtonType.Tertiary
                  }
                  onPress={() => setCurrentFeedback(feedback)}
                  containerStyle={s`mr-4`}
                >
                  {teams?.find((t) => t.id === feedback.teamId)?.name ?? ''}
                </Button>
              );
            })}
          </ScrollView>
        </>
      )}
      {feedbackDetails && (
        <View style={s`flex-1`} key={feedbackDetails.createdAt}>
          <Subtitle>
            {dayjs(feedbackDetails.createdAt).format('DD MMMM YYYY')}
          </Subtitle>
          <View style={s`mt-4`}>
            <UmFaces
              selectedVote={feedbackDetails.value}
              isEnabled={false}
              isFixedSize={false}
            />
          </View>
          {feedbackDetails.categoryFeedback ? (
            <View style={s`my-4 flex-row flex-wrap justify-center`}>
              {Object.keys(feedbackDetails.categoryFeedback).map((gc) => {
                const categoryVal = feedbackDetails.categoryFeedback[gc];
                const category = categories.find((c) => gc === c.id);
                if (!category) return null;
                return (
                  <UmFeedbackCategory
                    key={`good-category-stats-${gc}`}
                    icon={
                      <Image
                        style={s`w-7 h-7`}
                        source={category.icon.light}
                        contentFit="cover"
                        transition={300}
                      />
                    }
                    label={category.title}
                    value={categoryVal}
                    containerStyle={s`w-1/3 mt-6 px-1`}
                    isSelected
                  />
                );
              })}
            </View>
          ) : null}
          {feedbackDetails.comment ? (
            <ScrollView
              bounces={false}
              style={s`mt-4`}
              contentContainerStyle={s`flex-grow min-h-36 rounded-md p-3 bg-card`}
            >
              <Paragraph>{feedbackDetails.comment}</Paragraph>
            </ScrollView>
          ) : null}
        </View>
      )}
    </UmBottomSheet>
  );
});
