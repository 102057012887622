import * as React from 'react';
import { s } from 'react-native-wind';
import { Button, ParagraphBold } from '../../../components';
import { useLanguage } from '../../../locale';
import { useAuthCodeController } from '../../../screens/authCode/controllers/useAuthCodeController';
import { styles } from './CodeInput.style';

export const CodeInput = () => {
  const [t] = useLanguage();

  const {
    verifyCode,
    pasteFromClipboard,
    isClipboardAvailable,
    code,
    deleteDigit,
    add0,
    add1,
    add2,
    add3,
    add4,
    add5,
    add6,
    add7,
    add8,
    add9,
  } = useAuthCodeController();

  const keyEventBinds = React.useMemo(
    () => [add0, add1, add2, add3, add4, add5, add6, add7, add8, add9],
    [add0, add1, add2, add3, add4, add5, add6, add7, add8, add9]
  );

  const containerRef = React.useRef<HTMLDivElement>(null);
  const size = (document.body.clientWidth - 27 * 2) / 6 - 8;

  const focusInput = (
    index: number,
    e?: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    e?.preventDefault();

    const inputFocusedEl = Array.from(
      containerRef.current?.querySelectorAll('input') ?? []
    )[index];

    inputFocusedEl?.focus();
  };

  React.useEffect(() => {
    focusInput(code.length);
  }, [code.length]);

  return (
    <>
      <div
        className="foo"
        ref={containerRef}
        style={s`flex flex-row justify-between mt-6`}
      >
        {Array(6)
          .fill(undefined)
          .map((_, i) => (
            <input
              key={i}
              style={{ ...styles.box, height: size, width: size }}
              type="number"
              value={code[i] ?? ''}
              onPaste={() => pasteFromClipboard()}
              onMouseDown={() => focusInput(code.length)}
              onKeyUp={(e) =>
                ['Backspace', 'Delete'].includes(e.key)
                  ? deleteDigit()
                  : Object.keys(keyEventBinds).includes(e.key)
                  ? keyEventBinds[Number(e.key)]()
                  : undefined
              }
            />
          ))}
      </div>

      <ParagraphBold
        onPress={isClipboardAvailable ? pasteFromClipboard : undefined}
        style={[
          s`text-secondary mt-4`,
          !isClipboardAvailable ? styles.disabledText : undefined,
        ]}
      >
        {t('authCode.pasteClipboard')}
      </ParagraphBold>

      <Button
        onPress={verifyCode}
        isDisabled={code.length !== 6}
        containerStyle={s`mt-6`}
      >
        {t('authCode.confirm')}
      </Button>
    </>
  );
};
