import { PayloadAction } from '@reduxjs/toolkit';
import { Company } from '../../api/company/companies.models';
import { Team } from '../../api/team/team.models';
import { GeneralState } from './general.models';

export const addFullPageLoaderHandler = (
  state: GeneralState,
  action: PayloadAction<string>
) => {
  state.fullPageLoaders.push(action.payload);
};

export const removeFullPageLoaderHandler = (
  state: GeneralState,
  action: PayloadAction<string>
) => {
  state.fullPageLoaders = state.fullPageLoaders.filter(
    (l) => l !== action.payload
  );
};

export const addLoaderHandler = (
  state: GeneralState,
  action: PayloadAction<string>
) => {
  state.loaders.push(action.payload);
};

export const removeLoaderHandler = (
  state: GeneralState,
  action: PayloadAction<string>
) => {
  state.loaders = state.loaders.filter((l) => l !== action.payload);
};

export const setIsAuthorizedHandler = (
  state: GeneralState,
  action: PayloadAction<boolean>
) => {
  state.isAuthorized = action.payload;
};

export const setIsInitializedHandler = (
  state: GeneralState,
  action: PayloadAction<boolean>
) => {
  state.isInitialized = action.payload;
};

export const setIsInErrorHandler = (
  state: GeneralState,
  action: PayloadAction<boolean>
) => {
  state.isInError = action.payload;
};

export const setIsOnboardingFinishedHandler = (
  state: GeneralState,
  action: PayloadAction<boolean>
) => {
  state.isOnboardingFinished = action.payload;
};

export const setIsSummaryHandler = (
  state: GeneralState,
  action: PayloadAction<boolean>
) => {
  state.isSummarySelected = action.payload;
};

export const setSelectedTeamHandler = (
  state: GeneralState,
  action: PayloadAction<Team | null>
) => {
  state.selectedTeam = action.payload;
};

export const setSelectedCompanyHandler = (
  state: GeneralState,
  action: PayloadAction<Company | null>
) => {
  state.selectedCompany = action.payload;
};

export const setIsNoNetworkMomentHandler = (
  state: GeneralState,
  action: PayloadAction<boolean>
) => {
  state.isNoNetworkMoment = action.payload;
};
