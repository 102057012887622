import { s } from 'react-native-wind';
import React, { memo } from 'react';
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native';
import { Description, Title } from '../../../components';
import { Config } from '../../../config';

type IntroPagerSlideProps = {
  imageSource: ImageSourcePropType;
  description: string;
  title: string;
};

export const IntroPagerSlide: React.FC<IntroPagerSlideProps> = memo(
  ({ description, imageSource, title }) => {
    return (
      <View
        collapsable={false}
        style={s`flex-1 items-stretch my-6 h-full`}
        key="1"
      >
        <Title style={[s`text-3xl text-center mb-3`, styles.title]}>
          {title}
        </Title>
        <View style={s`flex-1 items-center`}>
          <Image
            source={imageSource}
            style={s`resize-contain flex-1 mt-4 w-full`}
          />
        </View>
        <Description style={s`mt-6`}>{description}</Description>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  title: {
    fontFamily: Config.fontSemibold,
    lineHeight: 28,
  },
});
