export const TEAM_SETTINGS_SCREEN = 'Qomprendo | Team Settings';
export const PROFILE_SCREEN = 'Qomprendo | Profile';
export const GAME_SCREEN = 'Qomprendo | Badges';
export const STATS_SCREEN = 'Qomprendo | Stats';
export const FEEDBACKS_SCREEN = 'Qomprendo | Feedbacks';

export type AuthenticatedBottomTabPages =
  | typeof TEAM_SETTINGS_SCREEN
  | typeof PROFILE_SCREEN
  | typeof STATS_SCREEN
  | typeof FEEDBACKS_SCREEN
  | typeof GAME_SCREEN;

export type AuthenticatedBottomTabParams = {
  [TEAM_SETTINGS_SCREEN]: undefined;
  [PROFILE_SCREEN]: undefined;
  [GAME_SCREEN]: undefined;
  [STATS_SCREEN]: undefined;
  [FEEDBACKS_SCREEN]: undefined;
};
