import { s } from 'react-native-wind';
import React from 'react';
import {
  Button,
  ButtonType,
  Paragraph,
  Subtitle,
  UmModal,
} from '../../../components';
import { useLanguage } from '../../../locale';
import { View } from 'react-native';

type DeleteProfileModalProps = {
  isOpen: boolean;
  onDelete: () => void;
  onCancel: () => void;
};

export const DeleteProfileModal: React.FC<DeleteProfileModalProps> = ({
  isOpen,
  onCancel: onCancel,
  onDelete,
}) => {
  const [t] = useLanguage();

  return (
    <UmModal isOpen={isOpen}>
      <Subtitle>{t('profile.deleteModal.title')}</Subtitle>
      <Paragraph style={s`mt-4`}>
        {t('profile.deleteModal.description')}
      </Paragraph>
      <View style={s`mt-8 flex-row`}>
        <Button
          buttonType={ButtonType.Tertiary}
          onPress={onCancel}
          containerStyle={s`flex-1 mr-4`}
        >
          {t('profile.deleteModal.cancel')}
        </Button>
        <Button
          onPress={onDelete}
          buttonType={ButtonType.Danger}
          containerStyle={s`flex-1`}
        >
          {t('profile.deleteModal.delete')}
        </Button>
      </View>
    </UmModal>
  );
};
