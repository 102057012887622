import BottomSheet from '@gorhom/bottom-sheet';
import { ForwardedRef, useCallback, useImperativeHandle, useRef } from 'react';
import { BottomSheetRef } from '../components';

export const useBottomSheetActions = (
  forwardedRef: ForwardedRef<BottomSheetRef>
) => {
  const bottomSheetRef = useRef<BottomSheet>(null);

  const openBottomSheet = useCallback(() => {
    bottomSheetRef.current?.expand();
  }, []);

  const closeBottomSheet = useCallback(() => {
    bottomSheetRef.current?.close();
  }, []);

  useImperativeHandle(forwardedRef, () => ({
    expand: openBottomSheet,
    close: closeBottomSheet,
  }));

  return {
    openBottomSheet,
    closeBottomSheet,
    bottomSheetRef,
  };
};
