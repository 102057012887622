import { useController, useForm } from 'react-hook-form';
import { UpdateProfileRequest } from '../../../api/user/user.models';
import { useLanguage } from '../../../locale';

export const useEditProfileForm = () => {
  const [t] = useLanguage();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateProfileRequest>();

  const {
    field: { onChange: onUsernameChange, value: usernameValue },
  } = useController<UpdateProfileRequest, 'username'>({
    name: 'username',
    control,
    rules: {
      minLength: {
        value: 3,
        message: t('editProfile.usernameError'),
      },
      required: false,
    },
    defaultValue: '',
  });

  return {
    setValue,
    handleSubmit,
    usernameValue,
    onUsernameChange,
    errors,
  };
};
