import { s } from 'react-native-wind';
import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { ParagraphBold } from './ParagraphBold';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Danger = 'danger',
}

type ButtonProps = {
  children: string;
  buttonType?: ButtonType;
  isDisabled?: boolean;
  containerStyle?: ViewStyle;
  onPress?: () => void;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  isDisabled,
  onPress,
  containerStyle,
  buttonType = ButtonType.Primary,
}) => {
  const finalContainerStyle = StyleSheet.flatten([
    isDisabled ? styles.disabled : null,
    containerStyle || {},
  ]);

  const bgAndBorderColor = useMemo(() => {
    switch (buttonType) {
      case ButtonType.Primary:
        return 'bg-primary border-primary';
      case ButtonType.Secondary:
        return 'bg-secondary border-secondary';
      case ButtonType.Tertiary:
        return 'bg-card border-text-primary';
      case ButtonType.Danger:
        return 'bg-danger border-danger';
    }
  }, [buttonType]);

  const textColor = useMemo(() => {
    switch (buttonType) {
      case ButtonType.Primary:
        return 'text-white';
      case ButtonType.Secondary:
        return 'text-white';
      case ButtonType.Tertiary:
        return 'text-text-primary';
      case ButtonType.Danger:
        return 'text-white';
    }
  }, [buttonType]);

  return (
    <View style={finalContainerStyle}>
      <TouchableOpacity
        activeOpacity={isDisabled ? 1 : 0.2}
        onPress={!isDisabled ? onPress : undefined}
        disabled={isDisabled}
        style={s`px-4 rounded-2xl h-12 border-solid border ${bgAndBorderColor} justify-center`}
      >
        <ParagraphBold style={s`${textColor} text-center`}>
          {children}
        </ParagraphBold>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  disabled: {
    opacity: 0.5,
  },
});
