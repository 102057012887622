import { s } from 'react-native-wind';
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { FeedbackValue } from '../api/feedback/feedback.models';
import { UmFace } from './UmFace';

type UmFacesProps = {
  onPressFace?:
    | ((feedbackValue: FeedbackValue) => Promise<void>)
    | ((feedbackValue: FeedbackValue) => void);
  selectedVote: FeedbackValue;
  containerStyle?: StyleProp<ViewStyle>;
  isFixedSize?: boolean;
  isEnabled?: boolean;
};

export const UmFaces: React.FC<UmFacesProps> = ({
  selectedVote,
  containerStyle,
  onPressFace,
  isFixedSize = true,
  isEnabled = true,
}) => {
  const onPressVeryHappyFace = React.useCallback(() => {
    onPressFace?.(4);
  }, [onPressFace]);
  const onPressHappyFace = React.useCallback(() => {
    onPressFace?.(3);
  }, [onPressFace]);
  const onPressSadFace = React.useCallback(() => {
    onPressFace?.(2);
  }, [onPressFace]);
  const onPressVerySadFace = React.useCallback(() => {
    onPressFace?.(1);
  }, [onPressFace]);

  return (
    <View
      style={[s`w-full justify-between items-center flex-row`, containerStyle]}
    >
      <UmFace
        containerStyle={!isFixedSize ? s`mr-4 flex-1` : undefined}
        isFixedSize={isFixedSize}
        onPress={onPressVeryHappyFace}
        value={4}
        isSelected={selectedVote === 4}
        isTouchable={isEnabled}
      />
      <UmFace
        containerStyle={!isFixedSize ? s`mr-4 flex-1` : undefined}
        isFixedSize={isFixedSize}
        onPress={onPressHappyFace}
        value={3}
        isSelected={selectedVote === 3}
        isTouchable={isEnabled}
      />
      <UmFace
        containerStyle={!isFixedSize ? s`mr-4 flex-1` : undefined}
        isFixedSize={isFixedSize}
        onPress={onPressSadFace}
        value={2}
        isSelected={selectedVote === 2}
        isTouchable={isEnabled}
      />
      <UmFace
        containerStyle={!isFixedSize ? s`flex-1` : undefined}
        isFixedSize={isFixedSize}
        onPress={onPressVerySadFace}
        value={1}
        isSelected={selectedVote === 1}
        isTouchable={isEnabled}
      />
    </View>
  );
};
