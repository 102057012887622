import * as React from 'react';
import { getDayRangeList, getDayType } from '../../../utils/feedbacks';
import { DotsType } from '../components/DaysInRowDots';
import { Feedback } from '../../../api/feedback/feedback.models';

export function useDaysInRowSectionController(feedbacks: Feedback[] = []) {
  const daysList = React.useMemo(() => {
    return getDayRangeList(7);
  }, []);

  const weeklyStreak = React.useMemo(() => {
    const daysTypes = daysList.map((d) => {
      return getDayType(feedbacks, d);
    });

    let bestStreak = 0;
    let currentStreak = 0;

    daysTypes.forEach((dt) => {
      switch (dt) {
        case DotsType.Complete:
          currentStreak++;
          break;
        case DotsType.Partial:
        case DotsType.Missing:
          currentStreak = 0;
          break;
      }

      if (currentStreak > bestStreak) bestStreak = currentStreak;
    });

    return bestStreak;
  }, [daysList, feedbacks]);

  return {
    feedbacks,
    weeklyStreak,
    daysList,
  };
}
