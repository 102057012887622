import { Badge } from '../../../api/gamification/gamification.models';
import { extractBadgeIdFromPath } from '../../../utils/extractBadgeIdFromPath';
import { getBadgeImageFromId } from '../../../utils/getBadgeImageFromId';

export const getTreeImageFromCompletedPercentage = (
  badge: Badge,
  currentPoints: number,
  targetPoints: number
) => {
  const completedPercentage = (currentPoints / targetPoints) * 100;
  const percentageRounded = Math.round(completedPercentage + Number.EPSILON);
  const correctProgression = [...badge.progression]
    .reverse()
    .find((p) => percentageRounded >= p.minProgress);

  const imageId = correctProgression?.image
    ? extractBadgeIdFromPath(correctProgression?.image)
    : undefined;

  return getBadgeImageFromId(imageId);
};
