import { useApi } from '../../context/ApiContext/useApi';
import {
  InvalidationTag,
  LoaderType,
  useAppMutation,
  useAppQuery,
} from './utils';

export const useGetMyCompanies = (isAdmin: boolean, isEnabled = true) => {
  const { companyApi } = useApi();
  const queryRes = useAppQuery(
    [InvalidationTag.Company],
    () => companyApi.getMyCompanies({ admin: isAdmin ? '1' : '0' }),
    { enabled: isEnabled }
  );
  return queryRes;
};

export const useSearchCompanyUsers = (companyId: string, query: string) => {
  const { companyApi } = useApi();
  const queryReturn = useAppQuery(
    [InvalidationTag.CompanyUsersSearch, companyId, query],
    ({ queryKey }) => {
      const companyId = queryKey[1];
      const queryValue = queryKey[2];

      return companyApi.searchUsers({
        companyId: `${companyId}`,
        query: queryValue,
      });
    },
    {
      enabled: false,
    }
  );
  return queryReturn;
};

export const useSearchCompanyUsersMutation = () => {
  const { companyApi } = useApi();
  const mutation = useAppMutation(
    (params: { companyId: string; query: string }) =>
      companyApi.searchUsers({
        companyId: `${params.companyId}`,
        query: params.query,
      }),
    undefined,
    LoaderType.None
  );
  return mutation;
};
