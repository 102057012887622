export const ONBOARDING_PRIVACY_TERMS = 'Qomprendo | Onboarding Privacy Terms';
export const ONBOARDING_NAME = 'Qomprendo | Onboarding Name';
export const ONBOARDING_BIRTH_DATE = 'Qomprendo | Onboarding Birthdate';
export const ONBOARDING_ANONYMOUS_VOTE_SCREEN =
  'Qomprendo | Onboarding Anonymous Vote';

export type OnboardingPages =
  | typeof ONBOARDING_PRIVACY_TERMS
  | typeof ONBOARDING_BIRTH_DATE
  | typeof ONBOARDING_ANONYMOUS_VOTE_SCREEN
  | typeof ONBOARDING_NAME;

export type OnboardingScreenParams = {
  [ONBOARDING_PRIVACY_TERMS]: undefined;
  [ONBOARDING_BIRTH_DATE]: undefined;
  [ONBOARDING_ANONYMOUS_VOTE_SCREEN]: undefined;
  [ONBOARDING_NAME]: undefined;
};
