import * as React from 'react';
import veryHappyFace from '../../../../../assets/very-happy-face.png';
import happyFace from '../../../../../assets/happy-face.png';
import sadFace from '../../../../../assets/sad-face.png';
import verySadFace from '../../../../../assets/very-sad-face.png';
import { Image } from 'react-native';
import { s } from 'react-native-wind';

type YLabelProps = {
  value: number;
};

const YLabel: React.FC<YLabelProps> = ({ value }) => {
  const face = React.useMemo(() => {
    switch (value) {
      case 1:
        return verySadFace;
      case 2:
        return sadFace;
      case 3:
        return happyFace;
      case 4:
        return veryHappyFace;
      default:
        return veryHappyFace;
    }
  }, [value]);

  return <Image source={face} style={s`w-6 h-7`} />;
};

export default YLabel;
