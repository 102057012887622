import { s } from 'react-native-wind';
import React, { useCallback, useMemo, useState } from 'react';
import { UmModal } from '.';
import { View } from 'react-native-animatable';
import { Subtitle } from './Subtitle';
import { useLanguage } from '../locale';
import { Button, ButtonType } from './Button';
import { DatePicker } from '../portability/components/DatePicker/DatePicker';
import dayjs from 'dayjs';
import ceil from '../utils/dayjsCeil';
import { Platform } from 'react-native';

dayjs.extend(ceil);

type MinuteInterval = 15 | 2 | 1 | 3 | 4 | 5 | 6 | 10 | 12 | 20 | 30;

type UmTimePickerProps = {
  isOpen: boolean;
  startingDate?: Date;
  minuteInterval?: MinuteInterval;
  onConfirm?: (time: string) => void;
  onCancel?: () => void;
};

export const UmTimePicker: React.FC<UmTimePickerProps> = ({
  isOpen,
  minuteInterval = 15,
  startingDate = dayjs().ceil('minutes', minuteInterval).toDate(),
  onConfirm,
  onCancel,
}) => {
  const [date, setDate] = useState(startingDate);
  const [t] = useLanguage();

  const { maxDate, minDate } = useMemo(() => {
    return {
      minDate: dayjs()
        .set('hours', 8)
        .set('minute', 0)
        .set('second', 0)
        .toDate(),
      maxDate: dayjs()
        .set('hours', 23)
        .set('minute', 0)
        .set('second', 0)
        .toDate(),
    };
  }, []);

  const confirm = useCallback(() => {
    const todayDate = dayjs(date);
    const formattedTime = todayDate.format('HH:mm:00');
    onConfirm?.(formattedTime);
  }, [date, onConfirm]);

  return (
    <UmModal isOpen={isOpen}>
      <Subtitle>{t('timePicker.title')}</Subtitle>
      <View
        style={s`overflow-hidden items-center justify-center mt-4 ${
          Platform.OS === 'web' ? '' : 'max-h-24'
        }`}
      >
        <DatePicker
          minimumDate={minDate}
          maximumDate={maxDate}
          minuteInterval={minuteInterval}
          locale="it"
          mode="time"
          date={date}
          onDateChange={setDate}
        />
      </View>
      <View style={s`flex-row mt-4`}>
        <Button
          onPress={onCancel}
          buttonType={ButtonType.Tertiary}
          containerStyle={s`flex-1 mr-4`}
        >
          {t('timePicker.cancel')}
        </Button>
        <Button
          onPress={confirm}
          buttonType={ButtonType.Primary}
          containerStyle={s`flex-1`}
        >
          {t('timePicker.confirm')}
        </Button>
      </View>
    </UmModal>
  );
};
