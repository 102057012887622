import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { UpdateTeamMemberMetaRequest } from '../../api/team/team.models';
import { useApi } from '../../context/ApiContext/useApi';
import { InvalidationTag, useAppMutation } from './utils';

export const useUpdateTeamMemberMeta = () => {
  const { teamApi } = useApi();
  const queryClient = useQueryClient();

  const onUpdateSuccess = useCallback(
    (_, params: UpdateTeamMemberMetaRequest) => {
      queryClient.invalidateQueries({
        queryKey: [InvalidationTag.TeamMembers, { teamId: params.teamId }],
      });
      queryClient.invalidateQueries({
        queryKey: [
          InvalidationTag.TeamMember,
          { teamId: params.teamId, memberId: params.memberId },
        ],
      });
    },
    [queryClient]
  );

  const mutation = useAppMutation(
    (req: UpdateTeamMemberMetaRequest) => teamApi.updateTeamMemberMeta(req),
    { onSuccess: onUpdateSuccess }
  );

  return mutation;
};
