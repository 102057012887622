import { s } from 'react-native-wind';
import * as React from 'react';
import { View } from 'react-native-animatable';
import {
  Button,
  ButtonType,
  Layout,
  Paragraph,
  ParagraphBold,
  ParagraphSmall,
  Subtitle,
} from '../../components';
import { PROFILE_SCREEN } from '../../navigation/authenticatedWithBottomTab/authenticated.types';
import { GenericScreenProps } from '../../navigation/core/types';
import { useLanguage } from '../../locale';
import { Image, StyleSheet, Switch, TouchableOpacity } from 'react-native';
import { Edge } from 'react-native-safe-area-context';
import { Config } from '../../config';
import { DeleteProfileModal } from './components/DeleteProfileModal';
import { ProfileDeleteConfirmModal } from './components/ProfileDeleteConfirmedModal';
import { useProfileController } from './controller/useProfileController';
import { PencilIcon } from 'react-native-heroicons/outline';
import dayjs from 'dayjs';
import SelectAppLanguage from '../../components/selectAppLanguage/SelectAppLanguage';

const profileSafeAreaEdges: Edge[] = ['left', 'right', 'top'];

type ProfileScreenProps = {} & GenericScreenProps<typeof PROFILE_SCREEN>;

export const ProfileScreen: React.FC<ProfileScreenProps> = ({ navigation }) => {
  const [t] = useLanguage();

  const {
    chooseProfileImage,
    imageProfile,
    imageUuid,
    isDeleteConfirmedModalOpen,
    isDeleteModalOpen,
    isProfileImageExist,
    myDetails,
    navigateToEditProfile,
    onProfileDelete,
    requestAccountDelete,
    signOut,
    toggleConfirmedModal,
    toggleDeleteModal,
    setIsProfileImageExist,
    isAnonymousVote,
    toggleAnonymousFeedback,
  } = useProfileController(navigation);

  return (
    <>
      <Layout safeAreaEdges={profileSafeAreaEdges} showNavbar={false}>
        {myDetails ? (
          <View style={s`flex-1`}>
            <View style={s`items-center`}>
              {!isProfileImageExist ? (
                <View
                  style={[
                    s`rounded-full w-1/3 bg-light-green justify-center items-center`,
                    styles.profileImage,
                  ]}
                >
                  <ParagraphBold style={s`text-4xl text-primary mt-1`}>
                    {myDetails.username.charAt(0).toUpperCase()}
                  </ParagraphBold>
                </View>
              ) : (
                <Image
                  source={{
                    uri:
                      imageProfile ??
                      `${Config.profileImageBaseUrl}/${myDetails.id}?id=${imageUuid}`,
                    cache: 'reload',
                  }}
                  style={[
                    s`rounded-full w-1/3 bg-light-green justify-center items-center`,
                    styles.profileImage,
                  ]}
                  onError={() => setIsProfileImageExist(false)}
                />
              )}
              <ParagraphBold
                onPress={chooseProfileImage}
                style={s`text-secondary mt-4`}
              >
                {t('profile.editProfileImage')}
              </ParagraphBold>
            </View>

            <View style={s`mt-6`}>
              <Subtitle>{t('profile.settings')}</Subtitle>
              <View
                style={s`mt-4 items-center flex-row rounded-2xl bg-card p-4 border border-text-secondary`}
              >
                <Paragraph style={s`flex-1`}>
                  {t('profile.anonymousVoteLabel')}
                </Paragraph>
                <Switch
                  value={isAnonymousVote}
                  onValueChange={toggleAnonymousFeedback}
                />
              </View>
              <SelectAppLanguage style={s`mt-4`} />
            </View>
            <View style={s`flex-row mt-6 items-center justify-between`}>
              <Subtitle>{t('profile.details')}</Subtitle>
              <TouchableOpacity onPress={navigateToEditProfile}>
                <PencilIcon color={Config.colorTextPrimary} />
              </TouchableOpacity>
            </View>
            <View
              style={s`mt-4 items-stretch rounded-2xl bg-card p-4 border border-text-secondary`}
            >
              <ParagraphSmall style={s`text-text-secondary`}>
                {t('profile.username')}
              </ParagraphSmall>
              <Paragraph>{myDetails.username}</Paragraph>
              <ParagraphSmall style={s`text-text-secondary mt-4`}>
                {t('profile.email')}
              </ParagraphSmall>
              <Paragraph>{myDetails.email}</Paragraph>
              {myDetails.birthDate ? (
                <>
                  <ParagraphSmall style={s`text-text-secondary mt-4`}>
                    {t('profile.dateOfBirth')}
                  </ParagraphSmall>
                  <Paragraph>
                    {dayjs(myDetails.birthDate).format('D MMMM YYYY')}
                  </Paragraph>
                </>
              ) : null}
            </View>
            <View>
              <Button
                onPress={signOut}
                containerStyle={s`mt-8`}
                buttonType={ButtonType.Secondary}
              >
                {t('profile.signOut')}
              </Button>
              <Button
                onPress={requestAccountDelete}
                containerStyle={s`mt-3`}
                buttonType={ButtonType.Danger}
              >
                {t('profile.requestDelete')}
              </Button>
            </View>
          </View>
        ) : null}
      </Layout>
      <DeleteProfileModal
        onCancel={toggleDeleteModal}
        onDelete={onProfileDelete}
        isOpen={isDeleteModalOpen}
      />
      <ProfileDeleteConfirmModal
        isOpen={isDeleteConfirmedModalOpen}
        onCloseModal={toggleConfirmedModal}
      />
    </>
  );
};

const styles = StyleSheet.create({
  profileImage: {
    aspectRatio: 1,
  },
});
