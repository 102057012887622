import * as React from 'react';
import { Route, Stack } from '../core/types';
import { createStack } from '../core/createStack';
import {
  AuthenticatedPages,
  AUTHENTICATED_BOTTOM_TAB_NAME,
  EDIT_PROFILE_SCREEN,
  FEEDBACK_DETAILS_SCREEN,
  TEAM_MEMBERS_SCREEN,
  USER_NOTIFICATIONS_SCREEN,
  LEADERBOARD_SCREEN,
  ALL_BADGES_SCREEN,
  ALL_CHALLENGES_SCREEN,
  HARMONIA_ASSISTANT_SCREEN,
  SLACK_INTEGRATION_SCREEN,
} from './authenticated.types';
import { AuthenticatedBottomTab } from '../authenticatedWithBottomTab/authenticated.bottomTab';
import { AllBadgesScreen } from '../../screens/allBadges/allBadges.screen';
import { EditProfileScreen } from '../../screens/editProfile/editProfile.screen';
import { FeedbackDetailsScreen } from '../../screens/feedbackDetails/feedbackDetails.screen';
import { TeamMembersScreen } from '../../screens/teamMembers/teamMembers.screen';
import { UserNotificationsScreen } from '../../screens/userNotifications/userNotifications.screen';
import { LeaderboardScreen } from '../../screens/leaderboard/leaderboard.screen';
import { AllChallengesScreen } from '../../screens/allChallenges/allChallenges.screen';
import SlackIntegrationScreen from '../../screens/slack/slackIntegration.screen';
import HarmoniaAssistantScreen from '../../screens/harmoniaAssistant/harmoniaAssistant.screen';
import { useSetupNotifications } from '../../hooks/useSetupNotifications';

export const AUTHENTICATED_STACK_NAME = 'Qomprendo';

export const authenticatedRoutes: Route<AuthenticatedPages>[] = [
  { name: ALL_BADGES_SCREEN, component: AllBadgesScreen },
  { name: AUTHENTICATED_BOTTOM_TAB_NAME, component: AuthenticatedBottomTab },
  { name: EDIT_PROFILE_SCREEN, component: EditProfileScreen },
  { name: TEAM_MEMBERS_SCREEN, component: TeamMembersScreen },
  { name: LEADERBOARD_SCREEN, component: LeaderboardScreen },
  { name: USER_NOTIFICATIONS_SCREEN, component: UserNotificationsScreen },
  { name: FEEDBACK_DETAILS_SCREEN, component: FeedbackDetailsScreen },
  { name: ALL_CHALLENGES_SCREEN, component: AllChallengesScreen },
  { name: HARMONIA_ASSISTANT_SCREEN, component: HarmoniaAssistantScreen },
  { name: SLACK_INTEGRATION_SCREEN, component: SlackIntegrationScreen },
];

export const authenticatedStack: Stack<AuthenticatedPages> = {
  routes: authenticatedRoutes,
  initialScreen: AUTHENTICATED_BOTTOM_TAB_NAME,
};

export const AuthenticatedStackComponent = createStack(authenticatedStack);

export const AuthenticatedStack = () => {
  useSetupNotifications();

  return <AuthenticatedStackComponent />;
};
