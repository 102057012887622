import * as React from 'react';
import { FeedbackCategory } from '../api/feedback/feedback.models';
import { useGetMyCompanies } from './api/company.api';

export const useCategories = () => {
  const { data: myCompanies } = useGetMyCompanies(false);

  const categories = React.useMemo(() => {
    if (!myCompanies) return [];

    return myCompanies.reduce((pv, cv) => {
      return [...pv, ...cv.categoryFeedback];
    }, [] as FeedbackCategory[]);
  }, [myCompanies]);

  return categories;
};
