import { ItemResponse } from '../../types/common.types';

export interface HarmoniaConfig {
  unseenMessages: string;
  responseTimeout: number;
  userSetting: { enableHarmonia: number };
}

export interface HarmoniaMessage {
  pk: `user#${string}`;
  sk: `thread#${string}#${string}`;
  messageId: string;
  userId: string;
  companyId: string;
  threadId: string;
  assistantId: string;
  assistantVersion: string;
  message: string;
  lang: string;
  author: HarmoniaMessageAuthor;
  sentAt: string;
  viewedAt: string;
  createdAt: string;
  updatedAt: string;
}

export interface UserHarmoniaMessage {
  messageId?: string;
  message: string;
  author: HarmoniaMessageAuthor;
  createdAt: string;
  viewedAt: string;
}

export interface WsHarmoniaMessageResponse {
  message: HarmoniaMessage;
  error: boolean;
}

export enum HarmoniaMessageAuthor {
  user = 'user',
  assistant = 'assistant',
  internalSystem = 'internalSystem',
  aiSystem = 'aiSystem',
}

export type GetHarmoniaConfigRequest = {
  companyId: string;
};
export type GetHarmoniaConfigResponse = ItemResponse<HarmoniaConfig>;

export type GetThreadRequest = {
  companyId?: string;
  next?: string;
};
export type GetThreadResponse = {
  items: HarmoniaMessage[];
  next?: string;
};

export type SetMessageShownRequest = {
  companyId: string;
};
