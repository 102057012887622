import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  Provider as ReduxProvider,
} from 'react-redux';
import { RootState } from './store';

export const useDispatch = useReduxDispatch;
export const useSelector = <T>(
  selector: (state: RootState) => T,
  equalityFn?: ((left: T, right: T) => boolean) | undefined
) => useReduxSelector(selector, equalityFn);

export const Provider = ReduxProvider;
