import { createNotImplementedMethod } from '../utils';
import {
  GetWhoAmIRequest,
  GetWhoAmIResponse,
  UpdateCountryRequest,
  UpdateProfileRequest,
  UploadImageProfileRequest,
  UserDetails,
} from './user.models';

export interface IUserApi {
  readonly getWhoAmI: (req: GetWhoAmIRequest) => Promise<GetWhoAmIResponse>;
  readonly getMyDetails: () => Promise<UserDetails>;
  readonly uploadProfileImage: (
    req: UploadImageProfileRequest
  ) => Promise<string>;
  readonly updateMy: (
    req: UpdateProfileRequest | UpdateCountryRequest
  ) => Promise<void>;
  readonly deleteMy: () => Promise<void>;
}

export class NotImplementedUserApi implements IUserApi {
  public readonly getWhoAmI = createNotImplementedMethod();
  public readonly getMyDetails = createNotImplementedMethod();
  public readonly uploadProfileImage = createNotImplementedMethod();
  public readonly updateMy = createNotImplementedMethod();
  public readonly deleteMy = createNotImplementedMethod();
}
