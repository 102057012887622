export const AUTHENTICATION_SCREEN = 'Qomprendo | Authentication';
export const INTRO_SCREEN = 'Qomprendo | Intro';
export const AUTH_CODE_SCREEN = 'Qomprendo | Auth Code';

export type AuthenticationPages =
  | typeof AUTHENTICATION_SCREEN
  | typeof AUTH_CODE_SCREEN
  | typeof INTRO_SCREEN;

export type AuthenticationScreenParams = {
  [AUTHENTICATION_SCREEN]: undefined;
  [INTRO_SCREEN]: undefined;
  [AUTH_CODE_SCREEN]: {
    session: string;
    email: string;
  };
};
