import { s } from 'react-native-wind';
import React from 'react';
import { AUTHENTICATION_SCREEN } from '../../navigation/authentication/authentication.types';
import { GenericScreenProps } from '../../navigation/core/types';
import { Button, Description, Layout } from '../../components';
import { useLanguage } from '../../locale';
import { StyleSheet, View } from 'react-native';
import { TextField } from '../../components/TextField';
import { useAuthenticationController } from './controllers/useAuthenticationController';

type AuthenticationScreenProps = {} & GenericScreenProps<
  typeof AUTHENTICATION_SCREEN
>;

export const AuthenticationScreen: React.FC<AuthenticationScreenProps> = () => {
  const [t] = useLanguage();
  const { emailValue, errors, onEmailChange, signUpIn } =
    useAuthenticationController();

  return (
    <Layout
      title={t('authentication.title')}
      description={t('authentication.description')}
      showNavbar={false}
      showSeparator={false}
    >
      <View style={s`mt-8`}>
        <TextField
          placeholder={t('authentication.email')}
          autoCapitalize="none"
          keyboardType="email-address"
          value={emailValue}
          onChangeText={onEmailChange}
          error={errors.email?.message}
        />
        <Description style={s`mt-3`}>
          {t('authentication.passwordlessExplanation')}
        </Description>
      </View>
      <View style={s`flex-1`} />
      <View
        style={[
          s`border-t border-light-grey px-page-padding pt-4 mt-8`,
          styles.footer,
        ]}
      >
        <Button isDisabled={!emailValue} onPress={signUpIn}>
          {t('authentication.cta')}
        </Button>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  footer: {
    marginHorizontal: -27,
  },
});
