import './DatePicker.css';

import * as React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { DatePickerProps } from 'react-native-date-picker';
import { Button, ButtonType, UmModal } from '../../../components';
import { View } from 'react-native';
import { s } from 'react-native-wind';
import { useLanguage } from '../../../locale';
import it from 'date-fns/locale/it';

registerLocale('it', it);
registerLocale('it-IT', it);

export const DatePicker: React.FC<React.PropsWithChildren<DatePickerProps>> =
  React.memo(
    ({
      date,
      locale,
      maximumDate,
      minimumDate,
      minuteInterval,
      modal,
      mode,
      open = false,
      title,
      onCancel,
      onConfirm,
      onDateChange,
    }) => {
      const [t] = useLanguage();
      const [value, setValue] = React.useState<Date | null>(date);

      const datePicker = (
        <ReactDatePicker
          fixedHeight
          inline
          locale={locale}
          maxDate={maximumDate}
          minDate={minimumDate}
          scrollableYearDropdown
          selected={value}
          showTimeSelect={mode === 'datetime' || mode === 'time'}
          showTimeSelectOnly={mode === 'time'}
          showYearDropdown
          timeIntervals={minuteInterval}
          title={title ?? undefined}
          yearDropdownItemNumber={100}
          onChange={(date) => {
            setValue(date);

            if (onDateChange && date) {
              onDateChange(date);
            }
          }}
        />
      );

      React.useEffect(() => {
        setValue(date);
      }, [date]);

      return modal ? (
        <UmModal isOpen={open}>
          <View style={s`flex items-center`}>{datePicker}</View>

          <View style={s`flex-row mt-4`}>
            <Button
              buttonType={ButtonType.Tertiary}
              containerStyle={s`flex-1 mr-4`}
              onPress={onCancel}
            >
              {t('timePicker.cancel')}
            </Button>

            <Button
              buttonType={ButtonType.Primary}
              containerStyle={s`flex-1`}
              onPress={() => onConfirm && value && onConfirm(value)}
            >
              {t('timePicker.confirm')}
            </Button>
          </View>
        </UmModal>
      ) : (
        datePicker
      );
    }
  );
