import { SubscribeSlackRequest } from '../../api/slack/slack.models';
import { useApi } from '../../context/ApiContext/useApi';
import { useAppMutation } from './utils';

export const useSubscribeSlack = () => {
  const { slackApi } = useApi();
  const mutation = useAppMutation((payload: SubscribeSlackRequest) => {
    return slackApi.subscribe(payload);
  });
  return mutation;
};
