import * as React from 'react';
import Svg, { SvgProps, Path, ClipPath, Defs, G } from 'react-native-svg';
import { IconProps } from './types';

type BronzeMedalIconProps = IconProps & SvgProps;

const BronzeMedalIcon = ({
  width,
  height,
  size,
  ...props
}: BronzeMedalIconProps) => (
  <Svg
    viewBox="0 0 20 20"
    width={width || size}
    height={height || size}
    fill="none"
    {...props}
  >
    <G clipPath="url(#a)">
      <Path
        fill="#0083FD"
        d="m9.14 14.914-2.998-.999-.879-.005-1.537 4.417a.203.203 0 0 0 .213.269l2.155-.226c.06-.006.12.015.163.057l1.55 1.515a.203.203 0 0 0 .333-.079l1.548-4.445-.548-.504Z"
      />
      <Path
        fill="#0072FC"
        d="M9.688 15.418 10 14.52l-4.425-1.505-.312.895a8.123 8.123 0 0 0 4.425 1.508Z"
      />
      <Path
        fill="#0083FD"
        d="m10.86 14.914 2.999-.999.878-.005 1.538 4.417a.203.203 0 0 1-.213.269l-2.155-.226a.203.203 0 0 0-.164.057l-1.549 1.515a.203.203 0 0 1-.334-.079l-1.547-4.445.548-.504Z"
      />
      <Path
        fill="#0072FC"
        d="M10.313 15.418 10 14.52l4.425-1.505.312.895a8.122 8.122 0 0 1-4.424 1.508Z"
      />
      <Path
        fill="#FF9838"
        d="M10 13.91c-7.25 0-7.257-6.895-7.25-7.016a7.26 7.26 0 0 1 14.5 0c.007.121 0 7.016-7.25 7.016Z"
      />
      <Path
        fill="#FF8709"
        d="M10 13.787a7.26 7.26 0 0 1-7.25-6.893 7.26 7.26 0 1 0 14.501 0A7.26 7.26 0 0 1 10 13.787Z"
      />
      <Path
        fill="#CE7430"
        d="M15.72 7.26c0 2.45-2.561 5.368-5.72 5.368S4.28 9.548 4.28 7.26a5.72 5.72 0 0 1 11.438 0Z"
      />
      <Path
        fill="#BC672E"
        d="M10 12.247a5.72 5.72 0 0 1-5.707-5.353 5.72 5.72 0 1 0 11.414 0A5.72 5.72 0 0 1 10 12.247Z"
      />
      <Path
        fill="#FFAA5C"
        d="M10.007 10.657c-1.231 0-2.015-.85-2.166-1.857-.014-.092-.018-.288-.018-.288a.72.72 0 0 1 1.388.262.797.797 0 1 0 1.503-.366s-.054-.678-.668-.678c-.616 0-.657-.837-.657-.837a.719.719 0 0 1 .618-.353.797.797 0 1 0-.796-.795.719.719 0 1 1-1.438 0 2.236 2.236 0 0 1 2.234-2.234 2.236 2.236 0 0 1 2.234 2.234c0 .584-.225 1.116-.593 1.514.29.314.49.71.563 1.15 0 0 .002.24-.018.364-.179 1.047-.954 1.884-2.186 1.884Z"
      />
      <Path
        fill="#EA9957"
        d="M10.008 7.247a.718.718 0 0 1-.619-.353.719.719 0 0 0 .618 1.085c.308 0 .574.174.707.43a.797.797 0 0 0-.706-1.162ZM10.007 10.277a2.237 2.237 0 0 1-2.183-1.764.716.716 0 0 0-.05.262 2.236 2.236 0 0 0 2.233 2.234 2.236 2.236 0 0 0 2.204-2.6 2.237 2.237 0 0 1-2.204 1.868Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h20v20H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default BronzeMedalIcon;
