import { createNotImplementedMethod } from '../utils';
import {
  Achievement,
  Challenge,
  CurrentBadge,
  GetChallengeRequest,
  GetHistoryChallengesRequest,
  GetCompanyLeaderboardPositionRequest,
  GetCompanyLeaderboardRequest,
  LeaderboardElement,
  MyLeaderboardPosition,
  GetAchievementsRequest,
  BadgeGained,
} from './gamification.models';

export interface IGamificationApi {
  readonly getCompanyLeaderboardPosition: (
    req: GetCompanyLeaderboardPositionRequest
  ) => Promise<MyLeaderboardPosition>;

  readonly getCompanyLeaderboard: (
    req: GetCompanyLeaderboardRequest
  ) => Promise<LeaderboardElement[]>;

  readonly getCurrentBadge: () => Promise<CurrentBadge>;
  readonly getTotalBadges: () => Promise<BadgeGained[]>;
  readonly getChallenge: (req: GetChallengeRequest) => Promise<Challenge>;
  readonly getHistoryChallenges: (
    req: GetHistoryChallengesRequest
  ) => Promise<Challenge[]>;
  readonly getAchievements: (
    req: GetAchievementsRequest
  ) => Promise<Achievement[]>;
}

export class NotImplementedGamificationApi implements IGamificationApi {
  public readonly getCompanyLeaderboardPosition = createNotImplementedMethod();
  public readonly getCompanyLeaderboard = createNotImplementedMethod();
  public readonly getCurrentBadge = createNotImplementedMethod();
  public readonly getTotalBadges = createNotImplementedMethod();
  public readonly getChallenge = createNotImplementedMethod();
  public readonly getHistoryChallenges = createNotImplementedMethod();
  public readonly getAchievements = createNotImplementedMethod();
}
