import { s } from 'react-native-wind';
import * as React from 'react';
import { Button, ButtonType, UmBottomBar } from '../../../components';
import { useLanguage } from '../../../locale';

type UmUserNotificationsBottomBarProps = {
  onCancelPress: () => void;
  onSavePress: () => void;
};

export const UmUserNotificationsBottomBar: React.FC<
  UmUserNotificationsBottomBarProps
> = ({ onCancelPress, onSavePress }) => {
  const [t] = useLanguage();

  return (
    <UmBottomBar>
      <Button
        onPress={onCancelPress}
        containerStyle={s`flex-1 mr-4`}
        buttonType={ButtonType.Tertiary}
      >
        {t('userNotification.cancel')}
      </Button>
      <Button onPress={onSavePress} containerStyle={s`flex-1`}>
        {t('userNotification.save')}
      </Button>
    </UmBottomBar>
  );
};
