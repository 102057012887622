export const ALL_BADGES_SCREEN = 'Qomprendo | All Badges';
export const AUTHENTICATED_BOTTOM_TAB_NAME =
  'Qomprendo | Authenticated Bottom Tab Name';
export const LEADERBOARD_SCREEN = 'Qomprendo | Leaderboard';
export const EDIT_PROFILE_SCREEN = 'Qomprendo | Edit Profile';
export const FEEDBACK_DETAILS_SCREEN = 'Qomprendo | Feedback Details';
export const TEAM_MEMBERS_SCREEN = 'Qomprendo | Team Members';
export const USER_NOTIFICATIONS_SCREEN = 'Qomprendo | User Notifications';
export const ALL_CHALLENGES_SCREEN = 'Qomprendo | All Challenges';
export const HARMONIA_ASSISTANT_SCREEN = 'Qomprendo | Harmonia';
export const SLACK_INTEGRATION_SCREEN = 'Qomprendo | Slack integration';

export type AuthenticatedPages =
  | typeof ALL_BADGES_SCREEN
  | typeof AUTHENTICATED_BOTTOM_TAB_NAME
  | typeof EDIT_PROFILE_SCREEN
  | typeof TEAM_MEMBERS_SCREEN
  | typeof USER_NOTIFICATIONS_SCREEN
  | typeof LEADERBOARD_SCREEN
  | typeof FEEDBACK_DETAILS_SCREEN
  | typeof ALL_CHALLENGES_SCREEN
  | typeof HARMONIA_ASSISTANT_SCREEN
  | typeof SLACK_INTEGRATION_SCREEN;

export type AuthenticatedScreenParams = {
  [ALL_BADGES_SCREEN]: undefined;
  [AUTHENTICATED_BOTTOM_TAB_NAME]: undefined;
  [LEADERBOARD_SCREEN]: undefined;
  [EDIT_PROFILE_SCREEN]: undefined;
  [TEAM_MEMBERS_SCREEN]: {
    teamId: string;
  };
  [USER_NOTIFICATIONS_SCREEN]: {
    teamId: string;
    userId: string;
  };
  [FEEDBACK_DETAILS_SCREEN]: {
    teamId: string;
    userId: string;
  };
  [ALL_CHALLENGES_SCREEN]: undefined;
  [HARMONIA_ASSISTANT_SCREEN]: undefined;
  [SLACK_INTEGRATION_SCREEN]: undefined;
};
