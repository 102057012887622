import { s } from 'react-native-wind';
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

type SeparatorProps = {
  style?: StyleProp<ViewStyle>;
};

export const Separator: React.FC<SeparatorProps> = ({ style }) => {
  return <View style={[s`h-0.25 bg-light-grey`, style]} />;
};
