import { StyleSheet } from 'react-native';
import { Config } from '../../../config';

export const styles = StyleSheet.create({
  box: {
    alignItems: 'center',
    backgroundColor: Config.colorCard,
    borderColor: 'transparent',
    borderRadius: 9,
    borderWidth: 0,
    fontSize: 16,
    fontWeight: 'bold',
    justifyContent: 'center',
    textAlign: 'center',
  },
  disabledText: {
    opacity: 0.5,
  },
});
