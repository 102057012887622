import { useGetMyCompanies } from './api/company.api';
import { useGetMyDetails } from './api/user.api';

const useUserData = (isEnabled = true) => {
  const { data: companies } = useGetMyCompanies(false, isEnabled);
  const { data: user } = useGetMyDetails(isEnabled);

  const company = companies?.[0];

  return { user, company, isReady: !!user && !!company };
};

export default useUserData;
