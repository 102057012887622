import dayjs from 'dayjs';
import _ from 'lodash';
import * as React from 'react';
import { Feedback } from '../../../api/feedback/feedback.models';
import { normalizeChartFeedbacks } from '../../../utils/feedbacks';
import { round } from '../../../utils/round';
import { Dimensions, PanResponder } from 'react-native';

export const useFeedbackLineChartSectionController = (
  feedbacks: Feedback[] = []
) => {
  const feedbacksGroupedByDate = React.useMemo(() => {
    if (feedbacks) {
      return _.groupBy(feedbacks, (e) =>
        dayjs(e.createdAt).format('YYYY-MM-DD')
      );
    }
  }, [feedbacks]);

  const dateKeys = React.useMemo(() => {
    const keys: string[] = [];

    for (let i = 14; i >= 0; i--) {
      keys.push(dayjs().subtract(i, 'days').format('YYYY-MM-DD'));
    }

    return keys;
  }, []);

  const reversedDates = React.useMemo(() => {
    if (feedbacksGroupedByDate) {
      return Object.keys(feedbacksGroupedByDate).reverse();
    }
  }, [feedbacksGroupedByDate]);

  const feedbacksList = React.useMemo(() => {
    if (reversedDates && feedbacksGroupedByDate) {
      return reversedDates.map((stringDate) => {
        return {
          label: stringDate,
          value: feedbacksGroupedByDate[stringDate].reduce<number | null>(
            (pv, cv, i, a) => {
              const isLast = i === a.length - 1;
              if (pv === null) return cv.value;
              if (cv.value === null && !isLast) return pv;

              let total = pv;
              if (cv.value !== null) {
                total += cv.value;
              }

              if (!isLast) return total;

              const noNullLenght = a.filter((e) => e.value !== null).length;

              return round(total / noNullLenght, 1);
            },
            null
          ),
        };
      });
    }
  }, [feedbacksGroupedByDate, reversedDates]);

  const expandedFeedbackList = React.useMemo(() => {
    const list: { label: string; value: number | null }[] = [];
    dateKeys.forEach((k) => {
      const feedback = feedbacksList?.find((f) => f.label === k);
      if (feedback) {
        list.push(feedback);
      } else {
        list.push({
          label: k,
          value: null,
        });
      }
    });
    return list;
  }, [dateKeys, feedbacksList]);

  const normalizedFeedbackList = React.useMemo(() => {
    return normalizeChartFeedbacks(expandedFeedbackList);
  }, [expandedFeedbackList]);

  const apx = (size = 0) => (Dimensions.get('window').width / 750) * size;

  const [positionX, setPositionX] = React.useState(-1);

  const updatePosition = React.useCallback(
    (x: number) => {
      const YAxisWidth = apx(130);
      const x0 = apx(0);
      const chartWidth = apx(750) - YAxisWidth - x0;
      const xN = x0 + chartWidth;
      const xDistance = chartWidth / normalizedFeedbackList.length;
      if (x <= x0) {
        x = x0;
      }
      if (x >= xN) {
        x = xN;
      }

      let value = Math.floor((x - x0) / xDistance);
      if (value >= normalizedFeedbackList.length - 1) {
        value = normalizedFeedbackList.length - 1;
      }

      setPositionX(Number(value));
    },
    [normalizedFeedbackList]
  );

  const panResponder = React.useMemo(
    () =>
      PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onStartShouldSetPanResponderCapture: () => true,
        onMoveShouldSetPanResponder: () => true,
        onMoveShouldSetPanResponderCapture: () => true,
        onPanResponderTerminationRequest: () => true,

        onPanResponderGrant: (evt) => {
          updatePosition(evt.nativeEvent.locationX);
          return true;
        },
        onPanResponderMove: (evt) => {
          updatePosition(evt.nativeEvent.locationX);
          return true;
        },
        onPanResponderRelease: () => {
          setPositionX(-1);
        },
      }),
    [updatePosition]
  );

  return {
    normalizedFeedbackList,
    expandedFeedbackList,
    panResponder,
    positionX,
  };
};
