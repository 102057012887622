import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Button, Subtitle } from '../../../components';
import { s } from 'react-native-wind';
import slackUmmyIcon from '../../../../assets/slack-ummy.png';
import { useNavigation } from '@react-navigation/native';
import { STATS_SCREEN } from '../../../navigation/authenticatedWithBottomTab/authenticated.types';
import { AppNavigation } from '../../../navigation/core/types';
import { useLanguage } from '../../../locale';
import { AUTHENTICATED_BOTTOM_TAB_NAME } from '../../../navigation/authenticated/authenticated.types';

const SlackSuccessFallback = () => {
  const { navigate } = useNavigation<AppNavigation<typeof STATS_SCREEN>>();
  const [t] = useLanguage();
  return (
    <View style={s`flex flex-col justify-center h-full`}>
      <Image style={styles.image} source={slackUmmyIcon} />
      <Subtitle style={s`text-center flex-1`}>
        {t('slack.successFallback.title')}
      </Subtitle>
      <Button onPress={() => navigate(AUTHENTICATED_BOTTOM_TAB_NAME)}>
        {t('slack.successFallback.cta')}
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 180,
    height: 180,
    alignSelf: 'center',
    marginBottom: '20%',
    marginTop: '20%',
  },
});

export default SlackSuccessFallback;
