import * as React from 'react';
import { Defs, LinearGradient, Stop } from 'react-native-svg';
import { Config } from '../../../../config';

type GradientProps = {};

const Gradient: React.FC<GradientProps> = () => {
  return (
    <Defs key={'defs'}>
      <LinearGradient id={'gradient'} x1={'0%'} y1={'0%'} x2={'0%'} y2={'100%'}>
        <Stop offset={'0%'} stopColor={Config.colorPrimary} stopOpacity={0.3} />
        <Stop offset={'100%'} stopColor={Config.colorPrimary} stopOpacity={0} />
      </LinearGradient>
    </Defs>
  );
};

export default Gradient;
