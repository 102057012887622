import { s } from 'react-native-wind';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Entypo } from '@expo/vector-icons';

type CheckboxProps = {
  isChecked: boolean;
  onCheckboxPress: () => void;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  isChecked,
  onCheckboxPress,
}) => {
  return (
    <TouchableOpacity
      onPress={onCheckboxPress}
      style={s`h-6 w-6 ${
        isChecked ? 'bg-primary' : 'bg-card border border-text-secondary'
      } rounded justify-center items-center`}
    >
      {isChecked ? <Entypo name="check" size={20} color="#fff" /> : null}
    </TouchableOpacity>
  );
};
