import noTree from '../../assets/personal_progress_bagnur.png';
import miniTree from '../../assets/personal_progress_foglioline.png';
import smallTree from '../../assets/personal_progress_alberello.png';
import mediumTree from '../../assets/personal_progress_uccellino.png';
import fullTree from '../../assets/personal_progress_albero_grande.png';
import { BadgeImage } from '../api/gamification/gamification.models';

export const getBadgeImageFromId = (imageId: string | undefined) => {
  switch (imageId) {
    case BadgeImage.NoTree:
      return noTree;
    case BadgeImage.MiniTree:
      return miniTree;
    case BadgeImage.SmallTree:
      return smallTree;
    case BadgeImage.MediumTree:
      return mediumTree;
    case BadgeImage.FullTree:
      return fullTree;
    default:
      return noTree;
  }
};
