import { s } from 'react-native-wind';
import React, { useCallback, useState } from 'react';
import {
  AUTHENTICATION_SCREEN,
  INTRO_SCREEN,
} from '../../navigation/authentication/authentication.types';
import { GenericScreenProps } from '../../navigation/core/types';
import { Layout, Button } from '../../components';
import { View } from 'react-native';
import {
  PagerView,
  type PagerViewOnPageSelectedEvent,
} from '../../portability/components/PagerView/PagerView';
import { useLanguage } from '../../locale';
import { IntroPagerSlide } from './components/IntroPagerSlide';
import introFeedbackImage from '../../../assets/intro-team-feedback.png';
import introHistoryImage from '../../../assets/intro-history.png';
import introGamificationImage from '../../../assets/intro-gamification.png';
import { Paginator } from '../../components/Paginator';

type IntroScreenProps = {} & GenericScreenProps<typeof INTRO_SCREEN>;

export const IntroScreen: React.FC<IntroScreenProps> = ({ navigation }) => {
  const [t] = useLanguage();

  const [selectedPage, setSelectedPage] = useState(0);

  const goToAuth = useCallback(() => {
    navigation.replace(AUTHENTICATION_SCREEN);
  }, [navigation]);

  const changePage = useCallback((event: PagerViewOnPageSelectedEvent) => {
    setSelectedPage(event.nativeEvent.position);
  }, []);

  return (
    <Layout showNavbar={false}>
      <Paginator
        containerStyle={s`mb-4`}
        pageCount={3}
        selectedIndex={selectedPage}
      />
      <View style={s`flex-1`}>
        <PagerView
          style={s`flex-1`}
          initialPage={0}
          onPageSelected={changePage}
        >
          <IntroPagerSlide
            title={t('intro.feedbackTitle')}
            description={t('intro.feedbackDescription')}
            imageSource={introFeedbackImage}
          />
          <IntroPagerSlide
            title={t('intro.historyTitle')}
            description={t('intro.statsDescription')}
            imageSource={introHistoryImage}
          />
          <IntroPagerSlide
            title={t('intro.gamificationTitle')}
            description={t('intro.rewardsDescription')}
            imageSource={introGamificationImage}
          />
        </PagerView>
      </View>
      <View style={s`mt-6`}>
        <View style={s`flex-row`}>
          <Button onPress={goToAuth} containerStyle={s`flex-1`}>
            {t('intro.enter')}
          </Button>
        </View>
      </View>
    </Layout>
  );
};
