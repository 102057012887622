import { Config } from '../../config';
import {
  IHttpClient,
  IAuthConsumer,
  RefreshAuthDelegate,
} from '../../services/HttpClient/IHttpClient';
import {
  IStorageProvider,
  StorageKeys,
} from '../../services/StorageProvider/IStorageProvider';
import { randomPassword } from '../../utils/miscellaneous';
import {
  SignInRequest,
  TokensResponse,
  SignUpRequest,
  VerifyCodeRequest,
  SignInResponse,
} from './authorization.models';
import { IAuthorizationApi } from './IAuthorizationApi';

export class AuthorizationApi
  implements IAuthorizationApi, RefreshAuthDelegate
{
  private waitingPromise: Promise<TokensResponse> | null | undefined;

  constructor(
    private readonly httpClient: IHttpClient,
    private readonly authConsumer: IAuthConsumer,
    private readonly storageProvider: IStorageProvider
  ) {
    authConsumer.setupRefreshDelegate(this);
  }

  public readonly signIn = async (
    signInBody: SignInRequest
  ): Promise<SignInResponse> => {
    const signInResponse: SignInResponse =
      await this.httpClient.post<SignInResponse>('', {
        body: {
          AuthFlow: 'CUSTOM_AUTH',
          ClientId: Config.userPoolWebClientId,
          AuthParameters: {
            USERNAME: signInBody.email,
          },
        },
        headers: {
          'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
        },
        isAuthorized: false,
      });
    return signInResponse;
  };

  public readonly signUp = async (signUpBody: SignUpRequest): Promise<{}> => {
    const signUpResponse = await this.httpClient.post<{}>('', {
      body: {
        ClientId: Config.userPoolWebClientId,
        Username: signUpBody.email,
        Password: randomPassword(32),
        UserAttributes: [
          { Name: 'preferred_username', Value: signUpBody.username },
        ],
        ValidationData: [],
      },
      headers: {
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.SignUp',
      },
      isAuthorized: false,
    });
    return signUpResponse;
  };

  public readonly refresh = async (
    refreshToken: string
  ): Promise<TokensResponse> => {
    if (this.waitingPromise) {
      return this.waitingPromise;
    }
    this.waitingPromise = this.refreshCall(refreshToken);
    return this.waitingPromise;
  };

  public readonly refreshCall = async (
    refreshToken: string
  ): Promise<TokensResponse> => {
    try {
      const refreshResponse = await this.httpClient.post<TokensResponse>('', {
        body: {
          AuthParameters: { REFRESH_TOKEN: refreshToken },
          AuthFlow: 'REFRESH_TOKEN_AUTH',
          ClientId: Config.userPoolWebClientId,
        },
        headers: {
          'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
        },
      });
      await this.saveTokens(refreshResponse);
      this.waitingPromise = null;
      return refreshResponse;
    } catch (e: any) {
      if (e?.status) await this.removeTokenThings();
      this.waitingPromise = null;
      throw e;
    }
  };

  public readonly verifyCode = async (
    verifyCodeBody: VerifyCodeRequest
  ): Promise<TokensResponse> => {
    const verifyCodeResponse = await this.httpClient.post<TokensResponse>('', {
      body: {
        ChallengeResponses: {
          USERNAME: verifyCodeBody.email,
          ANSWER: verifyCodeBody.otp,
        },
        ChallengeName: 'CUSTOM_CHALLENGE',
        ClientId: Config.userPoolWebClientId,
        Session: verifyCodeBody.session,
      },
      headers: {
        'X-Amz-Target':
          'AWSCognitoIdentityProviderService.RespondToAuthChallenge',
      },
    });

    await this.saveTokens(verifyCodeResponse);
    return verifyCodeResponse;
  };

  private readonly removeTokenThings = async () => {
    this.httpClient.cancelRequests();
    this.authConsumer.removeAuth();
    await this.storageProvider.removeItem(StorageKeys.ACCESS_TOKEN_KEY);
    await this.storageProvider.removeItem(StorageKeys.REFRESH_TOKEN_KEY);
  };

  private readonly saveTokens = async (refreshResponse: TokensResponse) => {
    this.authConsumer.setupAuth(
      refreshResponse.AuthenticationResult.AccessToken,
      refreshResponse.AuthenticationResult.RefreshToken
    );
    await this.storageProvider.setItem(
      StorageKeys.ACCESS_TOKEN_KEY,
      refreshResponse.AuthenticationResult.AccessToken
    );
    if (refreshResponse.AuthenticationResult.RefreshToken) {
      await this.storageProvider.setItem(
        StorageKeys.REFRESH_TOKEN_KEY,
        refreshResponse.AuthenticationResult.RefreshToken
      );
    }
  };
}
