import * as React from 'react';
import { Image, LayoutRectangle, View } from 'react-native';
import { Layout } from '../../components';
import { useBackNavbarAction } from '../../hooks';
import backgroundImg from '../../../assets/all_badges_background.png';
import treeImg from '../../../assets/all_badges_tree.png';
import { s } from 'react-native-wind';
import { type Tree, placeTrees } from './utils/placeTrees';
import { useStorageProvider } from '../../context/StorageProviderContext/useStorageProvider';
import { StorageKeys } from '../../services/StorageProvider/IStorageProvider';
import { useGetTotalBadges } from '../../hooks/api/gamification.api';
import { Edge } from 'react-native-safe-area-context';

const statsScreenSafeAreaEdges: Edge[] = ['left', 'right', 'top'];

// --------------------------------
// Constants
// --------------------------------

const IMG_HEIGHT = 96;
const IMG_WIDTH = 75;
const MIN_DISTANCE = 300;

// --------------------------------
// Types
// --------------------------------

type AllBadgesScreenProps = {};

// --------------------------------
// Component
// --------------------------------

export const AllBadgesScreen: React.FC<AllBadgesScreenProps> = () => {
  const backAction = useBackNavbarAction();
  const storage = useStorageProvider();
  const { data: badges } = useGetTotalBadges();

  const [isLoad, setIsLoad] = React.useState(false);
  const [rectangle, setRectangle] = React.useState<LayoutRectangle>();
  const [trees, setTrees] = React.useState<Array<Tree>>();

  React.useEffect(() => {
    const initTreesFromStorage = async () => {
      const storageTrees = await storage.getItemValue<Array<Tree>>(
        StorageKeys.TREES_KEY
      );

      if (!storageTrees) {
        setIsLoad(true);
        return;
      }

      setTrees(storageTrees);
      setIsLoad(true);
    };

    initTreesFromStorage();
  }, [storage]);

  React.useEffect(() => {
    if (!isLoad || badges === undefined || !rectangle) {
      return;
    }

    const initTrees = async () => {
      const options = {
        count: badges[0].count,
        minDistance: MIN_DISTANCE,
        xMax: rectangle.width - IMG_WIDTH,
        yMax: rectangle.height - IMG_HEIGHT,
      };

      const storageTrees =
        (await storage.getItemValue<Array<Tree>>(StorageKeys.TREES_KEY)) ?? [];

      const updatedTrees = placeTrees(options, storageTrees);

      setTrees(updatedTrees);
      await storage.setItemValue(StorageKeys.TREES_KEY, updatedTrees);
    };

    initTrees();
  }, [isLoad, rectangle, storage, badges]);

  return (
    <Layout
      safeAreaEdges={statsScreenSafeAreaEdges}
      containerStyle={s`px-0 py-0`}
      isScrollView={false}
      leftAction={backAction}
    >
      <View
        style={[s`h-full relative`, { backgroundColor: '#FCFFF0' }]}
        onLayout={(event) => setRectangle(event.nativeEvent.layout)}
      >
        <Image style={s`flex-1 w-full z-0`} source={backgroundImg} />

        {trees?.map((tree, index) => (
          <Image
            key={index}
            style={[s`absolute z-10`, tree]}
            source={treeImg}
          />
        ))}
      </View>
    </Layout>
  );
};
