import React from 'react';
import type { AreaChartProps } from 'react-native-svg-charts';
import { ResponsiveLine, LineSvgProps } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';
import dayjs from 'dayjs';
import { Config } from '../../../config';
import { View } from 'react-native-animatable';
import { Text } from 'react-native';
import { s } from 'react-native-wind';

export type { AreaChartProps };

export const AreaChart = ({
  data,
  yMin = Math.min(...data.map((d) => d.value)),
  yMax = Math.max(...data.map((d) => d.value)),
}: AreaChartProps<{ label: string; value: number; wasNull: boolean }>) => {
  const dataAdapted: LineSvgProps['data'] = React.useMemo(
    () => [
      {
        id: 'data',
        data: data.map(
          (d) => ({
            x: d.label,
            y: d.value,
            wasNull: d.wasNull,
          }),
          []
        ),
      },
    ],
    [data]
  );

  const gridYValues = React.useMemo(
    () => Array.from({ length: yMax - yMin + 1 }, (_, i) => yMin + i),
    [yMax, yMin]
  );

  return (
    <ResponsiveLine
      areaOpacity={0.3}
      axisBottom={{
        tickSize: 0,
        tickValues: dataAdapted[0].data.map((d, i) => (i % 2 ? d.x : '')),
        tickPadding: 10,
        format: (value) =>
          dayjs(value).isValid()
            ? dayjs(value).format('DD').toUpperCase()
            : value,
      }}
      axisLeft={null}
      colors={{ scheme: 'accent' }}
      data={dataAdapted}
      defs={[
        linearGradientDef('gradientA', [
          { offset: 0, color: 'inherit' },
          { offset: 100, color: 'inherit', opacity: 0.1 },
        ]),
      ]}
      isInteractive
      enableCrosshair
      useMesh
      enableArea={true}
      enableGridX={false}
      fill={[{ match: '*', id: 'gradientA' }]}
      gridYValues={gridYValues}
      lineWidth={3}
      margin={{ top: 10, right: 10, bottom: 30, left: 10 }}
      pointBorderColor={{ from: 'serieColor' }}
      pointBorderWidth={3}
      pointColor="#FFFFFF"
      pointLabelYOffset={-12}
      pointSize={8}
      tooltip={(point) => (
        <View style={s`bg-white rounded px-3 py-2 border border-light-grey`}>
          <Text>{dayjs(point.point.data.x).format('dddd DD MMMM')}</Text>
        </View>
      )}
      pointSymbol={(point) =>
        point.datum.wasNull === false ? (
          <circle
            fill={point.color}
            r={point.size / 2}
            stroke={point.borderColor}
            strokeWidth={point.borderWidth}
          />
        ) : null
      }
      theme={{ fontSize: 15, textColor: Config.colorTextSecondary }}
      yScale={{ type: 'linear', min: yMin, max: yMax }}
    />
  );
};
