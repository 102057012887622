import { IHttpClient } from '../../services/HttpClient/IHttpClient';
import {
  GetMyCompaniesRequest,
  GetMyCompaniesResponse,
  SearchCompanyUsersRequest,
  SearchCompanyUsersResponse,
} from './companies.models';
import { ICompanyApi } from './ICompanyApi';

export class CompanyApi implements ICompanyApi {
  constructor(private readonly httpClient: IHttpClient) {}

  public readonly searchUsers = async ({
    companyId,
    query,
  }: SearchCompanyUsersRequest) => {
    const res = await this.httpClient.get<SearchCompanyUsersResponse>(
      `/core/company/${companyId}/member/search`,
      {
        isAuthorized: true,
        params: {
          query,
        },
      }
    );

    return res;
  };

  public readonly getMyCompanies = async (req: GetMyCompaniesRequest) => {
    const res = await this.httpClient.get<GetMyCompaniesResponse>(
      '/core/company/my',
      {
        isAuthorized: true,
        params: {
          admin: req.admin,
        },
      }
    );
    return res.items;
  };
}
