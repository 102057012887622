import { useInfiniteQuery } from '@tanstack/react-query';
import {
  GetThreadRequest,
  SetMessageShownRequest,
} from '../../api/harmonia/harmonia.models';
import { useApi } from '../../context/ApiContext/useApi';
import {
  InvalidationTag,
  LoaderType,
  useAppMutation,
  useAppQuery,
} from './utils';

export const useGetHarmoniaConfig = (companyId?: string, isEnabled = true) => {
  const { harmoniaApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.HarmoniaConfig, companyId],
    ({ queryKey }) => {
      const id = queryKey[1] as string;
      return harmoniaApi.getHarmoniaConfig({ companyId: id });
    },
    { enabled: isEnabled || !!companyId }
  );
  return queryObject;
};

export const useGetUserThread = (
  { companyId }: GetThreadRequest,
  isEnabled = true
) => {
  const { harmoniaApi } = useApi();
  const queryObject = useInfiniteQuery({
    queryKey: [InvalidationTag.HarmoniaMessages, { companyId }],
    queryFn: ({ pageParam }) => {
      const pageParamParsed = pageParam === 'nothing' ? undefined : pageParam;
      return harmoniaApi.getUserThread({ companyId, next: pageParamParsed });
    },
    enabled: isEnabled,
    getNextPageParam: (lastPage) => lastPage?.next ?? undefined,
    initialPageParam: 'nothing',
  });
  return queryObject;
};

export const useSetMessageShown = () => {
  const { harmoniaApi } = useApi();
  const queryObject = useAppMutation(
    (req: SetMessageShownRequest) => {
      return harmoniaApi.setMessageShown(req);
    },
    undefined,
    LoaderType.None
  );
  return queryObject;
};
