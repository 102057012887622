import dayjs from 'dayjs';
import { VotingDay } from '../api/team/team.models';
import { CallbackTranslation } from '../locale/localizationContext';

export const maxBirthDate = new Date();
export const minBirthDate = dayjs().subtract(90, 'years').toDate();

export function randomPassword(length: number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const getValueInDelimiter = (
  value: string,
  firstDelimiter: string,
  secondDelimiter: string
) => {
  const regExp = new RegExp(`${firstDelimiter}([^)]+)${secondDelimiter}`);
  const matches = regExp.exec(value);
  return matches?.[1];
};

export const getDayTranslation = (t: CallbackTranslation, day: VotingDay) => {
  switch (day) {
    case VotingDay.Monday:
      return t('days.monday');
    case VotingDay.Tuesday:
      return t('days.tuesday');
    case VotingDay.Wednesday:
      return t('days.wednesday');
    case VotingDay.Thursday:
      return t('days.thursday');
    case VotingDay.Friday:
      return t('days.friday');
    case VotingDay.Saturday:
      return t('days.saturday');
    case VotingDay.Sunday:
      return t('days.sunday');
  }
};

export const updateVotingDaysWithNewValue = (
  newVotingDay: VotingDay,
  initialVotingDays: VotingDay[]
): VotingDay[] => {
  const isAlreadySelected = initialVotingDays.includes(newVotingDay);
  let daysToUpdate: VotingDay[];
  if (isAlreadySelected) {
    daysToUpdate = initialVotingDays.filter((vd) => vd !== newVotingDay);
  } else {
    daysToUpdate = [...initialVotingDays, newVotingDay];
  }

  return daysToUpdate;
};
