import { FeatureFlags } from '../api/company/companies.models';
import { useGetMyCompanies } from './api/company.api';

const useFeatureFlags = (isEnabled = true) => {
  const { data: companies } = useGetMyCompanies(false, isEnabled);

  const f = companies?.[0]?.featureFlags;

  const featureFlags: FeatureFlags = {
    CommentStress: !!f && f?.CommentStress !== false,
    CompanyAction: !!f && f?.CompanyAction !== false,
    GamificationChallenges: !!f && f?.GamificationChallenges !== false,
    GamificationLeaderboard: !!f && f?.GamificationLeaderboard !== false,
    Harmonia: !!f && f?.Harmonia !== false,
  };

  return [featureFlags, !!featureFlags] as [FeatureFlags, boolean];
};

export default useFeatureFlags;
