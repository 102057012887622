import { IHttpClient } from '../../services/HttpClient/IHttpClient';
import { ISlackApi } from './ISlackApi';
import { SubscribeSlackRequest } from './slack.models';

export class SlackApi implements ISlackApi {
  constructor(private readonly httpClient: IHttpClient) {}

  public readonly subscribe = async ({
    code,
    companyId,
  }: SubscribeSlackRequest): Promise<boolean> => {
    const res = await this.httpClient.post<boolean>(
      `/slack/company/${companyId}/subscribe`,

      {
        body: { code },
        isAuthorized: true,
      }
    );

    return res;
  };
}
