import * as React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { DatePicker } from '../../portability/components/DatePicker/DatePicker';
import { s } from 'react-native-wind';
import { Button, ButtonType, Layout, Paragraph } from '../../components';
import { useLanguage } from '../../locale';
import { maxBirthDate, minBirthDate } from '../../utils/miscellaneous';
import { useBirthDateController } from './controllers/useBirthDateController';

type BirthDateScreenProps = {};

const BirthDateScreen: React.FC<BirthDateScreenProps> = () => {
  const [t] = useLanguage();
  const {
    confirm,
    date,
    isDatePickerOpen,
    openDatePicker,
    standardDate,
    closeDatePicker,
    next,
    goToAnonymousFeedbackStep,
  } = useBirthDateController();

  return (
    <>
      <Layout
        title={t('onboarding.birthDate.title')}
        description={t('onboarding.birthDate.description')}
        showNavbar={false}
        isScrollView={false}
        showSeparator={false}
      >
        <TouchableOpacity
          style={s`mt-8 bg-card border border-text-secondary rounded-2xl h-12 px-2.5 justify-center`}
          onPress={openDatePicker}
        >
          <Paragraph
            style={s`${date ? 'text-text-primary' : 'text-text-secondary'}`}
          >
            {date
              ? date.format('DD/MM/YYYY')
              : t('onboarding.birthDate.birthDate')}
          </Paragraph>
        </TouchableOpacity>
        <View style={s`flex-1`} />
        <View
          style={[
            s`border-t border-light-grey px-page-padding pt-4 flex-row`,
            styles.footer,
          ]}
        >
          <Button
            onPress={goToAnonymousFeedbackStep}
            buttonType={ButtonType.Tertiary}
            containerStyle={s`mr-4 flex-1`}
          >
            {t('onboarding.birthDate.skip')}
          </Button>
          <Button onPress={next} isDisabled={!date} containerStyle={s`flex-1`}>
            {t('onboarding.birthDate.next')}
          </Button>
        </View>
      </Layout>
      <DatePicker
        modal
        open={isDatePickerOpen}
        date={standardDate ?? maxBirthDate}
        locale="it-IT"
        mode="date"
        maximumDate={maxBirthDate}
        minimumDate={minBirthDate}
        title={t('onboarding.birthDate.birthDate')}
        confirmText={t('onboarding.birthDate.confirm')}
        cancelText={t('onboarding.birthDate.cancel')}
        onConfirm={confirm}
        onCancel={closeDatePicker}
      />
    </>
  );
};

const styles = StyleSheet.create({
  footer: {
    marginHorizontal: -27,
  },
});

export default BirthDateScreen;
