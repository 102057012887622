import { s } from 'react-native-wind';
import * as React from 'react';
import { Image } from 'react-native';
import { Description } from '../../../components';
import manOnCouch from '../../../../assets/man-on-couch.png';

type NoDataProps = {
  descriptions?: string[];
};

export const NoData: React.FC<NoDataProps> = ({ descriptions = [] }) => {
  return (
    <>
      <Image source={manOnCouch} style={s`h-64 w-full mt-4 resize-contain`} />
      {descriptions.map((d, i) => {
        const isFirst = i === 0;
        return (
          <Description
            key={i}
            style={s`text-center ${isFirst ? 'mt-4' : 'mt-2'}`}
          >
            {d}
          </Description>
        );
      })}
    </>
  );
};
