import { s } from 'react-native-wind';
import * as React from 'react';
import { Modal, StyleSheet, View } from 'react-native';

type UmModalProps = {
  isOpen: boolean;
  outerComponent?: React.ReactNode;
};

export const UmModal: React.FC<UmModalProps> = ({
  isOpen,
  children,
  outerComponent,
}) => {
  return (
    <Modal visible={isOpen} animationType="fade" transparent={true}>
      <View
        style={[
          s`flex-1 items-stretch justify-center px-page-padding`,
          styles.container,
        ]}
      >
        <View
          style={s`rounded-2xl py-6 bg-white px-page-padding items-stretch`}
        >
          {children}
        </View>
        {outerComponent}
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
});
