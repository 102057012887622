import * as React from 'react';
import {
  FEEDBACK_DETAILS_SCREEN,
  AUTHENTICATED_BOTTOM_TAB_NAME,
} from '../../navigation/authenticated/authenticated.types';
import { GenericScreenProps } from '../../navigation/core/types';
import {
  FeedbackValue,
  GetFeedbacksRequest,
} from '../../api/feedback/feedback.models';
import dayjs from 'dayjs';
import { useCastFeedback, useGetFeedbacks } from '../../hooks/api/feedback.api';
import {
  Layout,
  Paragraph,
  ParagraphSmall,
  Separator,
  Subtitle,
  Title,
  UmFaces,
} from '../../components';
import { useBackNavbarAction } from '../../hooks';
import { Dimensions, Switch, View } from 'react-native';
import { s } from 'react-native-wind';
import { TextField } from '../../components/TextField';
import { useLanguage } from '../../locale';
import { UmFeedbackDetailsBottomBar } from './components/UmFeedbackDetailsBottomBar';
import * as Haptics from '../../portability/services/Haptics/Haptics';
import { useGetTeamDetails } from '../../hooks/api/team.api';
import { useGetMyCompanies } from '../../hooks/api/company.api';
import { Company } from '../../api/company/companies.models';
import { useGetMyDetails } from '../../hooks/api/user.api';
import { useFullPageLoader } from '../../context/Loader/useFullPageLoader';
import UmFeedbackCategorySection from './components/UmFeedbackCategorySection';

const MAX_COMMENT_CHARACTERS = 250;

type FeedbackDetailsScreenProps = {} & GenericScreenProps<
  typeof FEEDBACK_DETAILS_SCREEN
>;

export const FeedbackDetailsScreen: React.FC<FeedbackDetailsScreenProps> = ({
  navigation,
  route,
}) => {
  const [t] = useLanguage();
  const [openLoader, closeLoader] = useFullPageLoader();
  const facePartHeight = Dimensions.get('window').height / 3;
  const teamId = route.params.teamId;
  const userId = route.params.userId;

  const [comment, setComment] = React.useState<string | undefined>();
  const [isAnonymousVote, setIsAnonymousVote] = React.useState<boolean>();
  const [selectedPositiveCategories, setSelectedPositiveCategories] =
    React.useState<string[]>([]);
  const [selectedNegativeCategories, setSelectedNegativeCategories] =
    React.useState<string[]>([]);
  const [teamCompany, setTeamCompany] = React.useState<Company>();
  const [feedbackVal, setFeedbackVal] = React.useState<FeedbackValue | null>(
    null
  );

  const backAction = useBackNavbarAction();

  const getTodayFeedbacksRequest: GetFeedbacksRequest = React.useMemo(() => {
    return {
      teamId,
      from: dayjs().format('YYYY-MM-DD'),
      to: dayjs().format('YYYY-MM-DD'),
      userId,
    };
  }, [teamId, userId]);

  const { data: myDetails, isLoading: isUserDetailsLoading } =
    useGetMyDetails();
  const { data: myCompanies, isLoading: isCompaniesLoading } =
    useGetMyCompanies(false);
  const { data: teamDetails, isLoading: isTeamDetailsLoading } =
    useGetTeamDetails(teamId);
  const { data: [todayFeedback] = [], isLoading: isTodayFeedbackLoading } =
    useGetFeedbacks(getTodayFeedbacksRequest);
  const { mutateAsync: castFeedback } = useCastFeedback();

  const isSomethingLoading =
    isUserDetailsLoading ||
    isCompaniesLoading ||
    isTeamDetailsLoading ||
    isTodayFeedbackLoading;

  React.useEffect(() => {
    if (isSomethingLoading) {
      openLoader();
    } else {
      closeLoader();
    }
    return () => closeLoader();
  }, [closeLoader, isSomethingLoading, openLoader]);

  React.useEffect(() => {
    if (myCompanies && teamDetails) {
      const tmpTeamCompany = myCompanies.find(
        (c) => c.id === teamDetails.companyId
      );
      setTeamCompany(tmpTeamCompany);
    }
  }, [myCompanies, teamCompany?.id, teamDetails]);

  React.useEffect(() => {
    if (todayFeedback) {
      const feedbackCategoriesIds = todayFeedback.categoryFeedback
        ? Object.keys(todayFeedback.categoryFeedback)
        : [];
      const positiveCategory: string[] = [];
      const negativeCategory: string[] = [];

      feedbackCategoriesIds.forEach((categoryId) => {
        const categoryVal = todayFeedback.categoryFeedback[categoryId];
        if (categoryVal && categoryVal >= 3) {
          positiveCategory.push(categoryId);
        } else if (categoryVal) {
          negativeCategory.push(categoryId);
        }
      });
      setSelectedPositiveCategories(positiveCategory);
      setSelectedNegativeCategories(negativeCategory);
    }
  }, [todayFeedback]);

  React.useEffect(() => {
    setComment(todayFeedback?.comment ?? '');
  }, [todayFeedback]);

  React.useEffect(() => {
    if (todayFeedback?.value) {
      setFeedbackVal(todayFeedback?.value);
    }
  }, [todayFeedback]);

  React.useEffect(() => {
    if (myDetails && todayFeedback) {
      setIsAnonymousVote(
        !!todayFeedback.voteAnonymous ?? !!myDetails.voteAnonymous
      );
    }
  }, [myDetails, todayFeedback]);

  const toggleAnonymousFeedback = React.useCallback(() => {
    setIsAnonymousVote((pv) => !pv);
  }, []);

  const onCastFeedback = React.useCallback(async () => {
    if (!feedbackVal) return;
    const positiveCategoriesObj = selectedPositiveCategories.reduce(
      (pv, cv) => ({
        ...pv,
        [cv]: 4,
      }),
      {}
    );
    const negativeCategoriesObj = selectedNegativeCategories.reduce(
      (pv, cv) => ({
        ...pv,
        [cv]: 1,
      }),
      {}
    );
    try {
      await castFeedback({
        teamId,
        comment,
        value: feedbackVal,
        voteAnonymous: isAnonymousVote,
        categoryFeedback: {
          ...positiveCategoriesObj,
          ...negativeCategoriesObj,
        },
      });
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
      if (navigation.canGoBack()) navigation.goBack();
      else navigation.navigate(AUTHENTICATED_BOTTOM_TAB_NAME);
    } catch (e) {}
  }, [
    castFeedback,
    comment,
    feedbackVal,
    isAnonymousVote,
    navigation,
    selectedNegativeCategories,
    selectedPositiveCategories,
    teamId,
  ]);

  const togglePositiveCategoryGenerator = React.useCallback(
    (categoryId: string) => {
      return () => {
        setSelectedPositiveCategories((pv) => {
          const tmpCat = [...pv];
          const categoryToRemoveIndex = pv.findIndex((e) => e === categoryId);
          if (categoryToRemoveIndex >= 0) {
            tmpCat.splice(categoryToRemoveIndex, 1);
          } else {
            tmpCat.push(categoryId);
          }
          return tmpCat;
        });
      };
    },
    []
  );

  const toggleNegativeCategoryGenerator = React.useCallback(
    (categoryId: string) => {
      return () => {
        setSelectedNegativeCategories((pv) => {
          const tmpCat = [...pv];
          const categoryToRemoveIndex = pv.findIndex((e) => e === categoryId);
          if (categoryToRemoveIndex >= 0) {
            tmpCat.splice(categoryToRemoveIndex, 1);
          } else {
            tmpCat.push(categoryId);
          }
          return tmpCat;
        });
      };
    },
    []
  );

  const onCommentChange = React.useCallback((updatedComment: string) => {
    setComment((pv) => {
      if (updatedComment.length > MAX_COMMENT_CHARACTERS) {
        return pv;
      } else {
        return updatedComment;
      }
    });
  }, []);

  const isCategorySectionCompleted =
    selectedPositiveCategories.length > 0 ||
    selectedNegativeCategories.length > 0;

  const baseFeedbackPoints = feedbackVal ? 70 : 0;
  const commentPoints = comment ? 10 : 0;
  const feedbackCategoriesPoints = isCategorySectionCompleted ? 20 : 0;

  const points = baseFeedbackPoints + commentPoints + feedbackCategoriesPoints;

  return (
    <Layout
      leftAction={backAction}
      bottomBar={
        <UmFeedbackDetailsBottomBar points={points} onFinish={onCastFeedback} />
      }
    >
      <Title>{teamDetails?.name} </Title>
      <Subtitle style={s`text-primary text-sm`}>(70 pt.)</Subtitle>
      {todayFeedback ? (
        <>
          <View
            style={[s`justify-center items-center`, { height: facePartHeight }]}
          >
            <UmFaces
              selectedVote={feedbackVal}
              isEnabled
              isFixedSize={false}
              onPressFace={setFeedbackVal}
            />
          </View>
          <Separator style={s`my-4`} />
          {teamCompany?.categoryFeedback ? (
            <UmFeedbackCategorySection
              companyCategories={teamCompany.categoryFeedback}
              selectedPositiveCategories={selectedPositiveCategories}
              selectedNegativeCategories={selectedNegativeCategories}
              toggleNegativeCategoryGenerator={toggleNegativeCategoryGenerator}
              togglePositiveCategoryGenerator={togglePositiveCategoryGenerator}
            />
          ) : null}
          <Separator style={s`mb-4 mt-8`} />
          <Subtitle>{t('feedback.leaveComment')}</Subtitle>
          <Subtitle
            style={s`text-sm ${
              comment ? 'text-primary' : 'text-text-secondary'
            }`}
          >
            (10 pt.)
          </Subtitle>
          <TextField
            value={comment}
            onChangeText={onCommentChange}
            containerStyle={s`mt-6`}
            placeholder={t('feedback.commentPlaceholder')}
            numberOfLines={6}
          />
          <ParagraphSmall
            style={s`text-right ${
              comment && comment?.length >= MAX_COMMENT_CHARACTERS
                ? 'text-danger'
                : 'text-text-secondary'
            }`}
          >
            {`${comment?.length ?? 0}/${MAX_COMMENT_CHARACTERS}`}
          </ParagraphSmall>
          {isAnonymousVote !== undefined ? (
            <View style={s`mt-6`}>
              <Subtitle>{t('feedback.feedbackVisibility')}</Subtitle>
              <View
                style={s`mt-4 items-center flex-row rounded-2xl bg-card p-4 border border-text-secondary`}
              >
                <Paragraph style={s`flex-1`}>
                  {t('profile.anonymousVoteLabel')}
                </Paragraph>
                <Switch
                  value={isAnonymousVote}
                  onValueChange={toggleAnonymousFeedback}
                />
              </View>
            </View>
          ) : null}
        </>
      ) : null}
    </Layout>
  );
};
