import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
import { IconProps } from './types';

type LikeEmptyIconProps = IconProps & SvgProps;

const LikeEmptyIcon = ({
  width,
  height,
  size,
  ...props
}: LikeEmptyIconProps) => (
  <Svg
    viewBox="0 0 512 512"
    width={width || size}
    height={height || size}
    fill="none"
    {...props}
  >
    <Path
      fill="#C2C2C2"
      d="M490.91 275.24c6.39 8.5 10.18 19.05 10.18 30.48 0 8.65-2.17 16.8-6.01 23.93-3.65 6.83-8.84 12.73-15.08 17.25a50.525 50.525 0 0 1 10.18 30.48c0 16.92-8.31 31.94-21.07 41.19a50.537 50.537 0 0 1 10.16 30.46c0 28.03-22.8 50.83-50.82 50.83H268.43c-26.36 0-52.23-4.69-76.91-13.94l-80.04-30.01V244.36h4.29c60.18-63.58 125.55-135.84 135.44-151.66v-59c0-11.89 9.67-21.56 21.56-21.56 49.04 0 88.94 39.89 88.94 88.93v26.61l-16.09 55.55h115.55c28.03 0 50.83 22.8 50.83 50.83 0 16.92-8.32 31.93-21.09 41.18Z"
    />
    <Path
      fill="#A3A3A3"
      d="M469.11 418.57a50.537 50.537 0 0 1 10.16 30.46c0 28.03-22.8 50.83-50.82 50.83H268.43c-26.36 0-52.23-4.69-76.91-13.94l-80.04-30.01V329.65h383.6c-3.65 6.83-8.84 12.73-15.08 17.25a50.525 50.525 0 0 1 10.18 30.48c0 16.92-8.31 31.94-21.07 41.19Z"
    />
    <Path fill="#646D73" d="M141.48 173.34H0v316.72h141.48V173.34Z" />
    <Path fill="#474F54" d="M141.48 329.65H0v160.41h141.48V329.65Z" />
  </Svg>
);
export default LikeEmptyIcon;
