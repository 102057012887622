import { s } from 'react-native-wind';
import * as React from 'react';
import {
  TEAM_MEMBERS_SCREEN,
  USER_NOTIFICATIONS_SCREEN,
} from '../../navigation/authenticated/authenticated.types';
import { GenericScreenProps } from '../../navigation/core/types';
import { Layout, Separator, Title, UmListItem } from '../../components';
import { FlatList, Platform, View } from 'react-native';
import { useLanguage } from '../../locale';
import { useBackNavbarAction } from '../../hooks';
import { Edge } from 'react-native-safe-area-context';
import { TextField } from '../../components/TextField';
import { TeamUser } from '../../api/team/team.models';
import { useTeamMembersController } from './controllers/useTeamMembersController';

const teamMembersSafeAreaEdges: Edge[] = ['top', 'right', 'left'];

type TeamMembersScreenProps = {} & GenericScreenProps<
  typeof TEAM_MEMBERS_SCREEN
>;

export const TeamMembersScreen: React.FC<TeamMembersScreenProps> = ({
  route,
  navigation,
}) => {
  const [t] = useLanguage();
  const { teamId } = route.params;
  const backIcon = useBackNavbarAction();

  const {
    filteredTeamList,
    isTeamAdmin,
    searchText,
    setSearchText,
    onEndReached,
  } = useTeamMembersController(teamId);

  const renderListItem = React.useCallback(
    ({ item }: { item: TeamUser }) => {
      const onUserPress = isTeamAdmin
        ? () => {
            navigation.navigate(USER_NOTIFICATIONS_SCREEN, {
              userId: item.userId,
              teamId,
            });
          }
        : undefined;
      return (
        <UmListItem
          containerStyle={s`mt-4`}
          key={`user-member-${item.userId}`}
          label={item.username}
          onPress={onUserPress}
        />
      );
    },
    [isTeamAdmin, navigation, teamId]
  );

  const listHeaderComponent = React.useMemo(() => {
    return (
      <View style={s`bg-white`}>
        <Title>{t('teamMembers.title')}</Title>
        <TextField
          value={searchText}
          onChangeText={setSearchText}
          placeholder={t('teamMembers.search')}
          containerStyle={s`mt-4`}
        />
        <Separator style={s`mt-4`} />
      </View>
    );
  }, [searchText, setSearchText, t]);

  return (
    <Layout
      safeAreaEdges={teamMembersSafeAreaEdges}
      leftAction={backIcon}
      isScrollView={Platform.OS === 'web'}
    >
      {isTeamAdmin !== undefined && filteredTeamList ? (
        <>
          <FlatList
            ListHeaderComponent={listHeaderComponent}
            data={filteredTeamList}
            stickyHeaderIndices={[0]}
            onEndReached={onEndReached}
            onEndReachedThreshold={0.3}
            renderItem={renderListItem}
          />
        </>
      ) : null}
    </Layout>
  );
};
