import _ from 'lodash';
import * as React from 'react';
import { StyleSheet, Text } from 'react-native';
import { View } from 'react-native-animatable';
import { PieChart } from '../../../portability/components/PieChart/PieChart';
import { s } from 'react-native-wind';
import { Feedback } from '../../../api/feedback/feedback.models';
import { Description, Separator, Subtitle } from '../../../components';
import { Config } from '../../../config';
import { useLanguage } from '../../../locale';
import { getFeedbackColor } from '../../../utils/feedbacks';

import { useGetMyDetails } from '../../../hooks/api/user.api';
import { getEngagementTranslation } from '../../../utils/getEngagementTranslation';
import { FaceImage } from '../../../components/FaceImage';

type FeedbackCountPieChartSectionProps = {
  feedbacks: Feedback[] | undefined;
};

const FeedbackCountPieChartSection: React.FC<
  FeedbackCountPieChartSectionProps
> = ({ feedbacks = [] }) => {
  const [t] = useLanguage();

  const { data: myDetails } = useGetMyDetails();

  const notNullFeedbacks = React.useMemo(() => {
    return feedbacks.filter((f) => f.value !== null);
  }, [feedbacks]);

  const totalFeedbacks = notNullFeedbacks.length;

  const feedbacksByVote = React.useMemo(() => {
    return _.groupBy(notNullFeedbacks, (e) => e.value);
  }, [notNullFeedbacks]);

  const pieChartDataset = React.useMemo(() => {
    return Object.keys(feedbacksByVote).map((vote) => {
      return {
        svg: { fill: getFeedbackColor(+vote) },
        amount: feedbacksByVote[vote].length,
        key: `pie-chart-${vote}`,
      };
    });
  }, [feedbacksByVote]);

  const totalVeryHappyVotes = feedbacksByVote['4']?.length ?? 0;
  const totalHappyVotes = feedbacksByVote['3']?.length ?? 0;
  const totalSadVotes = feedbacksByVote['2']?.length ?? 0;
  const totalVerySadVotes = feedbacksByVote['1']?.length ?? 0;

  const textTranslation = React.useMemo(() => {
    const firstPartTranslation = t('stats.feedbackCountDescription1', {
      name: myDetails?.username || '',
      feedbackCount: totalFeedbacks,
    });
    const secondPartTranslation = getEngagementTranslation(
      t,
      totalFeedbacks,
      feedbacks.length
    );

    return `${firstPartTranslation} ${secondPartTranslation}`;
  }, [feedbacks.length, myDetails?.username, t, totalFeedbacks]);

  return (
    <>
      <Subtitle>{t('stats.moodsCount')}</Subtitle>
      <Description style={s`mt-4`}>{textTranslation}</Description>
      {totalFeedbacks > 0 ? (
        <View style={[s`w-full mt-4`, { aspectRatio: 2 }]}>
          <PieChart
            style={{ width: '100%', aspectRatio: 1 }}
            valueAccessor={({ item }) => item.amount}
            data={pieChartDataset}
            endAngle={Math.PI * 0.5}
            startAngle={Math.PI * -0.5}
            outerRadius={'95%'}
          />
          <Text style={styles.totalNumber}>{totalFeedbacks}</Text>
        </View>
      ) : null}
      <View style={s`mt-8 flex-row items-center justify-between`}>
        <FaceImage type="veryHappy" votes={totalVeryHappyVotes} />
        <FaceImage type="happy" votes={totalHappyVotes} />
        <FaceImage type="sad" votes={totalSadVotes} />
        <FaceImage type="verySad" votes={totalVerySadVotes} />
      </View>
      <Separator style={s`my-6`} />
    </>
  );
};

const styles = StyleSheet.create({
  totalNumber: {
    fontSize: 48,
    fontFamily: Config.fontMedium,
    textAlign: 'center',
    position: 'absolute',
    alignSelf: 'center',
    bottom: 0,
  },
});

export default FeedbackCountPieChartSection;
