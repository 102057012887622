import * as React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { ArrowsRightLeftIcon } from 'react-native-heroicons/outline';
import { Config } from '../../../config';
import { Description, HomeScreenHeader } from '../../../components';
import { useLanguage } from '../../../locale';
import { fontsStyles } from '../../../styles/general.style';

type TeamSettingsHeaderProps = {
  title?: string;
  isAdmin?: boolean;
  teamsCount: number;
  onRightIconPress?: () => void;
};

export const TeamSettingsHeader: React.FC<TeamSettingsHeaderProps> = React.memo(
  ({ onRightIconPress, isAdmin, title = '', teamsCount }) => {
    const [t] = useLanguage();

    return (
      <HomeScreenHeader
        title={title || ''}
        rightIcon={
          teamsCount > 1 ? (
            <TouchableOpacity onPress={onRightIconPress}>
              <ArrowsRightLeftIcon color={Config.colorTextSecondary} />
            </TouchableOpacity>
          ) : undefined
        }
        bottomComponent={
          isAdmin ? (
            <Description>
              {t('teamSettings.roleDescription1')}{' '}
              <Text style={fontsStyles.medium}>
                {t('teamSettings.administrator')}
              </Text>{' '}
              {t('teamSettings.roleDescription2')}
            </Description>
          ) : undefined
        }
      />
    );
  }
);
