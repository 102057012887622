import * as React from 'react';
import { Route, Stack } from '../core/types';
import { createStack } from '../core/createStack';
import {
  OnboardingPages,
  ONBOARDING_ANONYMOUS_VOTE_SCREEN,
  ONBOARDING_BIRTH_DATE,
  ONBOARDING_NAME,
  ONBOARDING_PRIVACY_TERMS,
} from './onboarding.types';
import PrivacyScreen from '../../screens/privacy/privacy.screen';
import NameScreen from '../../screens/name/name.screen';
import BirthDateScreen from '../../screens/birthDate/birthDate.screen';
import { AnonymousVoteScreen } from '../../screens/anonymousVote/anonymousVote.screen';

export const ONBOARDING_STACK_NAME = 'Qomprendo';

export const onboardingRoutes: Route<OnboardingPages>[] = [
  { name: ONBOARDING_PRIVACY_TERMS, component: PrivacyScreen },
  { name: ONBOARDING_NAME, component: NameScreen },
  { name: ONBOARDING_BIRTH_DATE, component: BirthDateScreen },
  { name: ONBOARDING_ANONYMOUS_VOTE_SCREEN, component: AnonymousVoteScreen },
];

export const onboardingStack: Stack<OnboardingPages> = {
  routes: onboardingRoutes,
  initialScreen: ONBOARDING_PRIVACY_TERMS,
};

export const OnboardingStackComponent = createStack(onboardingStack);

export const OnboardingStack = () => {
  return <OnboardingStackComponent />;
};
