import { RouteProp, useRoute } from '@react-navigation/native';
import { useCallback, useEffect, useState } from 'react';
import {
  useSignInMutation,
  useVerifyCodeMutation,
} from '../../../hooks/api/auth.api';
import { AUTH_CODE_SCREEN } from '../../../navigation/authentication/authentication.types';
import { ScreensProps } from '../../../navigation/core/types';
import {
  setIsAuthorized,
  setIsOnboardingFinished,
} from '../../../redux/general/general.slice';
import { useDispatch } from '../../../redux/utils';
import { useNumPad } from './useNumPad';
import * as Clipboard from 'expo-clipboard';
import { useGetMyDetailsMutation } from '../../../hooks/api/user.api';
import { AppState, Platform } from 'react-native';

export const useAuthCodeController = () => {
  const route: RouteProp<ScreensProps, typeof AUTH_CODE_SCREEN> = useRoute();
  const dispatch = useDispatch();
  const { code, setCode, ...numPadProps } = useNumPad();

  const [currentSession, setCurrentSession] = useState(route.params.session);
  const [isClipboardAvailable, setIsClipboardAvailable] = useState(false);

  const { mutateAsync: getUserDetails } = useGetMyDetailsMutation();
  const { mutateAsync: verifyCodeApiCall } = useVerifyCodeMutation();
  const { mutateAsync: resendEmailApiCall } = useSignInMutation();

  const checkClipboard = useCallback(async () => {
    const stringCopied = await Clipboard.getStringAsync();
    const isValidCode =
      stringCopied.length === 6 && !Number.isNaN(+stringCopied);
    setIsClipboardAvailable(isValidCode);
  }, []);

  const verifyCode = useCallback(async () => {
    const res = await verifyCodeApiCall({
      email: route.params.email,
      otp: code,
      session: currentSession,
    });

    if (res.AuthenticationResult.AccessToken) {
      const user = await getUserDetails();
      dispatch(setIsAuthorized(true));
      if (user.terms) {
        dispatch(setIsOnboardingFinished(true));
      }
    }
  }, [
    code,
    currentSession,
    dispatch,
    getUserDetails,
    route.params.email,
    verifyCodeApiCall,
  ]);

  const resendEmail = useCallback(async () => {
    const res = await resendEmailApiCall({ email: route.params.email });
    if (res) {
      setCurrentSession(res.Session);
    }
  }, [resendEmailApiCall, route.params.email]);

  const pasteFromClipboard = useCallback(async () => {
    const codeFromClipboard = await Clipboard.getStringAsync();
    setCode(codeFromClipboard);
  }, [setCode]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      window.addEventListener('focus', checkClipboard);
      return () => window.removeEventListener('focus', checkClipboard);
    }

    const subscription = AppState.addEventListener('change', checkClipboard);
    return () => subscription.remove();
  }, [checkClipboard]);

  return {
    verifyCode,
    code,
    resendEmail,
    pasteFromClipboard,
    isClipboardAvailable,
    ...numPadProps,
  };
};
