import _, { round } from 'lodash';
import * as React from 'react';
import { Image, View } from 'react-native';
import { s } from 'react-native-wind';
import {
  Feedback,
  SentimentCategory,
} from '../../../api/feedback/feedback.models';
import { Description, Separator, Subtitle, Title } from '../../../components';
import { useLanguage } from '../../../locale';
import manOnCouch from '../../../../assets/man-on-couch.png';

type SentimentSectionProps = {
  feedbacks: Feedback[];
};

const SentimentSection: React.FC<SentimentSectionProps> = ({ feedbacks }) => {
  const [t] = useLanguage();

  const feedbacksWithSentiment = React.useMemo(() => {
    return feedbacks.filter((f) => f.sentiment);
  }, [feedbacks]);

  const prevalingSentiment = React.useMemo(() => {
    const groupedSentiments = _.groupBy(
      feedbacksWithSentiment,
      (f) => f.sentiment!.mainSentiment
    );

    let majorSentiment = {
      sentimentName: SentimentCategory.Mixed,
      sentimentCount: 0,
    };

    Object.keys(groupedSentiments).forEach((sk) => {
      const sentimentImportance = groupedSentiments[sk].length;
      if (sentimentImportance > majorSentiment.sentimentCount) {
        majorSentiment = {
          sentimentCount: sentimentImportance,
          sentimentName: sk as SentimentCategory,
        };
      }
    });

    return majorSentiment.sentimentName;
  }, [feedbacksWithSentiment]);

  const sentimentTranslation = React.useMemo(() => {
    switch (prevalingSentiment) {
      case SentimentCategory.Positive:
        return t('stats.positive');
      case SentimentCategory.Negative:
        return t('stats.negative');
      case SentimentCategory.Neutral:
        return t('stats.neutral');
      case SentimentCategory.Mixed:
        return t('stats.mixed');
    }
  }, [prevalingSentiment, t]);

  const color = React.useMemo(() => {
    switch (prevalingSentiment) {
      case SentimentCategory.Positive:
        return 'text-primary';
      case SentimentCategory.Negative:
        return 'text-secondary';
      case SentimentCategory.Neutral:
        return 'text-secondary';
      case SentimentCategory.Mixed:
        return 'text-secondary';
    }
  }, [prevalingSentiment]);

  const percentageOfComments =
    round(feedbacksWithSentiment.length / feedbacks.length, 2) * 100;

  return (
    <>
      <Subtitle>{t('stats.commentSentiment')}</Subtitle>
      {feedbacksWithSentiment.length > 0 ? (
        <>
          <Description style={s`mt-2`}>
            {t('stats.sentimentsComments')}
          </Description>
          <Title style={s`mt-4 self-center ${color}`}>
            {sentimentTranslation}
          </Title>
          {percentageOfComments < 25 ? (
            <View
              style={s`rounded-3xl bg-secondary-light border border-secondary w-full p-4 pt-3 mt-4`}
            >
              <Description>
                {t('stats.sentimentWarning1')}{' '}
                <Subtitle>{percentageOfComments.toFixed(0)}%</Subtitle>{' '}
                {t('stats.sentimentWarning2')}
              </Description>
              <Description style={s`mt-2`}>
                {t('stats.sentimentWarning3')}
              </Description>
            </View>
          ) : null}
        </>
      ) : (
        <>
          <Image
            source={manOnCouch}
            style={s`h-64 w-full mt-4 resize-contain`}
          />
          <Description style={s`mt-4`}>{t('stats.emptySentiment')}</Description>
          <Description style={s`mt-2`}>
            {t('stats.rememberComment')}
          </Description>
        </>
      )}
      <Separator style={s`my-6`} />
    </>
  );
};

export default SentimentSection;
