import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import {
  useGetMyDetails,
  useUpdateProfileMutation,
} from '../../../hooks/api/user.api';
import { AppNavigation } from '../../../navigation/core/types';
import {
  ONBOARDING_BIRTH_DATE,
  ONBOARDING_NAME,
} from '../../../navigation/onboarding/onboarding.types';

export const useNameController = () => {
  const navigation = useNavigation<AppNavigation<typeof ONBOARDING_NAME>>();

  const [name, setName] = React.useState<string>('');

  const { data: user } = useGetMyDetails();
  const { mutateAsync: updateUser } = useUpdateProfileMutation();

  React.useEffect(() => {
    if (user) {
      setName(user.username);
    }
  }, [user]);

  const updateName = React.useCallback(async () => {
    await updateUser({
      username: name,
    });
    navigation.navigate(ONBOARDING_BIRTH_DATE);
  }, [name, navigation, updateUser]);

  return {
    name,
    updateName,
    setName,
  };
};
