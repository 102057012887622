import { useQueryClient } from '@tanstack/react-query';
import {
  UpdateCountryRequest,
  UpdateProfileRequest,
  UploadImageProfileRequest,
} from '../../api/user/user.models';
import { useApi } from '../../context/ApiContext/useApi';
import {
  InvalidationTag,
  LoaderType,
  useAppMutation,
  useAppQuery,
} from './utils';

export const useGetWhoAmI = () => {
  const { userApi } = useApi();
  const mutation = useAppMutation((email: string) =>
    userApi.getWhoAmI({ email })
  );
  return mutation;
};

export const useGetMyDetails = (isEnabled = true) => {
  const { userApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.MyDetails],
    () => {
      return userApi.getMyDetails();
    },
    { enabled: isEnabled }
  );
  return queryObject;
};

export const useGetMyDetailsMutation = (
  loaderType: LoaderType = LoaderType.FullPage
) => {
  const { userApi } = useApi();
  const mutation = useAppMutation(
    () => {
      return userApi.getMyDetails();
    },
    undefined,
    loaderType
  );
  return mutation;
};

export const useUploadProfileImageMutation = () => {
  const { userApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: UploadImageProfileRequest) => {
      return userApi.uploadProfileImage(req);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.MyDetails],
        });
      },
    }
  );
  return mutation;
};

export const useUpdateProfileMutation = () => {
  const { userApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: UpdateProfileRequest | UpdateCountryRequest) => userApi.updateMy(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.MyDetails],
        });
      },
    }
  );
  return mutation;
};

export const useDeleteProfile = () => {
  const { userApi } = useApi();
  const mutation = useAppMutation(() => {
    return userApi.deleteMy();
  });
  return mutation;
};
