import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import { ArrowsRightLeftIcon } from 'react-native-heroicons/outline';
import { Edge } from 'react-native-safe-area-context';
import { HomeScreenHeader, Layout } from '../../components';
import { ChangeTeamBottomSheet } from '../../components/ChangeTeamBottomSheet';
import { FeedbackDetailsBottomSheet } from '../../components/FeedbackDetailsBottomSheet';
import { Config } from '../../config';
import { useLanguage } from '../../locale';
import DaysInRowSection from './components/DaysInRowSection';
import { FeedbackCategoriesSection } from './components/FeedbackCategoriesSection';
import FeedbackCountPieChartSection from './components/FeedbackCountPieChartSection';
import FeedbackLineChartSection from './components/FeedbackLineChartSection';
import { NoData } from './components/NoData';
import SentimentSection from './components/SentimentSection';
import { TeamCalendarSection } from './components/TeamCalendarSection';
import useStatsController from './controllers/useStatsController';
import HarmoniaButton from '../../components/HarmoniaButton';
import { GenericScreenProps } from '../../navigation/core/types';
import { STATS_SCREEN } from '../../navigation/authenticatedWithBottomTab/authenticated.types';
import { HARMONIA_ASSISTANT_SCREEN } from '../../navigation/authenticated/authenticated.types';

const statsScreenSafeAreaEdges: Edge[] = ['left', 'right', 'top'];

type StatsScreenProps = GenericScreenProps<typeof STATS_SCREEN>;

const StatsScreen: React.FC<StatsScreenProps> = ({ navigation }) => {
  const [t] = useLanguage();
  const {
    isNoDataVisible,
    feedbackData,
    categories,
    showFeedbackDetails,
    myDetails,
    selectedTeam,
    isSummarySelected,
    changeTeamBottomSheetRef,
    feedbackDetailsBottomSheet,
    feedbacksDetails,
    teams,
    openChangeTeamBottomSheet,
    isHarmoniaActive,
    unseenMessages,
  } = useStatsController();

  const navigateToHarmonia = React.useCallback(() => {
    navigation.push(HARMONIA_ASSISTANT_SCREEN);
  }, [navigation]);

  const customHeader = React.useMemo(() => {
    return (
      <HomeScreenHeader
        title={
          isSummarySelected ? t('stats.summary') : selectedTeam?.name || ''
        }
        rightIcon={
          <>
            {teams && teams.length > 1 ? (
              <TouchableOpacity onPress={openChangeTeamBottomSheet}>
                <ArrowsRightLeftIcon color={Config.colorTextSecondary} />
              </TouchableOpacity>
            ) : null}
            {isHarmoniaActive && (
              <HarmoniaButton
                unseenMessages={unseenMessages}
                navigateToHarmonia={navigateToHarmonia}
              />
            )}
          </>
        }
      />
    );
  }, [
    isSummarySelected,
    t,
    selectedTeam?.name,
    teams,
    openChangeTeamBottomSheet,
    isHarmoniaActive,
    unseenMessages,
    navigateToHarmonia,
  ]);

  return (
    <>
      <Layout
        showNavbar={false}
        customNavbar={customHeader}
        safeAreaEdges={statsScreenSafeAreaEdges}
      >
        {isNoDataVisible ? (
          <NoData
            descriptions={[
              t('stats.notEnoughtData1'),
              t('stats.notEnoughtData2'),
            ]}
          />
        ) : (
          <>
            <DaysInRowSection feedbacks={feedbackData} />
            <FeedbackLineChartSection feedbacks={feedbackData} />
            <TeamCalendarSection
              isSummary={isSummarySelected}
              teamId={selectedTeam?.id}
              userId={myDetails?.id}
              onDayPress={showFeedbackDetails}
            />
            <FeedbackCountPieChartSection feedbacks={feedbackData} />
            <SentimentSection feedbacks={feedbackData} />
            <FeedbackCategoriesSection
              feedbacks={feedbackData}
              categories={categories}
            />
          </>
        )}
      </Layout>
      <ChangeTeamBottomSheet ref={changeTeamBottomSheetRef} />
      <FeedbackDetailsBottomSheet
        ref={feedbackDetailsBottomSheet}
        feedbacksDetails={feedbacksDetails}
      />
    </>
  );
};

export default StatsScreen;
