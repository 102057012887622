import * as React from 'react';
import { TeamUser } from '../../../api/team/team.models';
import {
  useGetTeamDetails,
  useGetTeamMembers,
} from '../../../hooks/api/team.api';
import { useGetMyDetails } from '../../../hooks/api/user.api';

export const useTeamMembersController = (teamId: string) => {
  const [filteredTeamList, setFilteredTeamList] = React.useState<TeamUser[]>(
    []
  );
  const [searchText, setSearchText] = React.useState<string>('');

  const { data: myDetails } = useGetMyDetails();
  const { data: teamDetails } = useGetTeamDetails(teamId);
  const {
    data: teamMembersRes,
    hasNextPage,
    fetchNextPage,
  } = useGetTeamMembers({
    teamId,
    limit: '10',
  });

  const teamMembers = React.useMemo(
    () => teamMembersRes?.pages?.flatMap((page) => page.items),
    [teamMembersRes]
  );

  React.useEffect(() => {
    if (searchText && teamMembers) {
      const membersFiltered = teamMembers.filter((tm) =>
        tm.username.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredTeamList(membersFiltered);
    } else {
      setFilteredTeamList(teamMembers ? [...teamMembers] : []);
    }
  }, [searchText, teamMembers]);

  const isTeamAdmin = React.useMemo(() => {
    if (!teamDetails || !myDetails) return undefined;
    return teamDetails?.admins.includes(myDetails?.id);
  }, [myDetails, teamDetails]);

  const onEndReached = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  return {
    onEndReached,
    searchText,
    setSearchText,
    isTeamAdmin,
    filteredTeamList,
  };
};
