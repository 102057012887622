import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React, { useMemo } from 'react';
import { Config } from '../../config';
import { LocalizationKeys, useLanguage } from '../../locale';
import { BottomTab, ScreensProps } from './types';

export const createBottomTab =
  <T extends keyof ScreensProps>(bottomTab: BottomTab<T>) =>
  () => {
    const TabNav = createBottomTabNavigator();
    const [t] = useLanguage();

    const navigatiorOptions = useMemo(() => {
      return { headerShown: false, tabBarActiveTintColor: Config.colorPrimary };
    }, []);

    return (
      <TabNav.Navigator
        initialRouteName={bottomTab.initialScreen}
        screenOptions={navigatiorOptions}
      >
        {bottomTab.routes.map(
          ({
            name,
            component,
            defaultParams,
            focusedTabBarIcon,
            notFocusedTabBarIcon,
            tabBarLabel,
          }) => (
            <TabNav.Screen
              key={name}
              name={name}
              component={component}
              initialParams={defaultParams}
              options={{
                tabBarLabel: t(tabBarLabel as LocalizationKeys),
                tabBarIcon: ({ focused }) =>
                  focused ? focusedTabBarIcon : notFocusedTabBarIcon,
              }}
            />
          )
        )}
      </TabNav.Navigator>
    );
  };
