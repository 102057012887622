import { s } from 'react-native-wind';
import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native';
import { Config } from '../config';

type DescriptionProps = {
  children: ReactNode;
  style?: StyleProp<TextStyle>;
};

export const Description: React.FC<DescriptionProps> = ({
  style,
  children,
}) => {
  const descriptionStyle = StyleSheet.flatten([styles.text, style]);
  return <Text style={descriptionStyle}>{children}</Text>;
};

const styles = StyleSheet.create({
  text: {
    fontFamily: Config.fontLight,
    ...s`text-base text-text-primary`,
  },
});
