import { s } from 'react-native-wind';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Button,
  ButtonType,
  Description,
  Subtitle,
  UmBottomBar,
} from '../../../components';
import { useLanguage } from '../../../locale';
import { Config } from '../../../config';

const maxPoints = 100;

type UmFeedbackDetailsBottomBarProps = {
  points: number;
  onFinish: () => void;
};

export const UmFeedbackDetailsBottomBar: React.FC<
  UmFeedbackDetailsBottomBarProps
> = ({ onFinish, points }) => {
  const [t] = useLanguage();
  const pointsColor = React.useMemo(() => {
    if (points > maxPoints * 0.85) {
      return 'text-primary';
    } else if (points > maxPoints * 0.25) {
      return 'text-secondary';
    } else if (points <= maxPoints * 0.25) {
      return 'text-secondary';
    } else {
      return '';
    }
  }, [points]);

  return (
    <UmBottomBar>
      <View style={s`flex-1`}>
        <Subtitle>{t('feedback.bottomBar.points')}</Subtitle>
        <Description>
          <Description style={[styles.points, s`${pointsColor}`]}>
            {points}
          </Description>{' '}
          {t('feedback.bottomBar.on')}{' '}
          <Description style={styles.points}>{maxPoints}</Description>{' '}
        </Description>
      </View>
      <Button
        onPress={onFinish}
        buttonType={ButtonType.Primary}
        containerStyle={s`flex-1`}
      >
        {t('feedback.bottomBar.finish')}
      </Button>
    </UmBottomBar>
  );
};

const styles = StyleSheet.create({
  points: {
    fontFamily: Config.fontSemibold,
  },
});
