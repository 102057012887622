import dayjs from 'dayjs';
import * as React from 'react';
import { DateData } from 'react-native-calendars';
import {
  GetFeedbacksRequest,
  GetUserFeedbacksRequest,
} from '../../../api/feedback/feedback.models';
import {
  useGetFeedbacks,
  useGetUserFeedbacks,
} from '../../../hooks/api/feedback.api';

type UseTeamCalendarSectionControllerParams = {
  teamId?: string;
  userId?: string;
  isSummary: boolean;
};

export const useTeamCalendarSectionController = ({
  teamId,
  isSummary,
  userId,
}: UseTeamCalendarSectionControllerParams) => {
  const [month, setMonth] = React.useState<DateData>({
    dateString: dayjs().toISOString(),
    day: dayjs().date(),
    month: dayjs().month(),
    year: dayjs().year(),
    timestamp: dayjs().unix(),
  });

  const getMonthFeedbacksRequest: GetFeedbacksRequest = React.useMemo(() => {
    const monthFeedback = {
      teamId: teamId ?? '',
      userId: userId ?? '',
      from: dayjs(month.dateString).startOf('month').format('YYYY-MM-DD'),
      to: dayjs(month.dateString).endOf('month').format('YYYY-MM-DD'),
    };
    return monthFeedback;
  }, [month.dateString, teamId, userId]);

  const getMonthFeedbacksRequestAll: GetUserFeedbacksRequest =
    React.useMemo(() => {
      const monthFeedback = {
        from: dayjs(month.dateString).startOf('month').format('YYYY-MM-DD'),
        to: dayjs(month.dateString).endOf('month').format('YYYY-MM-DD'),
      };
      return monthFeedback;
    }, [month.dateString]);

  const { data: monthFeedbacks = [] } = useGetFeedbacks(
    getMonthFeedbacksRequest,
    !isSummary
  );

  const { data: feedbacks = [] } = useGetUserFeedbacks(
    getMonthFeedbacksRequestAll,
    isSummary
  );

  return {
    monthFeedbacks: !isSummary ? monthFeedbacks : feedbacks,
    setMonth,
  };
};
