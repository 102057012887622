import { s } from 'react-native-wind';
import React, { useEffect, useMemo } from 'react';
import { EDIT_PROFILE_SCREEN } from '../../navigation/authenticated/authenticated.types';
import { GenericScreenProps } from '../../navigation/core/types';
import { useLanguage } from '../../locale';
import { Button, Layout, Paragraph } from '../../components';
import { useBackNavbarAction } from '../../hooks';
import { View } from 'react-native-animatable';
import { TextField } from '../../components/TextField';
import {
  useGetMyDetails,
  useUpdateProfileMutation,
} from '../../hooks/api/user.api';
import { useEditProfileForm } from './controllers/useEditProfileForm';
import { DatePicker } from '../../portability/components/DatePicker/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { maxBirthDate, minBirthDate } from '../../utils/miscellaneous';
import { TouchableOpacity } from 'react-native';
import * as Haptics from '../../portability/services/Haptics/Haptics';

type EditProfileScreenProps = {} & GenericScreenProps<
  typeof EDIT_PROFILE_SCREEN
>;

export const EditProfileScreen: React.FC<EditProfileScreenProps> = ({
  navigation,
}) => {
  const [t] = useLanguage();
  const backAction = useBackNavbarAction();
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState<boolean>();
  const [standardDate, setStandardDate] = React.useState<Date>();
  const [date, setDate] = React.useState<Dayjs>();

  const { data: myDetails } = useGetMyDetails();
  const { mutateAsync: updateProfileApiCall } = useUpdateProfileMutation();

  const { handleSubmit, onUsernameChange, setValue, usernameValue, errors } =
    useEditProfileForm();

  useEffect(() => {
    if (myDetails?.username) setValue('username', myDetails.username);
    if (myDetails?.birthDate)
      setStandardDate(dayjs(myDetails.birthDate).toDate());
  }, [myDetails?.birthDate, myDetails?.username, setValue]);

  React.useEffect(() => {
    if (standardDate) {
      const dayjsDate = dayjs(standardDate);
      setDate(dayjsDate);
    }
  }, [standardDate]);

  const updateProfile = useMemo(() => {
    return handleSubmit(async (formData) => {
      await updateProfileApiCall({
        birthDate: date?.format('YYYY-MM-DD'),
        username: formData.username,
      });
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
      navigation.goBack();
    });
  }, [date, handleSubmit, navigation, updateProfileApiCall]);

  const openDatePicker = React.useCallback(() => {
    setIsDatePickerOpen(true);
  }, []);

  const closeDatePicker = React.useCallback(() => {
    if (myDetails?.birthDate) {
      setStandardDate(dayjs(myDetails.birthDate).toDate());
    }

    setIsDatePickerOpen(false);
  }, [myDetails?.birthDate]);

  const confirm = React.useCallback((newDate: Date) => {
    setStandardDate(newDate);
    setIsDatePickerOpen(false);
  }, []);

  return (
    <>
      <Layout
        title={t('editProfile.title')}
        leftAction={backAction}
        showSeparator={false}
      >
        <View style={s`flex-1`}>
          <TextField
            containerStyle={s`mt-6`}
            placeholder={t('editProfile.usernamePlaceholder')}
            onChangeText={onUsernameChange}
            value={usernameValue}
            error={errors.username?.message}
          />
          <TouchableOpacity
            style={s`mt-4 bg-card border border-text-secondary rounded-2xl h-12 px-2.5 justify-center`}
            onPress={openDatePicker}
          >
            <Paragraph
              style={s`${date ? 'text-text-primary' : 'text-text-secondary'}`}
            >
              {date
                ? date.format('DD/MM/YYYY')
                : t('onboarding.birthDate.birthDate')}
            </Paragraph>
          </TouchableOpacity>
        </View>
        <Button containerStyle={s`mt-6`} onPress={updateProfile}>
          {t('editProfile.save')}
        </Button>
      </Layout>
      <DatePicker
        modal
        open={isDatePickerOpen}
        date={standardDate ?? maxBirthDate}
        locale="it-IT"
        mode="date"
        maximumDate={maxBirthDate}
        minimumDate={minBirthDate}
        title={t('onboarding.birthDate.birthDate')}
        confirmText={t('onboarding.birthDate.confirm')}
        cancelText={t('onboarding.birthDate.cancel')}
        onConfirm={confirm}
        onCancel={closeDatePicker}
      />
    </>
  );
};
