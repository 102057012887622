import { IHttpClient } from '../../services/HttpClient/IHttpClient';
import {
  CastFeedbackRequest,
  CastFeedbackResponse,
  GetFeedbacksRequest,
  GetFeedbacksResponse,
  GetUserFeedbackResponse,
  GetUserFeedbacksRequest,
} from './feedback.models';
import { IFeedbackApi } from './IFeedbackApi';

export class FeedbackApi implements IFeedbackApi {
  constructor(private readonly httpClient: IHttpClient) {}

  public readonly castFeedback = async (req: CastFeedbackRequest) => {
    const res = await this.httpClient.post<CastFeedbackResponse>(
      '/core/feedback/cast',
      {
        body: req,
        isAuthorized: true,
      }
    );

    return res.item.badgeProgress;
  };

  public readonly getFeedbacks = async ({
    teamId,
    ...params
  }: GetFeedbacksRequest) => {
    const res = await this.httpClient.get<GetFeedbacksResponse>(
      `/core/feedback/team/${teamId}`,
      {
        isAuthorized: true,
        params: {
          ...params,
        },
      }
    );

    return res.items;
  };

  public readonly getUserFeedbacks = async ({
    from,
    to,
  }: GetUserFeedbacksRequest) => {
    const res = await this.httpClient.get<GetUserFeedbackResponse>(
      '/core/feedback/user',
      {
        isAuthorized: true,
        params: {
          from,
          to,
        },
      }
    );

    return res.items;
  };
}
