import { s } from 'react-native-wind';
import * as React from 'react';
import { useLanguage } from '../locale';
import { useBottomSheetActions } from '../hooks/useBottomSheetActions';
import { BottomSheetRef, UmBottomSheet } from './UmBottomSheet';
import { View } from 'react-native';
import { Subtitle } from './Subtitle';
import { Button, ButtonType } from './Button';
import { Edge } from 'react-native-safe-area-context';
import { useGetMyTeams } from '../hooks/api/team.api';
import { TeamStatus } from '../api/team/team.models';
import { SelectableElement } from './SelectebleElement';
import { Paragraph } from './Paragraph';
import { useSelector } from 'react-redux';
import {
  isSummarySelectedSelector,
  selectedTeamSelector,
} from '../redux/general/general.selectors';
import { useDispatch } from '../redux/utils';
import { setIsSummary, setSelectedTeam } from '../redux/general/general.slice';

const safeAreaEdges: Edge[] = ['right', 'left'];

type ChangeTeamBottomSheetProps = {
  showSummary?: boolean;
};

export const ChangeTeamBottomSheet: React.ForwardRefExoticComponent<
  ChangeTeamBottomSheetProps & React.RefAttributes<BottomSheetRef>
> = React.forwardRef(({ showSummary = true }, forwardedRef) => {
  const [t] = useLanguage();
  const dispatch = useDispatch();

  const selectedTeam = useSelector(selectedTeamSelector);
  const isSummarySelected = useSelector(isSummarySelectedSelector);

  const [localSelectedTeam, setLocalSelectedTeam] =
    React.useState(selectedTeam);
  const [localIsSummarySelected, setLocalIsSummarySelected] =
    React.useState(isSummarySelected);

  const { bottomSheetRef, closeBottomSheet } =
    useBottomSheetActions(forwardedRef);

  const { data: teams = [] } = useGetMyTeams(TeamStatus.Active);

  const selectTeamFunctions = React.useMemo(() => {
    const functions: Record<string, () => void> = {};
    teams.forEach((team) => {
      functions[team.id] = () => {
        setLocalSelectedTeam(team);
        setLocalIsSummarySelected(false);
      };
    });
    return functions;
  }, [teams]);

  React.useEffect(() => {
    if (!selectedTeam && teams && teams[0]) {
      dispatch(setSelectedTeam(teams[0]));
    }
  }, [dispatch, selectedTeam, teams]);

  React.useEffect(() => {
    setLocalIsSummarySelected(isSummarySelected);
    setLocalSelectedTeam(selectedTeam);
  }, [isSummarySelected, selectedTeam]);

  const selectSummary = React.useCallback(() => {
    setLocalIsSummarySelected(true);
  }, []);

  const cancel = React.useCallback(() => {
    setLocalIsSummarySelected(isSummarySelected);
    setLocalSelectedTeam(selectedTeam);
    closeBottomSheet();
  }, [closeBottomSheet, isSummarySelected, selectedTeam]);

  const confirm = React.useCallback(() => {
    dispatch(setIsSummary(localIsSummarySelected));
    dispatch(setSelectedTeam(localSelectedTeam));
    closeBottomSheet();
  }, [closeBottomSheet, dispatch, localIsSummarySelected, localSelectedTeam]);

  return (
    <UmBottomSheet
      ref={bottomSheetRef}
      isContentHeight
      safeAreaEdges={safeAreaEdges}
    >
      <Subtitle>{t('changeTeamBottomSheet.title')}</Subtitle>
      <View style={s`my-4`}>
        {teams.map((team, i) => {
          const isFirst = i === 0;
          return (
            <SelectableElement
              key={`change-team-${team.id}`}
              isSelected={
                (!localIsSummarySelected || !showSummary) &&
                team.id === localSelectedTeam?.id
              }
              onPress={selectTeamFunctions[team.id]}
              lable={team.name}
              style={s`${isFirst ? '' : 'mt-3'}`}
            />
          );
        })}
      </View>
      {showSummary && (
        <>
          <Paragraph>{t('changeTeamBottomSheet.others')}</Paragraph>
          <SelectableElement
            onPress={selectSummary}
            isSelected={localIsSummarySelected}
            lable={t('changeTeamBottomSheet.summary')}
            style={s`mt-4`}
          />
        </>
      )}
      <View style={s`flex-row mt-8`}>
        <Button
          buttonType={ButtonType.Tertiary}
          onPress={cancel}
          containerStyle={s`flex-1 mr-4`}
        >
          {t('changeTeamBottomSheet.cancel')}
        </Button>
        <Button
          onPress={confirm}
          buttonType={ButtonType.Primary}
          containerStyle={s`flex-1`}
        >
          {t('changeTeamBottomSheet.confirm')}
        </Button>
      </View>
    </UmBottomSheet>
  );
});
