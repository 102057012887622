import { s } from 'react-native-wind';
import * as React from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Paragraph } from '../../../components';
import { ChevronRightIcon } from 'react-native-heroicons/outline';
import { Config } from '../../../config';

type CardListElementProps = {
  label: string;
  icon: React.ReactNode;
  containerStyle?: StyleProp<ViewStyle>;
  onPress: () => void;
};

export const CardListElement: React.FC<CardListElementProps> = ({
  icon,
  label,
  containerStyle,
  onPress,
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        s`flex-row py-3 border-b border-light-grey items-center`,
        containerStyle,
      ]}
    >
      <View style={s`mr-2`}>{icon}</View>
      <View style={s`flex-1`}>
        <Paragraph numberOfLines={1}>{label}</Paragraph>
      </View>
      <View style={s`ml-2`}>
        <ChevronRightIcon color={Config.colorTextPrimary} size={16} />
      </View>
    </TouchableOpacity>
  );
};
