import { useMemo } from 'react';
import {
  useGetCurrentBadge,
  useGetTotalBadges,
} from '../../../hooks/api/gamification.api';
import { getTreeImageFromCompletedPercentage } from '../utils/getTreeImageFromCompletedPercentage';
import noTree from '../../../../assets/personal_progress_bagnur.png';

export const usePersonalSectionController = () => {
  const { data: currentBadge, isLoading } = useGetCurrentBadge();
  const { data: totalBadges } = useGetTotalBadges();

  const otherBadgesExist = totalBadges && totalBadges.length > 0;

  const currentImage = useMemo(() => {
    if (!currentBadge && isLoading) return null;
    if (!currentBadge) return noTree;
    const { badge, currentPointsAmount, targetPointsAmount } = currentBadge;
    return getTreeImageFromCompletedPercentage(
      badge,
      currentPointsAmount,
      targetPointsAmount
    );
  }, [currentBadge, isLoading]);

  return {
    currentImage,
    targetPoints: currentBadge?.targetPointsAmount,
    currentPoints: currentBadge?.currentPointsAmount,
    otherBadgesExist,
  };
};
