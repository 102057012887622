import * as React from 'react';
import { View } from 'react-native-animatable';
import { s } from 'react-native-wind';
import { TextField } from '../../../components/TextField';
import { PaperAirplaneIcon } from 'react-native-heroicons/solid';
import { TouchableOpacity } from 'react-native';
import { useLanguage } from '../../../locale';

type HarmoniaBottomBarProps = {
  text: string;
  isSendDisabled: boolean;
  setText: (t: string) => void;
  sendMessage: () => void;
};

const HarmoniaBottomBar: React.FC<HarmoniaBottomBarProps> = ({
  setText,
  isSendDisabled,
  text,
  sendMessage,
}) => {
  const [t] = useLanguage();

  return (
    <View
      style={s`min-h-12 w-full border-t border-t-light-grey flex-row px-page-padding items-end py-2`}
    >
      <TextField
        placeholder={t('harmonia.inputPlaceholder')}
        containerStyle={s`flex-1`}
        autoExpand
        maxNumberOfLines={4}
        value={text}
        onChangeText={setText}
      />
      <TouchableOpacity
        disabled={isSendDisabled}
        onPress={isSendDisabled ? undefined : sendMessage}
        style={[
          s`ml-2 p-1.5 justify-center items-center rounded-full bg-primary`,
          { width: 27, height: 27 },
        ]}
      >
        <PaperAirplaneIcon
          color={'#fff'}
          opacity={isSendDisabled ? 0.4 : 1}
          size={14}
        />
      </TouchableOpacity>
    </View>
  );
};

export default HarmoniaBottomBar;
