import * as React from 'react';
import { View } from 'react-native-animatable';
import { s } from 'react-native-wind';
import { Description, Separator, Subtitle } from '../../../components';
import { useLanguage } from '../../../locale';
import { getDayType } from '../../../utils/feedbacks';
import DaysInRowDots from './DaysInRowDots';
import { useDaysInRowSectionController } from '../controllers/useDaysInRowSectionController';
import { Feedback } from '../../../api/feedback/feedback.models';

type DaysInRowSectionProps = {
  feedbacks: Feedback[] | undefined;
};

const DaysInRowSection: React.FC<DaysInRowSectionProps> = ({
  feedbacks = [],
}) => {
  const [t] = useLanguage();
  const { daysList, weeklyStreak } = useDaysInRowSectionController(feedbacks);

  return (
    <>
      <Subtitle style={s`mb-4`}>{t('stats.daysInRow')}</Subtitle>
      <View style={s`flex-row`}>
        {daysList.map((d, i) => {
          const type = getDayType(feedbacks, d);
          return (
            <React.Fragment key={`daysInRow-${i}`}>
              {i !== 0 ? (
                <View style={s`flex-1 h-0.25 bg-text-secondary mt-4`} />
              ) : null}
              <View style={s`items-center`}>
                <DaysInRowDots type={type} />
                <Description style={s`text-sm mt-2`}>
                  {d.format('ddd').toUpperCase()}
                </Description>
              </View>
            </React.Fragment>
          );
        })}
      </View>
      <View style={s`flex-row items-center mt-4`}>
        <Description>{t('stats.streak')}</Description>
        <Subtitle style={s`text`}>{`${weeklyStreak}`}</Subtitle>
      </View>
      <Separator style={s`my-6`} />
    </>
  );
};

export default DaysInRowSection;
