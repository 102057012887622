import { LinkingOptions } from '@react-navigation/native';
import { AUTHENTICATED_STACK_NAME } from '../authenticated/authenticated.stack';
import { AUTHENTICATION_STACK_NAME } from '../authentication/authentication.stack';

import {
  AUTHENTICATION_SCREEN,
  AUTH_CODE_SCREEN,
  INTRO_SCREEN,
} from '../authentication/authentication.types';

import {
  ALL_BADGES_SCREEN,
  SLACK_INTEGRATION_SCREEN,
  ALL_CHALLENGES_SCREEN,
  AUTHENTICATED_BOTTOM_TAB_NAME,
  EDIT_PROFILE_SCREEN,
  TEAM_MEMBERS_SCREEN,
  LEADERBOARD_SCREEN,
  USER_NOTIFICATIONS_SCREEN,
  FEEDBACK_DETAILS_SCREEN,
  HARMONIA_ASSISTANT_SCREEN,
} from '../authenticated/authenticated.types';
import {
  PROFILE_SCREEN,
  FEEDBACKS_SCREEN,
  GAME_SCREEN,
  STATS_SCREEN,
  TEAM_SETTINGS_SCREEN,
} from '../authenticatedWithBottomTab/authenticated.types';

const LinkingConfig: LinkingOptions<any> = {
  prefixes: ['https://ummy.app', 'ummy://'],

  config: {
    screens: {
      [AUTHENTICATED_STACK_NAME]: {
        screens: {
          [SLACK_INTEGRATION_SCREEN]: 'slack',
          [ALL_BADGES_SCREEN]: 'allBadges',
          [AUTHENTICATED_BOTTOM_TAB_NAME]: {
            path: 'home',
            screens: {
              [PROFILE_SCREEN]: 'profile',
              [FEEDBACKS_SCREEN]: 'feedbacks',
              [GAME_SCREEN]: 'game',
              [STATS_SCREEN]: 'stats',
              [TEAM_SETTINGS_SCREEN]: 'teamSettings',
            },
          },
          [USER_NOTIFICATIONS_SCREEN]: 'userNotifications',
          [TEAM_MEMBERS_SCREEN]: 'teamMembers',
          [HARMONIA_ASSISTANT_SCREEN]: 'harmoniaHistory',
          [FEEDBACK_DETAILS_SCREEN]: 'feedbackDetails',
          [LEADERBOARD_SCREEN]: 'leaderboard',
          [ALL_CHALLENGES_SCREEN]: 'allChallenges',
          [EDIT_PROFILE_SCREEN]: 'editProfile',
        },
      },
      [AUTHENTICATION_STACK_NAME]: {
        screens: {
          [AUTHENTICATION_SCREEN]: 'authentication',
          [INTRO_SCREEN]: 'intro',
          [AUTH_CODE_SCREEN]: 'authCode',
        },
      },
    },
  },
};

export default LinkingConfig;
