import dayjs, { Dayjs } from 'dayjs';
import { Feedback, FeedbackValue } from '../api/feedback/feedback.models';
import { Config } from '../config';
import { DotsType } from '../screens/stats/components/DaysInRowDots';
import {
  LineChartData,
  LineChartDataExtended,
} from '../screens/stats/stats.models';

export function getDayRangeList(dayBeforeToStart: number) {
  const lastDaysList: Dayjs[] = [];
  for (let i = dayBeforeToStart - 1; i >= 0; i--) {
    lastDaysList.push(dayjs().subtract(i, 'days'));
  }
  return lastDaysList;
}

export function getDayType(feedbacks: Feedback[], date: Dayjs) {
  const dailyFeedbacks = feedbacks.filter((f) =>
    dayjs(f.createdAt).isSame(date, 'day')
  );

  let isEmptyVoteExist = false;
  let isFilledVoteExist = false;

  dailyFeedbacks.forEach((df) => {
    if (!isFilledVoteExist) {
      isFilledVoteExist = df.value !== null;
    }
    if (!isEmptyVoteExist) {
      isEmptyVoteExist = df.value === null;
    }
  });

  if (isEmptyVoteExist && isFilledVoteExist) {
    return DotsType.Partial;
  } else if (isEmptyVoteExist && !isFilledVoteExist) {
    return DotsType.Missing;
  } else if (!isEmptyVoteExist && isFilledVoteExist) {
    return DotsType.Complete;
  } else {
    return DotsType.Unnecessary;
  }
}

export function getFeedbackColor(value: number) {
  switch (value) {
    case 1:
      return Config.colorDanger;
    case 2:
      return Config.colorSad;
    case 3:
      return Config.colorHappy;
    case 4:
      return Config.colorPrimary;
    default:
      return Config.colorPrimary;
  }
}

export function getGoodAndBadCategories(feedbacks: Feedback[]) {
  const voteOnCategories: Record<string, FeedbackValue[]> = {};
  feedbacks
    .filter((f) => !!f.value && !!f.categoryFeedback)
    .forEach((f) => {
      Object.keys(f.categoryFeedback).forEach((k) => {
        const voteList = voteOnCategories[k];
        const voteToAdd = f.categoryFeedback[k];
        if (!voteToAdd) return;

        if (!voteList) {
          voteOnCategories[k] = [voteToAdd];
        } else {
          voteList.push(voteToAdd);
        }
      });
    });

  const averageByCategory = Object.keys(voteOnCategories).reduce(
    (finalObj, categoryKey) => {
      const categoryVotes = voteOnCategories[categoryKey];
      const valuesSum = categoryVotes.reduce((sum, vote) => {
        if (!vote) return sum;
        return sum + vote;
      }, 0);

      return {
        ...finalObj,
        [categoryKey]: valuesSum / categoryVotes.length,
      };
    },
    {} as Record<string, number>
  );

  const goodCategories: string[] = [];
  const badCategories: string[] = [];

  Object.keys(averageByCategory).forEach((categoryId) => {
    if (averageByCategory[categoryId] >= 2.5) {
      goodCategories.push(categoryId);
    } else {
      badCategories.push(categoryId);
    }
  });

  return {
    goodCategories,
    badCategories,
  };
}

export function normalizeChartFeedbacks(
  feedbacksList: LineChartData[] | undefined
) {
  if (!feedbacksList) return [];

  const normalizedList: LineChartDataExtended[] = [];

  feedbacksList.forEach((f, i, a) => {
    if (f.value !== null) {
      return normalizedList.push({
        label: f.label,
        wasNull: false,
        value: f.value,
      });
    }

    let leftNumber: number | null = null;
    let rightNumber: number | null = null;
    let rightNullNumbers = 0;

    for (let k = i + 1; k < a.length && rightNumber === null; k++) {
      if (a[k].value) {
        rightNumber = a[k].value;
      } else {
        rightNullNumbers++;
      }
    }

    for (let k = i - 1; k >= 0 && leftNumber === null; k--) {
      if (normalizedList[k].value !== null) {
        leftNumber = normalizedList[k].value;
      }
    }

    if (leftNumber === null && rightNumber === null) {
      normalizedList.push({ label: f.label, value: 0, wasNull: true });
      return;
    }

    if (rightNumber === null && leftNumber !== null) {
      normalizedList.push({ label: f.label, value: leftNumber, wasNull: true });
      return;
    }

    if (rightNumber !== null && leftNumber === null) {
      normalizedList.push({
        label: f.label,
        value: rightNumber,
        wasNull: true,
      });
      return;
    }

    const numberToAdd = (rightNumber! - leftNumber!) / (rightNullNumbers + 2);

    normalizedList.push({
      label: f.label,
      value: leftNumber! + numberToAdd,
      wasNull: true,
    });
  });

  return normalizedList;
}
