import { useForm, useController } from 'react-hook-form';
import { useLanguage } from '../../../locale';
import { emailRegexp } from '../../../utils/regexp';

export type SignInFormModel = {
  email: string;
};

export const useAuthenticationForm = () => {
  const [t] = useLanguage();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormModel>();

  const {
    field: { onChange: onEmailChange, value: emailValue },
  } = useController<SignInFormModel, 'email'>({
    name: 'email',
    control,
    rules: {
      required: {
        value: true,
        message: t('authentication.emailError'),
      },
      pattern: {
        value: emailRegexp,
        message: t('authentication.emailError'),
      },
    },
  });

  return {
    onEmailChange,
    emailValue,
    handleSubmit,
    errors,
  };
};
