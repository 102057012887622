import { Platform } from 'react-native';

const ENV = {
  develop: {
    baseApiUrl: 'https://vbph0ie88k.execute-api.eu-central-1.amazonaws.com/dev',
    harmoniaWsUrl:
      'wss://rgc6drwt38.execute-api.eu-central-1.amazonaws.com/dev',
    profileImageBaseUrl:
      'https://d1necp896a690u.cloudfront.net/dev/profileimage',
    bucketBaseUrl: 'https://d1necp896a690u.cloudfront.net',
    cognitoRegion: 'eu-central-1',
    userPoolId: 'eu-central-1_5At7WyRy9',
    userPoolWebClientId: '5n5ter5i125r5ah8mhdbl0tegc',
    slackUrl:
      'https://slack.com/oauth/v2/authorize?client_id=5071318334182.6101769233012&scope=channels:history,channels:read,chat:write,groups:history,groups:read,im:history,im:read,im:write,mpim:history,mpim:read,team:read,users:read,users:read.email&user_scope=chat:write,users.profile:write',
    androidStoreUrl:
      'https://play.google.com/store/apps/details?id=com.ummymood',
    iosStoreUrl:
      'https://apps.apple.com/it/app/ummy-buon-umore-a-lavoro/id1628011047',
  },
  staging: {
    baseApiUrl: 'https://vbph0ie88k.execute-api.eu-central-1.amazonaws.com/dev',
    harmoniaWsUrl:
      'wss://rgc6drwt38.execute-api.eu-central-1.amazonaws.com/dev',
    profileImageBaseUrl:
      'https://d1necp896a690u.cloudfront.net/dev/profileimage',
    bucketBaseUrl: 'https://d1necp896a690u.cloudfront.net',
    cognitoRegion: 'eu-central-1',
    userPoolId: 'eu-central-1_5At7WyRy9',
    userPoolWebClientId: '5n5ter5i125r5ah8mhdbl0tegc',
    slackUrl:
      'https://slack.com/oauth/v2/authorize?client_id=5071318334182.6101769233012&scope=channels:history,channels:read,chat:write,groups:history,groups:read,im:history,im:read,im:write,mpim:history,mpim:read,team:read,users:read,users:read.email&user_scope=chat:write,users.profile:write',
    androidStoreUrl:
      'https://play.google.com/store/apps/details?id=com.ummymood',
    iosStoreUrl:
      'https://apps.apple.com/it/app/ummy-buon-umore-a-lavoro/id1628011047',
  },
  production: {
    baseApiUrl: 'https://camim98ggk.execute-api.eu-west-1.amazonaws.com/prod',
    harmoniaWsUrl: 'wss://mwqyh85dt6.execute-api.eu-west-1.amazonaws.com/prod',
    profileImageBaseUrl:
      'https://d1necp896a690u.cloudfront.net/prod/profileimage',
    bucketBaseUrl: 'https://d1necp896a690u.cloudfront.net',
    cognitoRegion: 'eu-west-1',
    userPoolId: 'eu-west-1_njPd7Sz9N',
    userPoolWebClientId: '39pqdorhjdhaiftpvhrbuhjikh',
    slackUrl:
      'https://slack.com/oauth/v2/authorize?client_id=5071318334182.5223413417008&scope=channels:history,channels:read,chat:write,commands,im:history,im:write,team:read,users:read,users:read.email,groups:history,groups:read,im:read,mpim:history,mpim:read&user_scope=users.profile:write',
    androidStoreUrl:
      'https://play.google.com/store/apps/details?id=com.ummymood',
    iosStoreUrl:
      'https://apps.apple.com/it/app/ummy-buon-umore-a-lavoro/id1628011047',
  },
};

const getWebEnvVars = () => {
  return __DEV__ ? ENV.develop : ENV.production;
};

const getEnvVars = () => {
  return __DEV__ ? ENV.develop : ENV.production;
};

const Env = Platform.OS === 'web' ? getWebEnvVars() : getEnvVars();

export const Config = {
  // Colors
  colorPrimary: '#6AC982',
  colorPrimaryLight: '#E0EFE6',
  colorSecondary: '#EFC319',
  colorSecondaryLight: '#FDF9E8',
  colorCard: '#F4F5F7',
  colorDanger: '#D14941',
  colorSad: '#F19E70',
  colorWarning: '#F7CE46',
  colorHappy: '#CFDC8D',
  colorTextPrimary: '#313131',
  colorTextSecondary: '#A3A3A3',

  // Fonts
  fontLight: 'Rubik_300Light',
  fontRegular: 'Rubik_400Regular',
  fontMedium: 'Rubik_500Medium',
  fontSemibold: 'Rubik_600SemiBold',

  // Styles
  actionSize: 24,
  pagePadding: 27,

  // Envs
  baseUrl: Env.baseApiUrl,
  harmoniaWsUrl: Env.harmoniaWsUrl,
  profileImageBaseUrl: Env.profileImageBaseUrl,
  bucketBaseUrl: Env.bucketBaseUrl,
  congnitoRegion: Env.cognitoRegion,
  userPoolId: Env.userPoolId,
  userPoolWebClientId: Env.userPoolWebClientId,
  slackUrl: Env.slackUrl,
  androidStoreUrl: Env.androidStoreUrl,
  iosStoreUrl: Env.iosStoreUrl,
};
