import { useCallback, useState } from 'react';

export const useNumPad = () => {
  const [code, setCode] = useState('');

  const addDigit = useCallback((num: string) => {
    setCode((oldCode) => {
      if (oldCode.length < 6) {
        return oldCode.concat(num);
      } else {
        return oldCode;
      }
    });
  }, []);

  const add0 = useCallback(() => {
    addDigit('0');
  }, [addDigit]);
  const add1 = useCallback(() => {
    addDigit('1');
  }, [addDigit]);
  const add2 = useCallback(() => {
    addDigit('2');
  }, [addDigit]);
  const add3 = useCallback(() => {
    addDigit('3');
  }, [addDigit]);
  const add4 = useCallback(() => {
    addDigit('4');
  }, [addDigit]);
  const add5 = useCallback(() => {
    addDigit('5');
  }, [addDigit]);
  const add6 = useCallback(() => {
    addDigit('6');
  }, [addDigit]);
  const add7 = useCallback(() => {
    addDigit('7');
  }, [addDigit]);
  const add8 = useCallback(() => {
    addDigit('8');
  }, [addDigit]);
  const add9 = useCallback(() => {
    addDigit('9');
  }, [addDigit]);

  const deleteDigit = useCallback(() => {
    if (code.length > 0) {
      const newCode = code.slice(0, -1);
      setCode(newCode);
    }
  }, [code]);

  return {
    add0,
    add1,
    add2,
    add3,
    add4,
    add5,
    add6,
    add7,
    add8,
    add9,
    deleteDigit,
    code,
    setCode,
  };
};
