import dayjs from 'dayjs';
import * as React from 'react';
import { View } from 'react-native';
import { Edge } from 'react-native-safe-area-context';
import { s } from 'react-native-wind';
import { FeedbackValue } from '../../api/feedback/feedback.models';
import { Team, TeamStatus } from '../../api/team/team.models';
import { HomeScreenHeader, Layout, Subtitle } from '../../components';
import {
  useCastFeedback,
  useGetUserFeedbacks,
} from '../../hooks/api/feedback.api';
import { useGetMyTeams } from '../../hooks/api/team.api';
import { useGetMyDetails } from '../../hooks/api/user.api';
import { useLanguage } from '../../locale';
import { FEEDBACK_DETAILS_SCREEN } from '../../navigation/authenticated/authenticated.types';
import { FEEDBACKS_SCREEN } from '../../navigation/authenticatedWithBottomTab/authenticated.types';
import { GenericScreenProps } from '../../navigation/core/types';
import { NoData } from '../stats/components/NoData';
import { TeamCard } from './components/TeamCard';
import * as Haptics from '../../portability/services/Haptics/Haptics';
import { AlreadyVotedTeamCard } from './components/AlreadyVotedTeamCard';

const feedbacksSafeAreaEdges: Edge[] = ['left', 'right', 'top'];

type FeedbacksScreensProps = {} & GenericScreenProps<typeof FEEDBACKS_SCREEN>;

const FeedbacksScreens: React.FC<FeedbacksScreensProps> = ({ navigation }) => {
  const [t] = useLanguage();

  const { mutateAsync: castFeedback } = useCastFeedback();
  const { data: myDetails } = useGetMyDetails();
  const { data: teams = [] } = useGetMyTeams(TeamStatus.Active);
  const { data: feedbacks } = useGetUserFeedbacks({
    from: dayjs().format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
  });

  const teamToShowNoVote = React.useMemo(() => {
    const noVoteTeams = teams.filter((t) => {
      const teamFeedback = feedbacks?.find((f) => f.teamId === t.id);
      return !teamFeedback;
    });
    return noVoteTeams.sort();
  }, [feedbacks, teams]);

  const teamWithVotes = React.useMemo(() => {
    const votedTeams = teams.filter((t) => {
      const teamFeedback = feedbacks?.find((f) => f.teamId === t.id);
      return teamFeedback;
    });

    return votedTeams;
  }, [feedbacks, teams]);

  const noDataDescription = React.useMemo(
    () => [t('feedback.notEnoughtData')],
    [t]
  );

  const onCastFeedback = React.useCallback(
    async (feedbackValue: FeedbackValue, teamId: string) => {
      if (!feedbackValue || !myDetails) return;

      try {
        await castFeedback({
          teamId,
          value: feedbackValue,
          voteAnonymous: myDetails.voteAnonymous,
        });
        navigation.navigate(FEEDBACK_DETAILS_SCREEN, {
          teamId,
          userId: myDetails.id,
        });
        Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
      } catch (e) {}
    },
    [castFeedback, myDetails, navigation]
  );

  const navigateToRightTeam = React.useCallback(
    (t: Team) => {
      return () => {
        if (!myDetails) return;
        navigation.navigate(FEEDBACK_DETAILS_SCREEN, {
          teamId: t.id,
          userId: myDetails.id,
        });
      };
    },
    [myDetails, navigation]
  );

  return (
    <Layout
      safeAreaEdges={feedbacksSafeAreaEdges}
      showNavbar={false}
      customNavbar={<HomeScreenHeader title={t('feedback.title')} />}
    >
      {teamToShowNoVote.length > 0 ? (
        <View>
          {teamToShowNoVote?.map((t, i) => {
            const isFirst = i === 0;
            const teamFeedback = feedbacks?.find((f) => f.teamId === t.id);
            return (
              <TeamCard
                onFacePress={onCastFeedback}
                containerStyle={s`${!isFirst ? 'mt-4' : ''}`}
                selectedVote={teamFeedback?.value}
                key={t.id}
                team={t}
              />
            );
          })}
        </View>
      ) : null}
      {teamWithVotes.length > 0 ? (
        <View style={s`${teamToShowNoVote.length > 0 ? 'mt-6' : ''}`}>
          <Subtitle>{t('feedback.alreadyVoted')}</Subtitle>
          <View style={s`mt-4`}>
            {teamWithVotes.map((t, i) => {
              const isFirst = i === 0;
              const teamFeedback = feedbacks?.find((f) => f.teamId === t.id);
              const navigateToAlreadyVotedTeam = navigateToRightTeam(t);

              return teamFeedback ? (
                <AlreadyVotedTeamCard
                  onCardPress={navigateToAlreadyVotedTeam}
                  key={t.id}
                  containerStyle={s`${!isFirst ? 'mt-4' : ''}`}
                  team={t}
                  feedback={teamFeedback}
                />
              ) : (
                <React.Fragment key={t.id}></React.Fragment>
              );
            })}
          </View>
        </View>
      ) : null}
      {teams.length === 0 ? <NoData descriptions={noDataDescription} /> : null}
    </Layout>
  );
};

export default FeedbacksScreens;
