import React, { ReactNode, useMemo } from 'react';
import { s } from 'react-native-wind';
import { Edge, SafeAreaView } from 'react-native-safe-area-context';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import { ScreenNavbar, NavbarAction } from './ScreenNavbar';
import { Title } from './Title';
import { Description } from './Description';
import { Separator } from './Separator';

type LayoutProps = {
  title?: string;
  description?: ReactNode;
  showNavbar?: boolean;
  leftAction?: NavbarAction;
  rightAction?: NavbarAction;
  showSeparator?: boolean;
  isScrollView?: boolean;
  safeAreaEdges?: Edge[];
  hasNestedScrollView?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  customNavbar?: ReactNode;
  bottomBar?: ReactNode;
  children: ReactNode;
  backgroundColor?: ViewStyle['backgroundColor'];
};

export const Layout: React.FC<LayoutProps> = ({
  children,
  title,
  description,
  leftAction,
  rightAction,
  customNavbar,
  bottomBar,
  showNavbar = true,
  showSeparator = true,
  isScrollView = true,
  safeAreaEdges = ['bottom', 'left', 'right', 'top'],
  hasNestedScrollView = false,
  containerStyle,
  backgroundColor,
}) => {
  const layoutBody = useMemo(() => {
    return (
      <>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description style={s`${title ? 'mt-4' : ''}`}>
            {description}
          </Description>
        ) : null}
        {showSeparator && (title || description) ? (
          <View style={s`mt-6`}>
            <Separator />
          </View>
        ) : null}
        {children}
      </>
    );
  }, [children, description, showSeparator, title]);

  return (
    <SafeAreaView
      style={[s`flex-1`, { backgroundColor }]}
      edges={safeAreaEdges}
    >
      {showNavbar ? (
        <ScreenNavbar rightAction={rightAction} leftAction={leftAction} />
      ) : null}
      {customNavbar ? customNavbar : null}
      <KeyboardAvoidingView
        style={s`flex-1 justify-center`}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        {isScrollView ? (
          <ScrollView
            nestedScrollEnabled={hasNestedScrollView}
            contentContainerStyle={[
              s`flex-grow px-page-padding py-6`,
              containerStyle,
            ]}
            keyboardShouldPersistTaps={'handled'}
          >
            {layoutBody}
          </ScrollView>
        ) : null}
        {!isScrollView ? (
          <View
            style={[
              s`${
                Platform.OS === 'web' ? 'flex-1' : 'flex-grow'
              } px-page-padding py-6`,
              containerStyle,
            ]}
          >
            {layoutBody}
          </View>
        ) : null}
        {bottomBar}
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
