import * as React from 'react';
import { Layout, NavbarAction, Separator, Subtitle } from '../../components';
import UmSwitch, { UmSwitchOption } from '../../components/UmSwitch';
import { useLanguage } from '../../locale';
import { s } from 'react-native-wind';
import PersonalGamificationSection from './components/PersonalGamificationSection';
import { GenericScreenProps } from '../../navigation/core/types';
import { GAME_SCREEN } from '../../navigation/authenticatedWithBottomTab/authenticated.types';
import { LEADERBOARD_SCREEN } from '../../navigation/authenticated/authenticated.types';
import { TrophyFilledIcon } from '../../icons';
import CompanyGamificationSection from './components/CompanyGamificationSection';
import useFeatureFlags from '../../hooks/useFeatureFlags';

enum GameView {
  Personal = 'personal',
  Company = 'company',
}

type GamificationScreenProps = {} & GenericScreenProps<typeof GAME_SCREEN>;

const GamificationScreen: React.FC<GamificationScreenProps> = ({
  navigation,
}) => {
  const [t] = useLanguage();
  const [FeatureFlags] = useFeatureFlags();

  const [selectedOption, setSelectedOption] = React.useState(GameView.Personal);

  const rightNavAction: NavbarAction = React.useMemo(() => {
    return {
      action: () => {
        navigation.navigate(LEADERBOARD_SCREEN);
      },
      icon: <TrophyFilledIcon />,
    };
  }, [navigation]);

  const switchOptions: UmSwitchOption[] = React.useMemo(() => {
    return [
      {
        key: GameView.Personal,
        label: t('gamification.personal'),
      },
      {
        key: GameView.Company,
        label: t('gamification.company'),
      },
    ];
  }, [t]);

  const onOptionChange = React.useCallback((key: string) => {
    setSelectedOption(key as GameView);
  }, []);

  return (
    <Layout
      isScrollView={false}
      safeAreaEdges={['top', 'left', 'right']}
      rightAction={
        FeatureFlags.GamificationLeaderboard ? rightNavAction : undefined
      }
    >
      {FeatureFlags.GamificationChallenges && (
        <>
          <Subtitle>{t('gamification.section')}:</Subtitle>
          <UmSwitch
            onOptionPress={onOptionChange}
            selectedOption={selectedOption}
            options={switchOptions}
            containerStyle={s`mt-2`}
          />
          <Separator style={s`my-4`} />
        </>
      )}
      {selectedOption === GameView.Personal ? (
        <PersonalGamificationSection />
      ) : (
        <CompanyGamificationSection />
      )}
    </Layout>
  );
};

export default GamificationScreen;
