import { getFirestore, getDoc, doc } from 'firebase/firestore';
import {
  CommonRemoteConfig,
  RemoteConfig,
  PlatformRemoteConfig,
} from './utils';

enum RemoteConfigEnv {
  Production = 'prod_confifurations',
  Development = 'dev_configurations',
}

export const getConfigurations = async () => {
  const key = __DEV__
    ? RemoteConfigEnv.Development
    : RemoteConfigEnv.Production;

  const data = doc(getFirestore(), key, 'web');
  const snap = await getDoc(data);

  const config = snap.data() as PlatformRemoteConfig;

  const dataCom = doc(getFirestore(), key, '_common');
  const snapCom = await getDoc(dataCom);

  const configCom = snapCom.data() as CommonRemoteConfig;

  return { ...config, ...configCom } as RemoteConfig;
};
