import { s } from 'react-native-wind';
import * as React from 'react';
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { FeedbackValue } from '../api/feedback/feedback.models';
import { Description } from './Description';

type UmFeedbackCategoryProps = {
  label?: string;
  icon: React.ReactNode;
  value: FeedbackValue;
  isSelected: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  iconContainerStyle?: StyleProp<ViewStyle>;
  onPress?: () => void;
  isDisabled?: boolean;
};

export const UmFeedbackCategory: React.FC<UmFeedbackCategoryProps> = ({
  icon,
  isSelected,
  label,
  value,
  containerStyle,
  iconContainerStyle,
  onPress,
  isDisabled,
}) => {
  const selectionColorClass = React.useMemo(() => {
    switch (value) {
      case 1:
        return 'very-sad';
      case 2:
        return 'sad';
      case 3:
        return 'happy';
      case 4:
        return 'primary';
      default:
        return 'primary';
    }
  }, [value]);

  const selectedClasses = isSelected
    ? `bg-${selectionColorClass} border-${selectionColorClass}`
    : 'bg-card border-text-secondary';

  return (
    <View
      style={[
        s`items-center`,
        containerStyle,
        isDisabled ? styles.disable : undefined,
      ]}
    >
      <TouchableOpacity
        disabled={!onPress}
        onPress={onPress}
        style={[
          s`w-14 h-14 rounded-full justify-center items-center border ${selectedClasses}`,
          iconContainerStyle,
        ]}
      >
        {icon}
      </TouchableOpacity>
      {label ? (
        <Description style={s`text-xs mt-1 text-center`}>{label}</Description>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  disable: {
    opacity: 0.3,
  },
});
