import { CallbackTranslation } from '../locale/localizationContext';

export function getEngagementTranslation(
  t: CallbackTranslation,
  totalVotes: number,
  mandatoryVotes: number
) {
  const votePercentage = (totalVotes / mandatoryVotes) * 100;

  if (votePercentage < 20)
    return t('stats.feedbackCountDescription2.lowerThanTwentyPercent');
  if (votePercentage < 40)
    return t('stats.feedbackCountDescription2.fromTwentyToForty');
  if (votePercentage < 60)
    return t('stats.feedbackCountDescription2.fromFortyToSixty');
  if (votePercentage < 80)
    return t('stats.feedbackCountDescription2.fromSixtyToEighty');
  if (votePercentage < 100)
    return t('stats.feedbackCountDescription2.fromEightyToHundred');

  return t('stats.feedbackCountDescription2.hundred');
}
