import dayjs from 'dayjs';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { TeamStatus, VotingDay } from '../../../api/team/team.models';
import { BottomSheetRef } from '../../../components';
import {
  useGetMyTeams,
  useGetTeamDetails,
  useUpdateTeam,
} from '../../../hooks/api/team.api';
import { useGetMyDetails } from '../../../hooks/api/user.api';
import { selectedTeamSelector } from '../../../redux/general/general.selectors';
import { updateVotingDaysWithNewValue } from '../../../utils/miscellaneous';

export const useTeamSettingsController = () => {
  const changeTeamBottomSheetRef = React.useRef<BottomSheetRef>(null);
  const [isTimePickerOpen, setIsTimePickerOpen] =
    React.useState<boolean>(false);

  const selectedTeam = useSelector(selectedTeamSelector);

  const { mutateAsync: updateTeam } = useUpdateTeam();
  const { data: myDetails } = useGetMyDetails();
  const { data: teamDetails } = useGetTeamDetails(selectedTeam?.id);
  const { data: myTeams = [] } = useGetMyTeams(TeamStatus.Active);

  const isTeamAdmin = React.useMemo(() => {
    if (!teamDetails || !myDetails) return undefined;
    return teamDetails?.admins.includes(myDetails?.id);
  }, [myDetails, teamDetails]);

  const timePickerStartingDate = React.useMemo(() => {
    if (!teamDetails) return;
    const [hours, minutes] = teamDetails.notificationTime.split(':');
    return dayjs().set('minute', +minutes).set('hour', +hours).toDate();
  }, [teamDetails]);

  const openChangeTeamBottomSheet = React.useCallback(() => {
    changeTeamBottomSheetRef.current?.expand();
  }, []);

  const openTimePicker = React.useCallback(() => {
    setIsTimePickerOpen(true);
  }, []);

  const closeTimePicker = React.useCallback(() => {
    setIsTimePickerOpen(false);
  }, []);

  const onVotingDaysUpdate = React.useCallback(
    (votingDay: VotingDay) => {
      if (!teamDetails) return;
      const daysToUpdate = updateVotingDaysWithNewValue(
        votingDay,
        teamDetails.votingDays
      );
      updateTeam({ votingDays: daysToUpdate, teamId: teamDetails.id });
    },
    [teamDetails, updateTeam]
  );

  const confirmNotificationTime = React.useCallback(
    async (newTime: string) => {
      if (!teamDetails) return;
      closeTimePicker();
      await updateTeam({ teamId: teamDetails?.id, notificationTime: newTime });
    },
    [closeTimePicker, teamDetails, updateTeam]
  );

  return {
    changeTeamBottomSheetRef,
    isTimePickerOpen,
    timePickerStartingDate,
    openChangeTeamBottomSheet,
    confirmNotificationTime,
    onVotingDaysUpdate,
    openTimePicker,
    teamDetails,
    closeTimePicker,
    isTeamAdmin,
    selectedTeam,
    myTeams,
    myDetails,
  };
};
