import React, { useEffect, useMemo, useRef } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
  AuthenticationStack,
  AUTHENTICATION_STACK_NAME,
} from './authentication/authentication.stack';
import { useSelector } from '../redux/utils';
import {
  isAuthorizedSelector,
  isOnboardingFinishedSelector,
} from '../redux/general/general.selectors';
import {
  AuthenticatedStack,
  AUTHENTICATED_STACK_NAME,
} from './authenticated/authenticated.stack';
import {
  OnboardingStack,
  ONBOARDING_STACK_NAME,
} from './onboarding/onboarding.stack';
import { Linking } from 'react-native';
import { useStorageProvider } from '../context/StorageProviderContext/useStorageProvider';
import { StorageKeys } from '../services/StorageProvider/IStorageProvider';
import * as Analytics from '../portability/services/Firebase/Analytics/Analytics';
import * as Crashlytics from '../portability/services/Firebase/Crashlytics/Crashlytics';
import useUserData from '../hooks/useUserData';

export const MainStack = () => {
  const StackNav = useRef(createNativeStackNavigator()).current;
  const isAuthorized = useSelector(isAuthorizedSelector);
  const isOnboardingFinished = useSelector(isOnboardingFinishedSelector);
  const storage = useStorageProvider();
  const { user, isReady } = useUserData(isAuthorized);
  useEffect(() => {
    Linking.getInitialURL().then((a: string | null) => {
      if (a?.includes('/slack') && (!isAuthorized || !isOnboardingFinished)) {
        storage.setItem(StorageKeys.SHOULD_REDIRECT_TO_SLACK, 'true');
      }
    });
  }, [storage, isAuthorized, isOnboardingFinished]);

  const screensToShow = useMemo(() => {
    if (!isAuthorized) {
      return (
        <StackNav.Screen
          name={AUTHENTICATION_STACK_NAME}
          component={AuthenticationStack}
        />
      );
    }

    if (!isOnboardingFinished) {
      return (
        <StackNav.Screen
          name={ONBOARDING_STACK_NAME}
          component={OnboardingStack}
        />
      );
    }

    return (
      <StackNav.Screen
        name={AUTHENTICATED_STACK_NAME}
        component={AuthenticatedStack}
      />
    );
  }, [StackNav, isAuthorized, isOnboardingFinished]);

  useEffect(() => {
    if (isAuthorized && isReady && user) {
      Analytics.setUserId(user.id);
      Crashlytics.setUserId(user.id);
    }
  }, [isAuthorized, isReady, user]);

  const navigatiorOptions = useMemo(() => {
    return { headerShown: false };
  }, []);

  return (
    <StackNav.Navigator screenOptions={navigatiorOptions}>
      {screensToShow}
    </StackNav.Navigator>
  );
};
