import React from 'react';
import type { PieChartProps, ChartProps } from 'react-native-svg-charts';
import { ResponsivePie } from '@nivo/pie';
import { Config } from '../../../config';

export type { PieChartProps, ChartProps };

export const PieChart = ({
  data,
}: ChartProps<{ key: string; amount: number; svg: { fill: string } }>) => {
  const dataAdapted = React.useMemo(
    () =>
      data.map((d) => ({
        id: d.key,
        label: d.key,
        value: d.amount,
      })),
    [data]
  );

  const colors = React.useMemo(() => data.map((d) => d.svg.fill), [data]);

  return (
    <ResponsivePie
      activeOuterRadiusOffset={2}
      colors={colors}
      data={dataAdapted}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      endAngle={90}
      innerRadius={0.55}
      padAngle={3.5}
      startAngle={-90}
    />
  );
};
