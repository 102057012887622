import { StyleSheet } from 'react-native';
import { Config } from '../config';

export const markdownStyle = StyleSheet.create({
  body: {
    color: Config.colorTextPrimary,
    fontFamily: Config.fontRegular,
    fontSize: 14,
    margin: 0,
  },
  heading1: {
    fontFamily: Config.fontMedium,
  },
  heading2: {
    fontFamily: Config.fontMedium,
  },
  heading3: {
    fontFamily: Config.fontMedium,
  },
  heading4: {
    fontFamily: Config.fontMedium,
  },
  heading5: {
    fontFamily: Config.fontMedium,
  },
  heading6: {
    fontFamily: Config.fontMedium,
  },
  strong: {
    fontFamily: Config.fontSemibold,
  },
});
