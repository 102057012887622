import dayjs from 'dayjs';
import { useGetMyCompanies } from '../../../hooks/api/company.api';
import {
  useGetChallenge,
  useGetChallengesHistory,
} from '../../../hooks/api/gamification.api';
import * as React from 'react';
import { Metric, Metrics } from '../../../api/gamification/gamification.models';

const challengeDate = dayjs()
  .subtract(1, 'day')
  .set('seconds', 0)
  .set('minutes', 0)
  .set('hours', 13)
  .format('YYYY-MM-DDTHH:mm:ssZ');

export const useCompanySectionController = () => {
  const { data: [selectedCompany] = [undefined] } = useGetMyCompanies(false);

  const { data: challenge, isLoading } = useGetChallenge({
    companyId: selectedCompany?.id,
    datetime: challengeDate,
  });

  const { data: challenges } = useGetChallengesHistory({
    companyId: selectedCompany?.id,
  });

  const historyExist = !!challenges && challenges.length > 0;
  const challengeExist = !!challenge;

  const leftDays = React.useMemo(() => {
    if (!challenge) return null;
    const daysLeftNotRounded =
      dayjs(challenge?.endsAt).diff(dayjs()) / 1000 / 60 / 60 / 24 + 1;
    if (daysLeftNotRounded < 1) return null;
    return Math.round(daysLeftNotRounded + Number.EPSILON);
  }, [challenge]);

  const currentChallengePoints = React.useMemo(() => {
    if (!challenge) return null;
    return challenge.currentAmount;
  }, [challenge]);

  const challengePrizes = React.useMemo(() => {
    if (!challenge) return [];
    return [...challenge.targets].reverse();
  }, [challenge]);

  const completedPercentage = React.useMemo(() => {
    if (!currentChallengePoints || !challenge) return 0;
    const percentage = (currentChallengePoints / challenge.targetAmount) * 100;

    return Math.min(percentage, 100);
  }, [challenge, currentChallengePoints]);

  const nextPrizePoints = React.useMemo(() => {
    if (!challenge || !currentChallengePoints === null) return null;
    let nextPrize = challenge.targets.find(
      (t) => completedPercentage < t.minPercent
    );
    if (!nextPrize) nextPrize = challenge.targets.at(-1);

    return challenge.targetAmount * (nextPrize!.minPercent / 100);
  }, [challenge, completedPercentage, currentChallengePoints]);

  const completedPrizes = React.useMemo(() => {
    if (!challenge || !currentChallengePoints) return null;
    const filteredPrizes = challenge.targets.filter(
      (t) => completedPercentage >= t.minPercent
    );

    return filteredPrizes;
  }, [challenge, completedPercentage, currentChallengePoints]);

  const currentMetrics = React.useMemo(() => {
    return completedPrizes?.reduce((calculatedMetrics, completedPrize) => {
      const weightedMetrics = Object.keys(completedPrize.prize.metrics).reduce(
        (pv, cv) => {
          const metric = completedPrize.prize.metrics[cv];
          return {
            ...pv,
            [cv]: {
              amount: metric.amount * completedPrize.prizeQta,
              unit: metric.unit,
              labels: metric.labels,
            },
          };
        },
        {} as Metrics
      );

      const finalMetrics = Object.keys(weightedMetrics).reduce((pv, cv) => {
        const calculatedMetric: Metric | undefined = calculatedMetrics[cv];

        if (!calculatedMetric) {
          return {
            ...pv,
            [cv]: { ...weightedMetrics[cv] },
          };
        } else {
          return {
            ...pv,
            [cv]: {
              ...calculatedMetric,
              amount: calculatedMetric.amount + weightedMetrics[cv].amount,
            },
          };
        }
      }, {} as Metrics);

      return finalMetrics;
    }, {} as Metrics);
  }, [completedPrizes]);

  return {
    completedPercentage,
    currentMetrics,
    currentChallengePoints,
    challengePrizes,
    nextPrizePoints,
    completedPrizes,
    challengeExist,
    isLoading,
    historyExist,
    leftDays,
  };
};
