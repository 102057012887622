import { s } from 'react-native-wind';
import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { Paragraph } from './Paragraph';
import { ChevronRightIcon } from 'react-native-heroicons/outline';
import { Config } from '../config';

type UmListItemProps = {
  label: string;
  containerStyle?: StyleProp<ViewStyle>;
  onPress?: () => void;
};

export const UmListItem: React.FC<UmListItemProps> = ({
  label,
  onPress,
  containerStyle,
}) => {
  return (
    <TouchableOpacity
      style={[
        s`flex-row rounded-2xl bg-card py-3 px-4 border border-text-secondary items-center`,
        containerStyle,
      ]}
      disabled={!onPress}
      onPress={onPress}
    >
      <Paragraph style={s`flex-1`} numberOfLines={1}>
        {label}
      </Paragraph>
      {onPress ? (
        <ChevronRightIcon
          style={s`ml-2`}
          color={Config.colorTextPrimary}
          size={16}
        />
      ) : null}
    </TouchableOpacity>
  );
};
