import { IHttpClient } from '../../services/HttpClient/IHttpClient';
import { getValueInDelimiter } from '../../utils/miscellaneous';
import { normalizeVotingDays } from '../utils';
import { ITeamApi } from './ITeamApi';
import {
  AddMemberRequest,
  AddMemberResponse,
  GetMyTeamsRequest,
  GetMyTeamsResponse,
  GetTeamDetailsRequest,
  GetTeamDetailsResponse,
  GetTeamMemberRequest,
  GetTeamMemberResponse,
  GetTeamMembersRequest,
  GetTeamMembersResponse,
  MyTeam,
  RemoveTeamMemberRequest,
  TeamStatus,
  UpdateTeamMemberMetaRequest,
  UpdateTeamRequest,
  UpdateTeamResponse,
  UpdateTeamStatusRequest,
} from './team.models';

export class TeamApi implements ITeamApi {
  constructor(private readonly httpClient: IHttpClient) {}

  public readonly getMyTeams = async ({
    status,
  }: GetMyTeamsRequest): Promise<MyTeam[]> => {
    const res = await this.httpClient.get<GetMyTeamsResponse>(
      `/core/team/my/${status}`,
      {
        isAuthorized: true,
      }
    );

    const teams: MyTeam[] = res.items.map((rt) => {
      return {
        ...rt,
        status: getValueInDelimiter(rt.statusFilter, '#', '#')! as TeamStatus,
        voteAnonymous: Boolean(rt.voteAnonymous),
      };
    });

    return teams;
  };

  public readonly getTeamDetails = async ({
    teamId,
  }: GetTeamDetailsRequest) => {
    const res = await this.httpClient.get<GetTeamDetailsResponse>(
      `/core/team/${teamId}`,
      { isAuthorized: true }
    );

    return {
      ...res.item,
      status: getValueInDelimiter(
        res.item.statusFilter,
        '#',
        '#'
      )! as TeamStatus,
      voteAnonymous: Boolean(res.item.voteAnonymous),
    };
  };

  public readonly updateTeam = async (req: UpdateTeamRequest) => {
    let normalizedVotingDays: string | undefined = undefined;
    let voteAnonymous: 1 | 0 | undefined = undefined;

    if (req.voteAnonymous !== undefined)
      voteAnonymous = req.voteAnonymous ? 1 : 0;

    if (req.votingDays)
      normalizedVotingDays = normalizeVotingDays(req.votingDays);

    const body = {
      name: req.name,
      voteAnonymous,
      votingDays: normalizedVotingDays,
      notificationTime: req.notificationTime,
    };

    const res = await this.httpClient.put<UpdateTeamResponse>(
      `/core/team/${req.teamId}`,
      {
        isAuthorized: true,
        body,
      }
    );

    return res.item;
  };

  public readonly updateTeamStatus = async ({
    teamId,
    status,
  }: UpdateTeamStatusRequest) => {
    await this.httpClient.put<void>(
      `/core/team/${teamId}/status/${status.toLocaleLowerCase()}`,
      {
        isAuthorized: true,
      }
    );
  };

  public readonly addTeamMember = async ({
    teamId,
    memberId,
  }: AddMemberRequest) => {
    const res = await this.httpClient.put<AddMemberResponse>(
      `/core/team/${teamId}/member/${memberId}`,
      {
        isAuthorized: true,
      }
    );

    return res.item;
  };

  public readonly removeTeamMember = async ({
    teamId,
    memberId,
  }: RemoveTeamMemberRequest) => {
    await this.httpClient.delete<void>(
      `/core/team/${teamId}/member/${memberId}`,
      {
        isAuthorized: true,
      }
    );
  };

  public readonly getTeamMembers = async ({
    teamId,
    limit = '',
    next = '',
    previous = '',
  }: GetTeamMembersRequest) => {
    const res = await this.httpClient.get<GetTeamMembersResponse>(
      `/core/team/${teamId}/member`,
      {
        params: {
          limit,
          next,
          previous,
        },
        isAuthorized: true,
      }
    );
    return res;
  };

  public readonly updateTeamMemberMeta = async ({
    notificationTime,
    workingDays,
    memberId,
    teamId,
    notificationEnabled,
    useDefaultVotingDays,
  }: UpdateTeamMemberMetaRequest) => {
    const normalizedVotingDays =
      workingDays && normalizeVotingDays(workingDays);
    await this.httpClient.put<void>(
      `/core/team/${teamId}/member/${memberId}/meta`,
      {
        isAuthorized: true,
        body: {
          notificationTime: notificationTime && `${notificationTime}:00`,
          votingDays: normalizedVotingDays,
          notificationEnabled,
          useDefaultVotingDays,
        },
      }
    );
  };

  public readonly getTeamMember = async ({
    teamId,
    memberId,
  }: GetTeamMemberRequest) => {
    const res = await this.httpClient.get<GetTeamMemberResponse>(
      `/core/team/${teamId}/member/${memberId}`,
      {
        isAuthorized: true,
      }
    );
    return res.item;
  };
}
