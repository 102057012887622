import * as React from 'react';
import { BadgeProgressContext } from './BadgeProgressContext';

export const useBadgeProgress = () => {
  const { badgeProgress, setBadgeProgress } =
    React.useContext(BadgeProgressContext);
  return {
    badgeProgress,
    setBadgeProgress,
  };
};
