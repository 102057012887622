import { s } from 'react-native-wind';
import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native';
import { Config } from '../config';

type TitleProps = {
  children: string | React.ReactNode;
  numberOfLines?: number;
  style?: StyleProp<TextStyle>;
};

export const Title: React.FC<TitleProps> = ({
  children,
  style,
  numberOfLines,
}) => {
  const titleStyle = StyleSheet.flatten([styles.text, style]);
  return (
    <Text numberOfLines={numberOfLines} style={titleStyle}>
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: Config.fontSemibold,
    ...s`text-4xl text-text-primary`,
  },
});
