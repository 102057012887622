import { useNavigation } from '@react-navigation/native';
import { useCallback, useMemo } from 'react';
import { useFullPageLoader } from '../../../context/Loader/useFullPageLoader';
import {
  useSignInMutation,
  useSignUpMutation,
} from '../../../hooks/api/auth.api';
import { useGetWhoAmI } from '../../../hooks/api/user.api';
import {
  AUTHENTICATION_SCREEN,
  AUTH_CODE_SCREEN,
} from '../../../navigation/authentication/authentication.types';
import { AppNavigation } from '../../../navigation/core/types';
import { useAuthenticationForm } from './useAuthenticationForm';

export const useAuthenticationController = () => {
  const navigation =
    useNavigation<AppNavigation<typeof AUTHENTICATION_SCREEN>>();
  const [openLoader, closeLoader] = useFullPageLoader();
  const { mutateAsync: getWhoAmI } = useGetWhoAmI();
  const { mutateAsync: signUpApiCall } = useSignUpMutation();
  const { mutateAsync: signInApiCall } = useSignInMutation();

  const { emailValue, onEmailChange, errors, handleSubmit } =
    useAuthenticationForm();

  const signUp = useCallback(
    async (email: string) => {
      const username = email.split('@')[0];
      return await signUpApiCall({ email, username });
    },
    [signUpApiCall]
  );

  const signIn = useCallback(
    async (email: string) => {
      return await signInApiCall({ email });
    },
    [signInApiCall]
  );

  const signUpIn = useMemo(() => {
    return handleSubmit(async ({ email }) => {
      const lowerCaseEmail = email.toLocaleLowerCase();
      openLoader();
      try {
        const { exists } = await getWhoAmI(lowerCaseEmail);
        if (!exists) {
          await signUp(lowerCaseEmail);
        }
        const res = await signIn(lowerCaseEmail);
        navigation.push(AUTH_CODE_SCREEN, {
          email: lowerCaseEmail,
          session: res.Session,
        });
      } catch (e) {
      } finally {
        closeLoader();
      }
    });
  }, [
    closeLoader,
    getWhoAmI,
    handleSubmit,
    navigation,
    openLoader,
    signIn,
    signUp,
  ]);

  return {
    emailValue,
    onEmailChange,
    errors,
    signUpIn,
  };
};
