import * as React from 'react';
import Svg, { SvgProps, Path, ClipPath, Defs, G } from 'react-native-svg';
import { IconProps } from './types';

type SilverMedalIconProps = IconProps & SvgProps;

const SilverMedalIcon = ({
  width,
  height,
  size,
  ...props
}: SilverMedalIconProps) => (
  <Svg
    viewBox="0 0 20 20"
    width={width || size}
    height={height || size}
    fill="none"
    {...props}
  >
    <G clipPath="url(#a)">
      <Path
        fill="#98DB7C"
        d="m9.198 14.953-3.224-1.115-.71.072-1.538 4.417a.203.203 0 0 0 .213.269l2.155-.226c.06-.006.12.015.163.057l1.55 1.515a.203.203 0 0 0 .333-.079l1.548-4.445-.49-.465Z"
      />
      <Path
        fill="#82D361"
        d="M9.688 15.418 10 14.52l-4.425-1.505-.312.895a8.123 8.123 0 0 0 4.425 1.508Z"
      />
      <Path
        fill="#98DB7C"
        d="m10.802 14.953 3.224-1.115.711.072 1.538 4.417a.203.203 0 0 1-.213.269l-2.155-.226a.203.203 0 0 0-.164.057l-1.549 1.515a.203.203 0 0 1-.334-.079l-1.547-4.445.49-.465Z"
      />
      <Path
        fill="#82D361"
        d="M10.313 15.418 10 14.52l4.425-1.505.312.895a8.122 8.122 0 0 1-4.424 1.508Z"
      />
      <Path
        fill="#E0E0E0"
        d="M10 14.04c-7.25 0-7.257-7.025-7.25-7.146a7.26 7.26 0 0 1 14.5-.008c.007.124 0 7.154-7.25 7.154Z"
      />
      <Path
        fill="#CECECE"
        d="M10 13.787a7.26 7.26 0 0 1-7.25-6.893 7.26 7.26 0 1 0 14.501 0A7.26 7.26 0 0 1 10 13.787Z"
      />
      <Path
        fill="#9E9E9E"
        d="M10 12.525c-5.719 0-5.715-5.51-5.707-5.63a5.72 5.72 0 0 1 11.415 0c.008.12 0 5.63-5.708 5.63Z"
      />
      <Path
        fill="#898989"
        d="M10 12.247a5.72 5.72 0 0 1-5.707-5.353 5.72 5.72 0 1 0 11.414 0A5.72 5.72 0 0 1 10 12.247Z"
      />
      <Path
        fill="#E0E0E0"
        d="M11.646 10.657H8.368c-.397 0-.703-.692-.674-.85.083-.438.279-.852.57-1.196l2.307-2.726a.572.572 0 0 0 .084-.608.533.533 0 0 0-.505-.328.779.779 0 0 0-.778.777.719.719 0 0 1-1.438 0 2.218 2.218 0 0 1 2.291-2.214 1.97 1.97 0 0 1 1.745 1.182c.316.714.2 1.526-.302 2.12L9.362 9.54a1.15 1.15 0 0 0-.025.03h2.31c.263 0 .493.142.618.353.064.107-.222.734-.619.734Z"
      />
      <Path
        fill="#CECECE"
        d="M11.647 10.276H8.368a.718.718 0 0 1-.673-.468c-.03.158-.046.319-.046.481 0 .397.322.72.72.72h3.278a.719.719 0 0 0 .618-1.086.718.718 0 0 1-.618.353Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h20v20H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SilverMedalIcon;
