import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import {
  AddMemberRequest,
  GetMyTeamsRequest,
  GetTeamDetailsRequest,
  GetTeamMemberRequest,
  GetTeamMembersRequest,
  RemoveTeamMemberRequest,
  TeamStatus,
  UpdateTeamRequest,
  UpdateTeamStatusRequest,
} from '../../api/team/team.models';
import { useApi } from '../../context/ApiContext/useApi';
import { InvalidationTag, useAppMutation, useAppQuery } from './utils';

export const useGetMyTeams = (status: TeamStatus) => {
  const { teamApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.MyTeams, { status }],
    ({ queryKey }) => {
      const params = queryKey[1] as GetMyTeamsRequest;
      return teamApi.getMyTeams(params);
    }
  );
  return queryObject;
};

export const useGetMyActiveTeamsMutation = () => {
  const { teamApi } = useApi();
  const mutation = useAppMutation(() => {
    return teamApi.getMyTeams({ status: TeamStatus.Active });
  });
  return mutation;
};

export const useGetTeamDetails = (teamId: string | undefined) => {
  const { teamApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.MyTeams, { teamId }],
    ({ queryKey }) => {
      const params = queryKey[1] as GetTeamDetailsRequest;
      return teamApi.getTeamDetails(params);
    },
    { enabled: !!teamId }
  );
  return queryObject;
};

export const useGetTeamDetailsMutation = () => {
  const { teamApi } = useApi();
  const mutation = useAppMutation((teamId: string) =>
    teamApi.getTeamDetails({ teamId })
  );
  return mutation;
};

export const useUpdateTeam = () => {
  const { teamApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: UpdateTeamRequest) => teamApi.updateTeam(req),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.MyTeams, { teamId: req.teamId }],
        });
      },
    }
  );
  return mutation;
};

export const useUpdateTeamStatus = () => {
  const { teamApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: UpdateTeamStatusRequest) => teamApi.updateTeamStatus(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [InvalidationTag.MyTeams] });
      },
    }
  );
  return mutation;
};

export const useAddTeamMember = () => {
  const { teamApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: AddMemberRequest) => teamApi.addTeamMember(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.TeamMembers],
        });
      },
    }
  );
  return mutation;
};

export const useRemoveTeamMember = () => {
  const { teamApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: RemoveTeamMemberRequest) => teamApi.removeTeamMember(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.TeamMembers],
        });
        queryClient.invalidateQueries({ queryKey: [InvalidationTag.MyTeams] });
      },
    }
  );
  return mutation;
};

export const useGetTeamMembers = ({ teamId, limit }: GetTeamMembersRequest) => {
  const { teamApi } = useApi();
  const queryObject = useInfiniteQuery({
    queryKey: [InvalidationTag.TeamMembers, { teamId }],
    queryFn: ({ pageParam }) => {
      const pageParamParsed = pageParam === 'nothing' ? undefined : pageParam;
      return teamApi.getTeamMembers({ teamId, limit, next: pageParamParsed });
    },
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: 'nothing',
  });
  return queryObject;
};

export const useGetTeamMembersMutation = () => {
  const { teamApi } = useApi();
  const mutation = useAppMutation((teamId: string) =>
    teamApi.getTeamMembers({ teamId })
  );
  return mutation;
};

export const useGetTeamMember = ({
  teamId,
  memberId,
}: GetTeamMemberRequest) => {
  const { teamApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.TeamMember, { teamId, memberId }],
    ({ queryKey }) => {
      const params = queryKey[1] as GetTeamMemberRequest;
      return teamApi.getTeamMember(params);
    }
  );
  return queryObject;
};
