import { s } from 'react-native-wind';
import * as React from 'react';
import { GenericScreenProps } from '../../navigation/core/types';
import { TEAM_SETTINGS_SCREEN } from '../../navigation/authenticatedWithBottomTab/authenticated.types';
import {
  ChooseVotingDays,
  Layout,
  Paragraph,
  ParagraphBold,
  Subtitle,
} from '../../components';
import { TouchableOpacity, View } from 'react-native';
import { BellIcon, UserGroupIcon } from 'react-native-heroicons/outline';
import { Config } from '../../config';
import { useLanguage } from '../../locale';
import { Edge } from 'react-native-safe-area-context';
import { ChangeTeamBottomSheet } from '../../components/ChangeTeamBottomSheet';
import { CardListElement } from './components/CardListElement';
import { UmTimePicker } from '../../components/UmTimePicker';
import { useTeamSettingsController } from './controllers/useTeamSettingsController';
import { TeamSettingsHeader } from './components/TeamSettingsHeader';
import {
  TEAM_MEMBERS_SCREEN,
  USER_NOTIFICATIONS_SCREEN,
} from '../../navigation/authenticated/authenticated.types';
import { NoData } from '../stats/components/NoData';

const teaSettingsScreenSafeAreaEdges: Edge[] = ['left', 'right', 'top'];

type TeamSettingsScreenProps = {} & GenericScreenProps<
  typeof TEAM_SETTINGS_SCREEN
>;

export const TeamSettingsScreen: React.FC<TeamSettingsScreenProps> = ({
  navigation,
}) => {
  const [t] = useLanguage();

  const {
    changeTeamBottomSheetRef,
    confirmNotificationTime,
    isTimePickerOpen,
    onVotingDaysUpdate,
    openChangeTeamBottomSheet,
    openTimePicker,
    timePickerStartingDate,
    teamDetails,
    closeTimePicker,
    isTeamAdmin,
    selectedTeam,
    myTeams,
    myDetails,
  } = useTeamSettingsController();

  const customHeader = React.useMemo(() => {
    return (
      <TeamSettingsHeader
        teamsCount={myTeams.length}
        isAdmin={isTeamAdmin}
        onRightIconPress={openChangeTeamBottomSheet}
        title={teamDetails?.name}
      />
    );
  }, [
    isTeamAdmin,
    myTeams.length,
    openChangeTeamBottomSheet,
    teamDetails?.name,
  ]);

  const listElements = React.useMemo(() => {
    if (!teamDetails || !myDetails) return [];
    return [
      {
        label: t('teamSettings.teamMembers'),
        icon: <UserGroupIcon color={Config.colorTextPrimary} />,
        onPress: () =>
          navigation.navigate(TEAM_MEMBERS_SCREEN, { teamId: teamDetails?.id }),
      },
      {
        label: t('teamSettings.yourNotifications'),
        icon: <BellIcon color={Config.colorTextPrimary} />,
        onPress: () =>
          navigation.navigate(USER_NOTIFICATIONS_SCREEN, {
            teamId: teamDetails.id,
            userId: myDetails.id,
          }),
      },
    ];
  }, [myDetails, navigation, t, teamDetails]);

  const showNoData = !selectedTeam;

  return (
    <>
      <Layout
        customNavbar={customHeader}
        showNavbar={false}
        isScrollView={!showNoData}
        safeAreaEdges={teaSettingsScreenSafeAreaEdges}
      >
        {showNoData ? (
          <NoData descriptions={[t('teamSettings.emptyDescription')]} />
        ) : (
          <>
            <View style={s`rounded-2xl p-4 bg-card`}>
              {listElements.map((e, i, a) => {
                const isFirst = i === 0;
                const isLast = i === a.length - 1;

                return (
                  <CardListElement
                    key={`settings-list-element-${i}`}
                    containerStyle={s`${isFirst ? 'pt-0' : ''} ${
                      isLast ? 'pb-0 border-b-0' : ''
                    }`}
                    label={e.label}
                    icon={e.icon}
                    onPress={e.onPress}
                  />
                );
              })}
            </View>
            {isTeamAdmin && teamDetails ? (
              <View style={s`mt-6`}>
                <Subtitle>{t('teamSettings.teamNotifications')}</Subtitle>
                <View style={s`mt-4 flex-row items-center`}>
                  <Paragraph style={s`mr-4`}>{t('teamSettings.at')}</Paragraph>
                  <TouchableOpacity
                    onPress={openTimePicker}
                    style={s`w-20 bg-card rounded-md items-center py-1`}
                  >
                    <ParagraphBold>
                      {teamDetails.notificationTime.slice(0, 5)}
                    </ParagraphBold>
                  </TouchableOpacity>
                </View>
                <View style={s`mt-4`}>
                  <ChooseVotingDays
                    onVotingDayPressed={onVotingDaysUpdate}
                    votingDays={teamDetails.votingDays}
                  />
                </View>
              </View>
            ) : null}
          </>
        )}
      </Layout>
      {isTeamAdmin ? (
        <UmTimePicker
          startingDate={timePickerStartingDate}
          isOpen={isTimePickerOpen}
          onCancel={closeTimePicker}
          onConfirm={confirmNotificationTime}
        />
      ) : null}
      <ChangeTeamBottomSheet
        showSummary={false}
        ref={changeTeamBottomSheetRef}
      />
    </>
  );
};
