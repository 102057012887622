import * as React from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { s } from 'react-native-wind';
import { ParagraphBold } from './ParagraphBold';

export type UmSwitchOption = {
  key: string;
  label: string;
};

type UmSwitchProps = {
  options: UmSwitchOption[];
  selectedOption: string;
  containerStyle?: StyleProp<ViewStyle>;
  onOptionPress?: (key: string) => void;
};

const UmSwitch: React.FC<UmSwitchProps> = ({
  options,
  selectedOption,
  containerStyle,
  onOptionPress,
}: UmSwitchProps) => {
  return (
    <View
      style={[s`w-full h-14 flex-row p-2 bg-card rounded-xl`, containerStyle]}
    >
      {options.map((o) => {
        const isSelected = o.key === selectedOption;
        const optionPress = () => onOptionPress?.(o.key);

        return (
          <TouchableOpacity
            key={o.key}
            style={s`flex-1 item-center justify-center rounded-lg ${
              isSelected ? 'bg-primary' : ''
            }`}
            onPress={optionPress}
          >
            <ParagraphBold
              style={s`text-center ${isSelected ? 'text-white' : ''}`}
            >
              {o.label}
            </ParagraphBold>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default UmSwitch;
