import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const LogoIcon: React.FC<IconProps> = ({ height, width }) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 15 14" fill="none">
      <Path
        d="M.164 13.923h6.834l.184.004a7.182 7.182 0 007.18-7.18V0C14.329.096 11.047 9.193.165 13.923"
        fill="#6AC982"
      />
      <Path
        d="M7.182 5.577A5.578 5.578 0 001.604 0H0v6.747c0 1.997.814 3.802 2.133 5.109L.16 13.923h.003C11.046 9.193 14.328.096 14.362 0h-1.604a5.577 5.577 0 00-5.576 5.577v.026"
        fill="#33AA4C"
      />
      <Path
        d="M4.483 8.07a.729.729 0 11-1.457-.002.729.729 0 011.457.001zM7.912 8.07a.728.728 0 11-1.456 0 .728.728 0 011.456 0zM11.342 8.07a.728.728 0 11-1.457-.001.728.728 0 011.457 0z"
        fill="#fff"
      />
    </Svg>
  );
};
