import { s } from 'react-native-wind';
import React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { Paragraph } from './Paragraph';
import { CheckCircleIcon } from 'react-native-heroicons/solid';
import { Config } from '../config';

type SelectableElementProps = {
  lable?: string;
  isSelected?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
};

export const SelectableElement: React.FC<SelectableElementProps> = ({
  isSelected,
  lable,
  style,
  onPress,
}) => {
  const borderColor = isSelected
    ? 'border-primary border'
    : 'border-text-secondary border';

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        s`w-full p-4 rounded-2xl flex-row justify-between items-center ${borderColor}`,
        style,
      ]}
    >
      <Paragraph>{lable}</Paragraph>
      {isSelected ? (
        <CheckCircleIcon color={Config.colorPrimary} size={24} />
      ) : null}
    </TouchableOpacity>
  );
};
