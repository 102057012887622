import { s } from 'react-native-wind';
import * as React from 'react';
import {
  Feedback,
  FeedbackCategory,
} from '../../../api/feedback/feedback.models';
import {
  Description,
  Separator,
  Subtitle,
  UmFeedbackCategory,
} from '../../../components';
import { useLanguage } from '../../../locale';
import { getGoodAndBadCategories } from '../../../utils/feedbacks';
import { View } from 'react-native';
import { Image } from 'expo-image';

type FeedbackCategoriesSectionProps = {
  feedbacks: Feedback[];
  categories: FeedbackCategory[];
};

export const FeedbackCategoriesSection: React.FC<
  FeedbackCategoriesSectionProps
> = ({ categories, feedbacks }) => {
  const [t] = useLanguage();

  const { goodCategories, badCategories } = React.useMemo(() => {
    return getGoodAndBadCategories(feedbacks);
  }, [feedbacks]);

  return (
    <>
      {goodCategories.length > 0 ? (
        <>
          <Subtitle>{t('stats.feelGood')}</Subtitle>
          <Description style={s`mt-2`}>
            {t('stats.feelGoodDescription')}
          </Description>
          <View style={s`mt-2 flex-row flex-wrap justify-center`}>
            {goodCategories.map((gc) => {
              const category = categories.find((c) => gc === c.id);
              if (!category) return null;
              return (
                <UmFeedbackCategory
                  key={`good-category-stats-${gc}`}
                  icon={
                    <Image
                      style={s`w-7 h-7`}
                      source={category.icon.light}
                      contentFit="cover"
                      transition={300}
                    />
                  }
                  label={category.title}
                  value={4}
                  containerStyle={s`w-1/3 mt-6 px-1`}
                  isSelected
                />
              );
            })}
          </View>
          <Separator style={s`my-6`} />
        </>
      ) : null}
      {badCategories.length > 0 ? (
        <>
          <Subtitle>{t('stats.feelBad')}</Subtitle>
          <Description style={s`mt-2`}>
            {t('stats.feelBadDescription')}
          </Description>
          <View style={s`mt-2 flex-row flex-wrap justify-center`}>
            {badCategories.map((gc) => {
              const category = categories.find((c) => gc === c.id);
              if (!category) return null;
              return (
                <UmFeedbackCategory
                  key={`bad-category-stats-${gc}`}
                  icon={
                    <Image
                      style={s`w-7 h-7`}
                      source={category.icon.light}
                      contentFit="cover"
                      transition={300}
                    />
                  }
                  label={category.title}
                  value={1}
                  containerStyle={s`w-1/3 mt-6 px-1`}
                  isSelected
                />
              );
            })}
          </View>
          <Separator style={s`my-6`} />
        </>
      ) : null}
    </>
  );
};
