import * as React from 'react';
import Svg, { SvgProps, Path, ClipPath, Defs, G } from 'react-native-svg';
import { IconProps } from './types';

type GoldMedalIconProps = IconProps & SvgProps;

const GoldMedalIcon = ({
  width,
  height,
  size,
  ...props
}: GoldMedalIconProps) => (
  <Svg
    viewBox="0 0 20 20"
    width={width || size}
    height={height || size}
    fill="none"
    {...props}
  >
    <G clipPath="url(#a)">
      <Path
        fill="#FF4755"
        d="m6.056 13.872-.793.039-1.537 4.416a.203.203 0 0 0 .213.27l2.155-.226c.06-.006.12.014.163.057l1.55 1.514a.203.203 0 0 0 .333-.078l1.548-4.446-.457-.442-3.175-1.104Z"
      />
      <Path
        fill="#FC2B3A"
        d="M9.688 15.418 10 14.52l-4.425-1.505-.312.895a8.123 8.123 0 0 0 4.425 1.508Z"
      />
      <Path
        fill="#FF4755"
        d="m13.944 13.872.793.039 1.538 4.416a.203.203 0 0 1-.213.27l-2.155-.226a.204.204 0 0 0-.164.057l-1.549 1.514a.203.203 0 0 1-.334-.078l-1.547-4.446.456-.442 3.175-1.104Z"
      />
      <Path
        fill="#FC2B3A"
        d="M10.313 15.418 10 14.52l4.425-1.505.312.895a8.122 8.122 0 0 1-4.424 1.508Z"
      />
      <Path
        fill="#FFE27A"
        d="M10 14.067c-7.26 0-7.257-7.052-7.25-7.173a7.26 7.26 0 0 1 14.5 0c.007.121.01 7.173-7.25 7.173Z"
      />
      <Path
        fill="#F9CF58"
        d="M10 13.787a7.26 7.26 0 0 1-7.25-6.893 7.26 7.26 0 1 0 14.501 0A7.26 7.26 0 0 1 10 13.787Z"
      />
      <Path
        fill="#FFBA57"
        d="M10 12.505c-5.707 0-5.715-5.613-5.707-5.734a5.72 5.72 0 0 1 11.415 0c.008.121 0 5.734-5.708 5.734Z"
      />
      <Path
        fill="#FFAC3E"
        d="M10 12.247a5.72 5.72 0 0 1-5.707-5.353 5.72 5.72 0 1 0 11.414 0A5.72 5.72 0 0 1 10 12.247Z"
      />
      <Path
        fill="#FFE27A"
        d="M10.326 10.657a.719.719 0 0 1-.719-.72V5.909s-.43.432-.944.173c-.402-.202-.402-.721-.402-.721l.887-1.497a.719.719 0 0 1 .618-.352h.56c.397 0 .72.322.72.719v5.707a.719.719 0 0 1-.72.72Z"
      />
      <Path
        fill="#F9CF58"
        d="M9.509 5.344a.732.732 0 0 1-.935.301.717.717 0 0 1-.313-.285.719.719 0 0 0 .24.978c.344.211.802.085 1.007-.262l.1-.168v-.537c0-.054-.072-.074-.1-.027ZM10.326 10.277a.719.719 0 0 1-.719-.72v.732a.719.719 0 0 0 1.438 0v-.731a.719.719 0 0 1-.719.719Z"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h20v20H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default GoldMedalIcon;
