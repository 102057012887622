import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { NavbarAction } from '../components';

import { Config } from '../config';
import { ChevronLeftIcon } from 'react-native-heroicons/outline';
import { Platform } from 'react-native';
import { AUTHENTICATED_BOTTOM_TAB_NAME } from '../navigation/authenticated/authenticated.types';

export const useBackNavbarAction = (): NavbarAction | undefined => {
  const navigation = useNavigation<any>();

  const backNavbarAction: NavbarAction | undefined = useMemo(() => {
    if (!navigation.canGoBack() && Platform.OS !== 'web') return undefined;

    return {
      icon: (
        <ChevronLeftIcon
          size={Config.actionSize}
          color={Config.colorTextPrimary}
        />
      ),
      action: () => {
        if (Platform.OS === 'web' && !navigation.canGoBack())
          navigation.navigate(AUTHENTICATED_BOTTOM_TAB_NAME);
        else navigation.goBack();
      },
    };
  }, [navigation]);

  return backNavbarAction;
};
