import { createNotImplementedMethod } from '../utils';
import {
  GetHarmoniaConfigRequest,
  GetThreadRequest,
  GetThreadResponse,
  HarmoniaConfig,
  SetMessageShownRequest,
} from './harmonia.models';

export interface IHarmoniaApi {
  readonly getHarmoniaConfig: (
    req: GetHarmoniaConfigRequest
  ) => Promise<HarmoniaConfig>;
  readonly getUserThread: (req: GetThreadRequest) => Promise<GetThreadResponse>;
  readonly setMessageShown: (req: SetMessageShownRequest) => Promise<void>;
}

export class NotImplementedHarmoniaApi implements IHarmoniaApi {
  public readonly getHarmoniaConfig = createNotImplementedMethod();
  public readonly getUserThread = createNotImplementedMethod();
  public readonly setMessageShown = createNotImplementedMethod();
}
