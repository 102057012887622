import React, { useEffect, useMemo } from 'react';
import { getVersion } from 'react-native-device-info';
import { InvalidationTag, useAppQuery } from './api/utils';
import { getConfigurations } from '../portability/services/Firebase/RemoteConfig/RemoteConfig';
import { useLanguage } from '../locale';
import { Linking, Platform } from 'react-native';
import { Config } from '../config';
import { useSelector } from '../redux/utils';
import { isNoNetworkMomentSelector } from '../redux/general/general.selectors';
import { RNRestart } from '../portability/services/Restart/RNRestart';

export interface RemoteConfigError {
  title: string;
  message: string;
  cta?: string;
  ctaAction?: () => void;
  icon?: string;
  secondaryCta?: string;
  secondaryCtaAction?: () => void;
}
const useGetRemoteConfig = () => {
  const queryReturn = useAppQuery(
    [InvalidationTag.RemoteConfig],
    getConfigurations
  );
  return queryReturn;
};

const useRemoteConfig = () => {
  const { data: config, isLoading, isError } = useGetRemoteConfig();
  const [, , lang] = useLanguage();
  const [t] = useLanguage();
  const isNoNetworkMoment = useSelector(isNoNetworkMomentSelector);
  const [checkCompleted, setCheckCompleted] = React.useState(false);
  const [error, setError] = React.useState<RemoteConfigError | undefined>();

  const shouldShowMinVersion = useMemo(() => {
    const version = getVersion();

    if (!version) return false;

    if (config?.min_supported_version) {
      const vNumbers = version.split('.');
      const minVersionNumbers = config?.min_supported_version.split('.');

      for (let i = 0; i < minVersionNumbers.length; i++) {
        const vNumber = parseInt(vNumbers[i]);
        const minVNumber = parseInt(minVersionNumbers[i]);

        if (vNumber < minVNumber) {
          return true;
        } else if (vNumber > minVNumber) {
          return false;
        }
      }
    }
    return false;
  }, [config?.min_supported_version]);

  const openAppStore = () => {
    if (Platform.OS === 'ios') Linking.openURL(Config.iosStoreUrl);
    else if (Platform.OS === 'android') Linking.openURL(Config.androidStoreUrl);
  };

  useEffect(() => {
    if (isLoading) return;

    if (isNoNetworkMoment) {
      setError({
        title: t('remoteConfig.networkError.title'),
        message: t('remoteConfig.networkError.description'),
        cta: t('remoteConfig.networkError.ctaLabel'),
        ctaAction: () => {
          if (Platform.OS === 'web') {
            window.location.reload();
          } else {
            RNRestart.restart();
          }
        },
      });
    } else if (isError) {
      setError({
        title: t('remoteConfig.genericError.title'),
        message: t('remoteConfig.genericError.message'),
      });
    } else if (config?.backend_maintenance?.block_access) {
      setError({
        title: config.backend_maintenance?.title?.[lang] || '',
        message: config.backend_maintenance.message?.[lang] || '',
      });
    } else if (shouldShowMinVersion) {
      setError({
        title: t('remoteConfig.versionControl.title'),
        message: t('remoteConfig.versionControl.description'),
        cta: t('remoteConfig.versionControl.update'),
        ctaAction: openAppStore,
      });
    }

    setCheckCompleted(true);
  }, [
    config,
    lang,
    isError,
    t,
    isLoading,
    isNoNetworkMoment,
    shouldShowMinVersion,
  ]);

  return {
    isLoading,
    error,
    checkCompleted,
    canContinue: !error && checkCompleted,
  };
};
export default useRemoteConfig;
