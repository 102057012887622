import { s } from 'react-native-wind';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputContentSizeChangeEventData,
  TextInputProps,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import { Config } from '../config';
import { ParagraphSmall } from './ParagraphSmall';
import { BottomSheetTextInput } from '@gorhom/bottom-sheet';

type TextFieldProps = {
  placeholder: string;
  containerStyle?: StyleProp<ViewStyle>;
  textInputStyle?: StyleProp<TextStyle>;
  secureTextEntry?: boolean;
  frontIcon?: ReactNode;
  onChangeText?: TextInputProps['onChangeText'];
  value?: TextInputProps['value'];
  autoCapitalize?: TextInputProps['autoCapitalize'];
  keyboardType?: TextInputProps['keyboardType'];
  error?: string;
  isInBottomSheet?: boolean;
  numberOfLines?: number;
  autoExpand?: boolean;
  maxNumberOfLines?: number;
};

export const TextField: React.FC<TextFieldProps> = ({
  frontIcon,
  placeholder,
  containerStyle,
  textInputStyle,
  onChangeText,
  autoCapitalize,
  keyboardType,
  secureTextEntry,
  value,
  error,
  isInBottomSheet = false,
  numberOfLines,
  autoExpand,
  maxNumberOfLines,
}) => {
  const [autoExpandHeight, setAutoExpandHeight] = useState(27);
  const isMultiline = numberOfLines !== undefined && numberOfLines > 0;
  const fixedHeightClass = !isMultiline ? 'h-12' : '';

  const textInputMultilineStyle: TextStyle | undefined = useMemo(() => {
    return isMultiline
      ? {
          minHeight: numberOfLines * 20,
          maxHeight: numberOfLines * 20,
          ...s`text-vertical-top`,
        }
      : undefined;
  }, [numberOfLines, isMultiline]);

  const containerMultilineStyle: TextStyle | undefined = useMemo(() => {
    return isMultiline
      ? {
          minHeight: numberOfLines * 20 + 16,
          maxHeight: numberOfLines * 20 + 16,
        }
      : undefined;
  }, [numberOfLines, isMultiline]);

  const containerAutoExpansionStyle = useMemo(() => {
    return autoExpand
      ? {
          height: maxNumberOfLines
            ? Math.min(autoExpandHeight + 8, maxNumberOfLines * 20)
            : autoExpandHeight + 8,
        }
      : undefined;
  }, [autoExpand, autoExpandHeight, maxNumberOfLines]);

  const textInputAutoExpansionStyle = useMemo(() => {
    return autoExpand
      ? {
          height: maxNumberOfLines
            ? Math.min(autoExpandHeight + 8, maxNumberOfLines * 20)
            : autoExpandHeight + 8,
        }
      : undefined;
  }, [autoExpand, autoExpandHeight, maxNumberOfLines]);

  const onContentSizeChange = useCallback(
    (event: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
      setAutoExpandHeight(event.nativeEvent.contentSize.height);
    },
    []
  );

  return (
    <>
      <View
        style={[
          s`flex-row bg-card ${fixedHeightClass} px-3.5 rounded-2xl justify-center items-center border border-text-secondary`,
          containerMultilineStyle,
          Platform.OS !== 'web' ? containerAutoExpansionStyle : {},
          containerStyle,
        ]}
      >
        {frontIcon && <View style={s`mr-2`}>{frontIcon}</View>}
        {isInBottomSheet && Platform.OS !== 'web' ? (
          <BottomSheetTextInput
            style={[
              styles.textField,
              textInputAutoExpansionStyle,
              textInputMultilineStyle,
              textInputStyle,
            ]}
            placeholder={placeholder}
            onChangeText={onChangeText}
            keyboardType={keyboardType}
            autoCapitalize={autoCapitalize}
            secureTextEntry={secureTextEntry}
            multiline={isMultiline}
            numberOfLines={numberOfLines}
            value={value}
          />
        ) : (
          <TextInput
            style={[
              styles.textField,
              Platform.OS !== 'web' ? textInputAutoExpansionStyle : {},
              textInputMultilineStyle,
              textInputStyle,
            ]}
            placeholder={placeholder}
            onChangeText={onChangeText}
            keyboardType={keyboardType}
            autoCapitalize={autoCapitalize}
            secureTextEntry={secureTextEntry}
            multiline={isMultiline || autoExpand}
            numberOfLines={
              Platform.OS === 'web'
                ? maxNumberOfLines ?? numberOfLines
                : numberOfLines
            }
            onContentSizeChange={onContentSizeChange}
            value={value}
          />
        )}
      </View>
      {error && (
        <ParagraphSmall style={s`text-danger text-xs mt-2`}>
          {error}
        </ParagraphSmall>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  textField: {
    fontFamily: Config.fontRegular,
    fontSize: 16,
    ...s`text-text-primary flex-1 justify-center items-center h-full`,
  },
});
