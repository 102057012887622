import { useNavigation } from '@react-navigation/native';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { useUpdateProfileMutation } from '../../../hooks/api/user.api';
import { AppNavigation } from '../../../navigation/core/types';
import {
  ONBOARDING_ANONYMOUS_VOTE_SCREEN,
  ONBOARDING_BIRTH_DATE,
} from '../../../navigation/onboarding/onboarding.types';

const startingDate = dayjs().subtract(20, 'years').toDate();

export const useBirthDateController = () => {
  const navigation =
    useNavigation<AppNavigation<typeof ONBOARDING_BIRTH_DATE>>();
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState<boolean>();
  const [standardDate, setStandardDate] = React.useState<Date>(startingDate);
  const [date, setDate] = React.useState<Dayjs>();

  const { mutateAsync: updateProfile } = useUpdateProfileMutation();

  const openDatePicker = React.useCallback(() => {
    setIsDatePickerOpen(true);
  }, []);

  const closeDatePicker = React.useCallback(() => {
    setIsDatePickerOpen(false);
  }, []);

  React.useEffect(() => {
    if (standardDate) {
      const dayjsDate = dayjs(standardDate);
      setDate(dayjsDate);
    }
  }, [standardDate]);

  const confirm = React.useCallback(
    (newDate: Date) => {
      setStandardDate(newDate);
      closeDatePicker();
    },
    [closeDatePicker]
  );

  const goToAnonymousFeedbackStep = React.useCallback(() => {
    navigation.navigate(ONBOARDING_ANONYMOUS_VOTE_SCREEN);
  }, [navigation]);

  const next = React.useCallback(async () => {
    await updateProfile({ birthDate: date?.format('YYYY-MM-DD') });
    goToAnonymousFeedbackStep();
  }, [date, goToAnonymousFeedbackStep, updateProfile]);

  return {
    isDatePickerOpen,
    date,
    openDatePicker,
    standardDate,
    closeDatePicker,
    confirm,
    next,
    goToAnonymousFeedbackStep,
  };
};
