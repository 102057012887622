import * as React from 'react';
import { View } from 'react-native';
import { s } from 'react-native-wind';
import { Title } from './Title';

type HomeScreenHeaderProps = {
  title: string;
  rightIcon?: React.ReactNode;
  bottomComponent?: React.ReactNode;
  horizontalPadding?: boolean;
};

const HomeScreenHeader: React.FC<HomeScreenHeaderProps> = ({
  rightIcon,
  title,
  bottomComponent,

  horizontalPadding = true,
}) => {
  return (
    <View style={s`w-full border-b border-light-grey py-4`}>
      <View
        style={s`flex-row justify-between ${
          horizontalPadding ? 'px-page-padding' : ''
        } items-center`}
      >
        <Title numberOfLines={2}>{title}</Title>
        <View style={s`flex-row items-center`}>{rightIcon}</View>
      </View>
      <View style={s`px-page-padding`}>{bottomComponent}</View>
    </View>
  );
};

export default HomeScreenHeader;
