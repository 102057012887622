import { createNotImplementedMethod } from '../utils';
import {
  AddMemberRequest,
  GetMyTeamsRequest,
  GetTeamDetailsRequest,
  GetTeamMemberRequest,
  GetTeamMembersRequest,
  GetTeamMembersResponse,
  MyTeam,
  RemoveTeamMemberRequest,
  Team,
  TeamUser,
  UpdateTeamMemberMetaRequest,
  UpdateTeamRequest,
  UpdateTeamStatusRequest,
} from './team.models';

export interface ITeamApi {
  readonly getMyTeams: (req: GetMyTeamsRequest) => Promise<MyTeam[]>;
  readonly getTeamDetails: (req: GetTeamDetailsRequest) => Promise<Team>;
  readonly updateTeam: (req: UpdateTeamRequest) => Promise<Team>;
  readonly updateTeamStatus: (params: UpdateTeamStatusRequest) => Promise<void>;
  readonly addTeamMember: (params: AddMemberRequest) => Promise<TeamUser>;
  readonly removeTeamMember: (params: RemoveTeamMemberRequest) => Promise<void>;
  readonly getTeamMembers: (
    params: GetTeamMembersRequest
  ) => Promise<GetTeamMembersResponse>;
  readonly updateTeamMemberMeta: (
    params: UpdateTeamMemberMetaRequest
  ) => Promise<void>;
  readonly getTeamMember: (params: GetTeamMemberRequest) => Promise<TeamUser>;
}

export class NotImplementedTeamApi implements ITeamApi {
  public readonly getMyTeams = createNotImplementedMethod();
  public readonly getTeamDetails = createNotImplementedMethod();
  public readonly updateTeam = createNotImplementedMethod();
  public readonly updateTeamStatus = createNotImplementedMethod();
  public readonly addTeamMember = createNotImplementedMethod();
  public readonly removeTeamMember = createNotImplementedMethod();
  public readonly getTeamMembers = createNotImplementedMethod();
  public readonly updateTeamMemberMeta = createNotImplementedMethod();
  public readonly getTeamMember = createNotImplementedMethod();
}
