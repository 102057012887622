import { s } from 'react-native-wind';
import React from 'react';
import { View, ViewStyle } from 'react-native';

type PaginatorProps = {
  pageCount: number;
  selectedIndex: number;
  containerStyle?: ViewStyle;
};

export const Paginator: React.FC<PaginatorProps> = ({
  containerStyle,
  pageCount,
  selectedIndex,
}) => {
  return (
    <View style={[s`flex-row`, containerStyle]}>
      {Array(pageCount)
        .fill(undefined)
        .map((_, i) => {
          const isFirst = i === 0;
          const isSelected = i === selectedIndex;
          return (
            <View
              key={i}
              style={s`h-1.5 flex-1 rounded-full ${!isFirst ? 'ml-4' : ''} ${
                isSelected ? 'bg-secondary' : 'bg-card'
              }`}
            />
          );
        })}
    </View>
  );
};
