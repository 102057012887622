import { Medal } from '../../../api/gamification/gamification.models';
import {
  BronzeMedalIcon,
  GoldMedalIcon,
  SilverMedalIcon,
} from '../../../icons';

export const getCorrectMedalImage = (medalId: string) => {
  switch (medalId) {
    case Medal.Gold:
      return GoldMedalIcon;
    case Medal.Silver:
      return SilverMedalIcon;
    case Medal.Bronze:
      return BronzeMedalIcon;
    default:
      return BronzeMedalIcon;
  }
};
