import { createNotImplementedMethod } from '../utils';
import { SubscribeSlackRequest } from './slack.models';

export interface ISlackApi {
  readonly subscribe: (payload: SubscribeSlackRequest) => Promise<boolean>;
}

export class NotImplementedSlackApi implements ISlackApi {
  public readonly subscribe = createNotImplementedMethod();
}
