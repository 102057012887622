import {
  getAnalytics,
  setUserId as setFBUserId,
  logEvent as logFBEvent,
  setAnalyticsCollectionEnabled,
} from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../../../../firebase.config.json';

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const init = () => {
  setAnalyticsCollectionEnabled(analytics, true);
};

export const stop = () => {
  setAnalyticsCollectionEnabled(analytics, false);
};

export const setUserId = (userId: string) => {
  setFBUserId(analytics, userId);
};

export const logEvent = (
  eventName: string,
  params?: { [key: string]: string | number | boolean }
) => {
  logFBEvent(analytics, eventName, params);
};
