import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
import { IconProps } from './types';

type ProfileFilledIconProps = IconProps & SvgProps;

const ProfileFilledIcon = ({
  width,
  height,
  size,
  ...props
}: ProfileFilledIconProps) => (
  <Svg
    viewBox="0 0 512 512"
    width={width || size}
    height={height || size}
    fill="none"
    {...props}
  >
    <Path
      fill="#FFBB85"
      d="M375.672 123.309c0 33.886-12.152 63.218-36.125 87.195-23.973 23.973-53.309 36.125-87.195 36.125h-.059c-33.844-.012-63.16-12.164-87.133-36.125-23.976-23.977-36.125-53.309-36.125-87.195 0-33.875 12.149-63.211 36.125-87.184C189.121 12.16 218.438.012 252.293 0h.059c33.875 0 63.21 12.152 87.195 36.125 23.973 23.973 36.125 53.309 36.125 87.184Z"
    />
    <Path
      fill="#6AC982"
      d="M469.168 423.945c0 26.735-8.504 48.379-25.254 64.321C427.359 504.02 405.465 512 378.844 512H132.312c-26.621 0-48.515-7.98-65.058-23.734C50.492 472.312 42 450.672 42 423.945c0-10.281.34-20.453 1.02-30.234.69-10 2.09-20.883 4.152-32.363 2.078-11.575 4.75-22.516 7.95-32.516 3.32-10.352 7.812-20.562 13.37-30.344 5.774-10.152 12.555-18.996 20.156-26.277 7.97-7.621 17.711-13.742 28.973-18.203 11.223-4.438 23.664-6.688 36.977-6.688 5.222 0 10.281 2.137 20.031 8.489 6.101 3.98 13.133 8.511 20.894 13.472 6.704 4.274 15.782 8.281 27.004 11.903 9.864 3.191 19.875 4.972 29.766 5.281 1.09.039 2.18.058 3.269.058 10.985 0 22.094-1.8 33.047-5.339 11.223-3.622 20.313-7.629 27.012-11.903a2660.244 2660.244 0 0 0 20.887-13.461c9.758-6.363 14.808-8.5 20.043-8.5 13.301 0 25.742 2.25 36.972 6.688 11.262 4.461 21.004 10.594 28.965 18.203 7.614 7.281 14.395 16.125 20.164 26.277 5.563 9.789 10.063 19.992 13.371 30.332 3.204 10.012 5.883 20.953 7.961 32.535 2.051 11.493 3.454 22.375 4.141 32.348.691 9.75 1.031 19.922 1.043 30.242Z"
    />
    <Path
      fill="#F5A86C"
      d="M252.352 246.629h-.059V0h.059c33.875 0 63.21 12.152 87.195 36.125 23.973 23.973 36.125 53.309 36.125 87.184 0 33.886-12.152 63.218-36.125 87.195-23.973 23.973-53.309 36.125-87.195 36.125Z"
    />
    <Path
      fill="#5CAB70"
      d="M469.168 423.945c0 26.735-8.504 48.379-25.254 64.321C427.359 504.02 405.465 512 378.844 512H252.293V286.465c1.09.039 2.18.058 3.269.058 10.985 0 22.094-1.8 33.047-5.339 11.223-3.622 20.313-7.629 27.012-11.903a2660.244 2660.244 0 0 0 20.887-13.461c9.758-6.363 14.808-8.5 20.043-8.5 13.301 0 25.742 2.25 36.972 6.688 11.262 4.461 21.004 10.594 28.965 18.203 7.614 7.281 14.395 16.125 20.164 26.277 5.563 9.789 10.063 19.992 13.371 30.332 3.204 10.012 5.883 20.953 7.961 32.535 2.051 11.493 3.454 22.375 4.141 32.348.691 9.75 1.031 19.922 1.043 30.242Z"
    />
  </Svg>
);
export default ProfileFilledIcon;
