import * as React from 'react';
import { View } from 'react-native';
import Markdown from 'react-native-markdown-display';
import { markdownStyle } from '../../../styles/markdown.style';
import { s } from 'react-native-wind';
import { Description } from '../../../components';
import dayjs from 'dayjs';

export enum BubblePosition {
  Left = 'left',
  Right = 'right',
}

const greenBubbleStyle = {
  ...markdownStyle,
  body: {
    ...markdownStyle.body,
    color: '#ffffff',
  },
};

type MessageBubbleProps = {
  position: BubblePosition;
  dateTime?: string;
};

const MessageBubble: React.FC<MessageBubbleProps> = ({
  position,
  dateTime,
  children,
}) => {
  return position === BubblePosition.Left ? (
    <View style={s`w-5/6 bg-card rounded-lg px-1.5`}>
      <Markdown style={markdownStyle}>{children}</Markdown>
      <Description style={[s`text-xs mb-0.5 text-text-secondary`]}>
        {dayjs(dateTime).format('DD/MM/YY HH:mm')}
      </Description>
    </View>
  ) : (
    <View style={s`flex-row justify-end`}>
      <View style={s`w-5/6 bg-primary rounded-lg px-1.5`}>
        <Markdown style={greenBubbleStyle}>{children}</Markdown>
        <Description
          style={[s`text-xs text-right mb-0.5`, { color: '#d0f5d9' }]}
        >
          {dayjs(dateTime).format('DD/MM/YY HH:mm')}
        </Description>
      </View>
    </View>
  );
};

export default MessageBubble;
