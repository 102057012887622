import { s } from 'react-native-wind';
import * as React from 'react';
import { ONBOARDING_ANONYMOUS_VOTE_SCREEN } from '../../navigation/onboarding/onboarding.types';
import { GenericScreenProps } from '../../navigation/core/types';
import {
  Button,
  ButtonType,
  Description,
  Layout,
  Paragraph,
  Title,
} from '../../components';
import { useLanguage } from '../../locale';
import { useBackNavbarAction } from '../../hooks';
import { useDispatch } from 'react-redux';
import { setIsOnboardingFinished } from '../../redux/general/general.slice';
import { useUpdateProfileMutation } from '../../hooks/api/user.api';
import { StyleSheet, Switch, View } from 'react-native';

type AnonymousVoteScreenProps = {} & GenericScreenProps<
  typeof ONBOARDING_ANONYMOUS_VOTE_SCREEN
>;

export const AnonymousVoteScreen: React.FC<AnonymousVoteScreenProps> = () => {
  const [t] = useLanguage();
  const dispatch = useDispatch();
  const backAction = useBackNavbarAction();

  const [isFeedbackOpen, setIsFeedbackOpen] = React.useState<boolean>(false);

  const { mutateAsync: updateProfile } = useUpdateProfileMutation();

  const setOnboardingAsFinished = React.useCallback(() => {
    dispatch(setIsOnboardingFinished(true));
  }, [dispatch]);

  const finish = React.useCallback(async () => {
    await updateProfile({ voteAnonymous: !isFeedbackOpen });
    setOnboardingAsFinished();
  }, [setOnboardingAsFinished, updateProfile, isFeedbackOpen]);

  return (
    <Layout isScrollView={false} leftAction={backAction}>
      <Title>{t('onboarding.anonymousVote.title')}</Title>
      <Description style={s`mt-4`}>
        {t('onboarding.anonymousVote.description1')}
      </Description>
      <Description style={s`mt-2`}>
        {t('onboarding.anonymousVote.description3')}
      </Description>
      <View
        style={s`mt-6 items-center flex-row rounded-2xl bg-card p-4 border border-text-secondary`}
      >
        <Paragraph style={s`flex-1`}>
          {t('onboarding.anonymousVote.switchLabel')}
        </Paragraph>
        <Switch value={isFeedbackOpen} onValueChange={setIsFeedbackOpen} />
      </View>
      <View style={s`flex-1`} />
      <View
        style={[
          s`border-t border-light-grey px-page-padding pt-4 flex-row`,
          styles.footer,
        ]}
      >
        <Button
          onPress={setOnboardingAsFinished}
          buttonType={ButtonType.Tertiary}
          containerStyle={s`mr-4 flex-1`}
        >
          {t('onboarding.anonymousVote.skip')}
        </Button>
        <Button
          onPress={finish}
          containerStyle={s`flex-1`}
          isDisabled={!isFeedbackOpen}
        >
          {t('onboarding.anonymousVote.finish')}
        </Button>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  footer: {
    marginHorizontal: -27,
  },
});
