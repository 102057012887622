import { Alert } from 'react-native';
import { IErrorHandler } from './IErrorHandler';

export class MobileErrorHandler implements IErrorHandler {
  blockInteraction: (message?: string | undefined, cb?: () => void) => void = (
    message,
    cb
  ) => {
    Alert.alert('Error', message, [
      {
        onPress: cb,
        text: 'Ok',
      },
    ]);
  };
}
