import { s } from 'react-native-wind';
import React from 'react';
import { VotingDay } from '../api/team/team.models';
import { View } from 'react-native-animatable';
import { useLanguage } from '../locale';
import { getDayTranslation } from '../utils/miscellaneous';
import { ParagraphBold } from './ParagraphBold';
import { TouchableOpacity } from 'react-native';

const days = [
  VotingDay.Monday,
  VotingDay.Tuesday,
  VotingDay.Wednesday,
  VotingDay.Thursday,
  VotingDay.Friday,
  VotingDay.Saturday,
  VotingDay.Sunday,
];

type ChooseVotingDaysProps = {
  votingDays: VotingDay[];
  onVotingDayPressed?: (selectedVotingDay: VotingDay) => void;
};

export const ChooseVotingDays: React.FC<ChooseVotingDaysProps> = ({
  onVotingDayPressed,
  votingDays,
}) => {
  const [t] = useLanguage();

  return (
    <View style={s`flex-row flex-wrap justify-center`}>
      {days.map((d, i) => {
        const dayText = getDayTranslation(t, d).slice(0, 3);
        const isFirst = i === 0;
        const isSelected = votingDays.includes(d);
        const onPress = () => onVotingDayPressed?.(d);
        return (
          <TouchableOpacity
            key={i}
            onPress={onPress}
            style={s`mt-3 py-2 w-14 rounded-md flex justify-center items-center ${
              !isFirst ? 'ml-3' : ''
            } ${isSelected ? 'bg-secondary' : 'bg-card'}`}
          >
            <ParagraphBold style={s`${isSelected ? 'text-white' : ''}`}>
              {dayText}
            </ParagraphBold>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};
