import './PagerView.css';

import React from 'react';
import {
  PagerViewProps,
  PagerViewOnPageSelectedEvent,
} from 'react-native-pager-view';

export type { PagerViewProps, PagerViewOnPageSelectedEvent };

export const PagerView: React.FC<PagerViewProps> = ({
  children,
  initialPage = 0,
  onPageSelected,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const timer = React.useRef<NodeJS.Timeout>();

  const changePage = React.useCallback(() => {
    const containerEl = containerRef.current;

    if (!containerEl || !onPageSelected) {
      return;
    }

    const event = {
      nativeEvent: {
        position: Math.floor(containerEl.scrollLeft / containerEl.clientWidth),
      },
    } as PagerViewOnPageSelectedEvent;

    onPageSelected(event);
  }, [onPageSelected]);

  React.useEffect(() => {
    const scrollHandler = () => {
      clearTimeout(timer.current);
      timer.current = setTimeout(changePage, 200);
    };

    const containerEl = containerRef.current;
    containerEl?.addEventListener('scroll', scrollHandler);
    return () => containerEl?.removeEventListener('scroll', scrollHandler);
  }, [changePage]);

  React.useEffect(() => {
    const containerEl = containerRef.current;
    containerEl?.scrollTo({ left: initialPage * containerEl.clientWidth });
  }, [initialPage]);

  return (
    <div
      ref={containerRef}
      className="pager-view-container"
      style={{
        height: '100%',
        overflow: 'auto',
        paddingBottom: '50px',
        scrollSnapType: 'x mandatory',
        whiteSpace: 'nowrap',
      }}
    >
      {React.Children.map(children, (child, index) => (
        <div
          style={{
            display: 'inline-block',
            height: '100%',
            scrollSnapAlign: 'start',
            width: '100%',
            ...(index < React.Children.count(children) - 1 && {
              paddingRight: '20px',
            }),
          }}
        >
          {child}
        </div>
      ))}
    </div>
  );
};
