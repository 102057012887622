import { useQueryClient } from '@tanstack/react-query';
import {
  CastFeedbackRequest,
  GetFeedbacksRequest,
  GetUserFeedbacksRequest,
} from '../../api/feedback/feedback.models';
import { useApi } from '../../context/ApiContext/useApi';
import { InvalidationTag, useAppMutation, useAppQuery } from './utils';
import { useBadgeProgress } from '../../context/BadgeProgressContext/useBadgeProgress';

export const useCastFeedback = () => {
  const { feedbackApi } = useApi();
  const { setBadgeProgress } = useBadgeProgress();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: CastFeedbackRequest) => feedbackApi.castFeedback(req),
    {
      onSuccess: (badgeProgress, req) => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.TeamFeedbacks, { teamId: req.teamId }],
        });
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.UserFeedbacks],
        });

        // That's because gamification points are processed in async on the server
        // so if requested immediatly, the same points will be returned
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: [InvalidationTag.Badge],
          });
          queryClient.invalidateQueries({
            queryKey: [InvalidationTag.TotalBadges],
          });
          queryClient.invalidateQueries({
            queryKey: [InvalidationTag.Leaderboard],
          });
          queryClient.invalidateQueries({
            queryKey: [[InvalidationTag.LeaderboardPosition]],
          });
        }, 5000);

        setBadgeProgress(badgeProgress);
      },
    }
  );
  return mutation;
};

export type UseGetFeedbacksRequestParams = Omit<
  GetFeedbacksRequest,
  'teamId'
> & {
  teamId?: string;
};

export const useGetFeedbacks = (
  req: UseGetFeedbacksRequestParams,
  isEnabled = true
) => {
  const { feedbackApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.TeamFeedbacks, req],
    ({ queryKey }) => {
      const { from, teamId, to, userId } =
        queryKey[1] as UseGetFeedbacksRequestParams;
      if (teamId && userId) {
        return feedbackApi.getFeedbacks({
          from,
          to,
          teamId,
          userId,
        });
      }
    },
    { enabled: isEnabled }
  );
  return queryObject;
};

export const useGetUserFeedbacks = (
  req: GetUserFeedbacksRequest,
  isEnabled = true
) => {
  const { feedbackApi } = useApi();

  const queryObject = useAppQuery(
    [InvalidationTag.UserFeedbacks, req],
    ({ queryKey }) => {
      const params = queryKey[1] as GetUserFeedbacksRequest;
      return feedbackApi.getUserFeedbacks(params);
    },
    { enabled: isEnabled }
  );

  return queryObject;
};
