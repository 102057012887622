import { IHttpClient } from '../../services/HttpClient/IHttpClient';
import { IHarmoniaApi } from './IHarmoniaApi';
import {
  GetHarmoniaConfigRequest,
  GetHarmoniaConfigResponse,
  GetThreadRequest,
  GetThreadResponse,
  HarmoniaConfig,
  SetMessageShownRequest,
} from './harmonia.models';

export class HarmoniaApi implements IHarmoniaApi {
  constructor(private readonly httpClient: IHttpClient) {}

  public readonly getHarmoniaConfig = async ({
    companyId,
  }: GetHarmoniaConfigRequest): Promise<HarmoniaConfig> => {
    const res = await this.httpClient.get<GetHarmoniaConfigResponse>(
      `/harmonia/v2/company/${companyId}/config`,
      {
        isAuthorized: true,
      }
    );
    return res.item;
  };

  public readonly getUserThread = async ({
    companyId,
    next,
  }: GetThreadRequest): Promise<GetThreadResponse> => {
    const res = await this.httpClient.get<GetThreadResponse>(
      `/harmonia/v2/company/${companyId}/thread`,
      {
        params: next
          ? {
              next,
            }
          : undefined,
        isAuthorized: true,
      }
    );
    return res;
  };

  public readonly setMessageShown = async ({
    companyId,
  }: SetMessageShownRequest): Promise<void> => {
    await this.httpClient.put<void>(
      `/harmonia/v2/company/${companyId}/message/shown`,
      {
        isAuthorized: true,
      }
    );
  };
}
