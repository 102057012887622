import React from 'react';
import {
  Image,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { Subtitle } from '../../../components';
import { s } from 'react-native-wind';
import slackUmmyIcon from '../../../../assets/slack-ummy.png';
import slackIcon from '../../../../assets/slack.png';
import { useLanguage } from '../../../locale';
import { Config } from '../../../config';

interface SlackErrorProps {
  error: string;
}

const SlackErrorFallback = ({ error }: SlackErrorProps) => {
  const [t] = useLanguage();
  return (
    <View style={s`flex flex-col justify-center h-full`}>
      <Image style={styles.image} source={slackUmmyIcon} />
      <Subtitle>{t('slack.errorFallback.title')}</Subtitle>
      <Text style={s`my-3 flex-1 text-text-secondary`}>{error}</Text>

      <TouchableOpacity onPress={() => Linking.openURL(Config.slackUrl)}>
        <View
          style={s`flex flex-row items-center  bg-slack border rounded-full p-4 justify-center`}
        >
          <Image style={styles.icon} source={slackIcon} />
          <Text style={s`font-bold text-md text-white`}>
            {t('slack.errorFallback.cta')}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 180,
    height: 180,
    alignSelf: 'center',
    marginBottom: '20%',
    marginTop: '20%',
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
});

export default SlackErrorFallback;
