import { s } from 'react-native-wind';
import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native';
import { Config } from '../config';

type SubtitleProps = {
  children: ReactNode;
  style?: StyleProp<TextStyle>;
};

export const Subtitle: React.FC<SubtitleProps> = ({ children, style }) => {
  const subtitleStyle = StyleSheet.flatten([styles.text, style]);
  return <Text style={subtitleStyle}>{children}</Text>;
};

const styles = StyleSheet.create({
  text: {
    fontFamily: Config.fontMedium,
    ...s`text-xl text-text-primary`,
  },
});
