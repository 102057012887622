import * as React from 'react';
import Svg, { SvgProps, Path, ClipPath, Defs, G } from 'react-native-svg';
import { IconProps } from './types';

type GiftEmptyIconProps = IconProps & SvgProps;

const GiftEmptyIcon = ({
  width,
  height,
  size,
  ...props
}: GiftEmptyIconProps) => (
  <Svg
    viewBox="0 0 512 512"
    width={width || size}
    height={height || size}
    fill="none"
    {...props}
  >
    <G clipPath="url(#a)">
      <Path
        fill="#FBE77B"
        d="M214.374 64.52S149.854 0 74.927 0C31.22 0 14.57 29.138 14.57 58.276s17.691 58.276 49.951 58.276h149.854V64.52Z"
      />
      <Path
        fill="#C2C2C2"
        d="M214.374 64.52S149.854 0 74.927 0C31.22 0 14.57 29.138 14.57 58.276s17.691 58.276 49.951 58.276h149.854V64.52ZM297.626 64.52S362.146 0 437.073 0c43.707 0 60.358 29.138 60.358 58.276s-17.691 58.276-49.951 58.276H297.626V64.52Z"
      />
      <Path
        fill="#646D73"
        d="M492 199.805H20c-11.046 0-20-8.954-20-20v-43.252c0-11.046 8.954-20 20-20h472c11.046 0 20 8.954 20 20v43.252c0 11.046-8.954 20-20 20Z"
      />
      <Path
        fill="#474F54"
        d="M484.943 116.553v83.252h6.453c11.379 0 20.604-9.225 20.604-20.604v-42.045c0-11.379-9.225-20.604-20.604-20.604l-6.453.001Z"
      />
      <Path
        fill="#C2C2C2"
        d="M193.561 116.553h124.878v83.252H193.561v-83.252Z"
      />
      <Path
        fill="#646D73"
        d="M464.943 512H47.057c-11.046 0-20-8.954-20-20V199.805h457.886V492c0 11.046-8.954 20-20 20Z"
      />
      <Path fill="#474F54" d="M27.057 199.805h457.886v24.976H27.057v-24.976Z" />
      <Path fill="#C2C2C2" d="M214.374 199.805h83.252V512h-83.252V199.805Z" />
      <Path
        fill="#A3A3A3"
        d="M214.374 199.805h83.252v24.976h-83.252v-24.976ZM326.764 95.74c-4.333 0-8.045-2.65-9.611-6.416 2.685-.986 5.58-1.951 8.585-2.798a7.728 7.728 0 0 0-4.192-14.874c-1.773.5-3.505 1.035-5.189 1.589V48.393c-11.859 9.256-18.732 16.127-18.732 16.127v52.033H447.48c17.376 0 30.516-8.46 38.951-20.813H326.764ZM195.642 48.393V73.24a125.883 125.883 0 0 0-5.189-1.59 7.727 7.727 0 0 0-4.192 14.874c2.998.846 5.895 1.813 8.584 2.8-1.566 3.766-5.278 6.414-9.61 6.414H25.569c8.435 12.353 21.575 20.813 38.951 20.813h149.854V64.52s-6.873-6.871-18.732-16.127Z"
      />
      <Path
        fill="#C2C2C2"
        d="M297.626 116.553h-83.252V63.951c0-7.732 6.268-14 14-14h55.252c7.732 0 14 6.268 14 14v52.602Z"
      />
      <Path fill="#A3A3A3" d="M214.374 95.74h83.252v20.813h-83.252V95.74Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h512v512H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default GiftEmptyIcon;
