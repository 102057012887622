import AsyncStorage from '@react-native-async-storage/async-storage';
import { IStorageProvider, StorageKeys } from './IStorageProvider';

export class MobileStorageProvider implements IStorageProvider {
  getItem: (key: StorageKeys) => Promise<string | null> = (key) => {
    return AsyncStorage.getItem(key);
  };
  setItem: (key: StorageKeys, value: string) => Promise<void> = (key, val) => {
    return AsyncStorage.setItem(key, val);
  };
  removeItem: (key: StorageKeys) => Promise<void> = (key) => {
    return AsyncStorage.removeItem(key);
  };
  getItemValue: <T>(key: StorageKeys) => Promise<T | null> = async (key) => {
    try {
      const content = await this.getItem(key);
      return content ? JSON.parse(content) : null;
    } catch (e) {
      return null;
    }
  };
  setItemValue: <T = unknown>(key: StorageKeys, val: T) => Promise<void> = (
    key,
    val
  ) => {
    return AsyncStorage.setItem(key, JSON.stringify(val));
  };
}
