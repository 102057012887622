import { s } from 'react-native-wind';
import React, { useMemo, useState } from 'react';
import {
  Image,
  ImageStyle,
  StyleProp,
  StyleSheet,
  TextProps,
  View,
  ViewStyle,
} from 'react-native';
import { Config } from '../config';
import uuid from 'react-native-uuid';
import { ParagraphBold } from './ParagraphBold';

type ProfileImageProps = {
  userId: string;
  username: string;
  imageStyle?: StyleProp<ImageStyle>;
  placeholderStyle?: StyleProp<ViewStyle>;
  letterStyle?: StyleProp<TextProps>;
  src?: string;
};

export const ProfileImage: React.FC<ProfileImageProps> = ({
  src,
  imageStyle,
  placeholderStyle,
  letterStyle,
  userId,
  username,
}) => {
  const [isProfileImageExist, setIsProfileImageExist] = useState<boolean>(true);

  const imageUuid = useMemo(() => {
    uuid.v4() as string;
  }, []);

  return !isProfileImageExist ? (
    <View
      style={[
        s`rounded-full bg-light-green justify-center items-center`,
        styles.profileImage,
        placeholderStyle,
      ]}
    >
      <ParagraphBold style={[s`text-4xl text-primary mt-1`, letterStyle]}>
        {username.charAt(0).toUpperCase()}
      </ParagraphBold>
    </View>
  ) : (
    <Image
      source={{
        uri: src ?? `${Config.profileImageBaseUrl}/${userId}?id=${imageUuid}`,
        cache: 'reload',
      }}
      style={[
        s`rounded-full bg-light-green justify-center items-center`,
        styles.profileImage,
        imageStyle,
      ]}
      onError={() => setIsProfileImageExist(false)}
    />
  );
};

const styles = StyleSheet.create({
  profileImage: {
    aspectRatio: 1,
  },
});
