import { s } from 'react-native-wind';
import React, { useMemo } from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { ParagraphBold } from './ParagraphBold';

export enum BadgeType {
  Primary = 'primary',
  Secondary = 'secondary',
}

type BadgeProps = {
  badgeType: BadgeType;
  label: string;
  isSelected?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  onPress?: () => void;
};

export const Badge: React.FC<BadgeProps> = ({
  badgeType,
  label,
  isSelected,
  containerStyle = {},
  onPress,
}) => {
  const style = useMemo(() => {
    if (isSelected && BadgeType.Primary === badgeType) {
      return {
        container: s`bg-primary`,
        text: s`text-white`,
      };
    } else if (isSelected && BadgeType.Secondary === badgeType) {
      return {
        container: s`bg-secondary`,
        text: s`text-white`,
      };
    } else {
      return {
        container: s`bg-card`,
        text: s`text-text-primary`,
      };
    }
  }, [badgeType, isSelected]);

  return (
    <TouchableOpacity
      disabled={!onPress}
      onPress={onPress}
      style={[s`rounded-full px-4 py-1`, style.container, containerStyle]}
    >
      <ParagraphBold style={style.text}>{label}</ParagraphBold>
    </TouchableOpacity>
  );
};
