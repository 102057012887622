import { createNotImplementedMethod } from '../utils';
import {
  Company,
  GetMyCompaniesRequest,
  SearchCompanyUsersRequest,
  SearchCompanyUsersResponse,
} from './companies.models';

export interface ICompanyApi {
  readonly searchUsers: (
    req: SearchCompanyUsersRequest
  ) => Promise<SearchCompanyUsersResponse>;
  readonly getMyCompanies: (req: GetMyCompaniesRequest) => Promise<Company[]>;
}

export class NotImplementedCompanyApi implements ICompanyApi {
  public readonly searchUsers = createNotImplementedMethod();
  public readonly getMyCompanies = createNotImplementedMethod();
}
