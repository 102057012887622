import { s } from 'react-native-wind';
import * as React from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import {
  ParagraphSmall,
  Subtitle,
  UmFace,
  UmFeedbackCategory,
} from '../../../components';
import { Feedback } from '../../../api/feedback/feedback.models';
import { Team } from '../../../api/team/team.models';
import { useLanguage } from '../../../locale';
import { Image } from 'expo-image';
import { useCategories } from '../../../hooks/useCategories';

type AlreadyVotedTeamCardProps = {
  containerStyle?: StyleProp<ViewStyle>;
  feedback: Feedback;
  team: Team;
  onCardPress?: () => void;
};

export const AlreadyVotedTeamCard: React.FC<AlreadyVotedTeamCardProps> = ({
  containerStyle,
  feedback,
  onCardPress,
  team,
}) => {
  const [t] = useLanguage();
  const categories = useCategories();

  return (
    <TouchableOpacity
      onPress={onCardPress}
      disabled={!onCardPress}
      style={[
        s`border border-text-secondary rounded-2xl p-4 bg-card`,
        containerStyle,
      ]}
    >
      <View style={s`flex-row justify-between items-center`}>
        <View>
          <Subtitle>{team.name}</Subtitle>
          <ParagraphSmall style={s`text-text-secondary`}>
            {feedback.voteAnonymous
              ? t('feedback.anonymous')
              : t('feedback.named')}
          </ParagraphSmall>
        </View>
        <UmFace
          containerStyle={s`ml-2`}
          isFixedSize
          value={feedback.value}
          isSelected
          isTouchable={false}
        />
      </View>
      {feedback.categoryFeedback &&
      Object.keys(feedback.categoryFeedback).length > 0 ? (
        <>
          <View style={s`flex-row flex-wrap mt-4`}>
            {Object.keys(feedback.categoryFeedback).map((c) => {
              const category = categories.find(
                (companyCategory) => c === companyCategory.id
              );
              return (
                <UmFeedbackCategory
                  key={`feedback-category-${team.id}-${c}`}
                  icon={
                    category ? (
                      <Image
                        style={s`w-6 h-6`}
                        source={category.icon.light}
                        contentFit="cover"
                        transition={300}
                      />
                    ) : (
                      <></>
                    )
                  }
                  value={feedback.categoryFeedback[c]}
                  containerStyle={s`w-1/6 px-1`}
                  iconContainerStyle={s`w-8 h-8`}
                  isSelected={true}
                />
              );
            })}
          </View>
        </>
      ) : null}
      {feedback.comment ? (
        <ParagraphSmall style={s`mt-4`} numberOfLines={3}>
          {feedback.comment}
        </ParagraphSmall>
      ) : null}
    </TouchableOpacity>
  );
};
