import { s } from 'react-native-wind';
import React from 'react';
import { View } from 'react-native';
import { ParagraphBold, ParagraphSmall } from '../../../components';
import { ProfileImage } from '../../../components/ProfileImage';

type LeaderboardPositionProps = {
  position: number | string;
  userId: string;
  username: string;
  points?: number;
};

export const LeaderboardPosition: React.FC<LeaderboardPositionProps> = ({
  points,
  position,
  userId,
  username,
}) => {
  return (
    <View style={s`flex-row items-center`}>
      <ParagraphBold style={s`w-10 mr-3`}>{position}</ParagraphBold>
      <View style={s`rounded-full flex-row flex-1 items-center bg-card h-12`}>
        <ProfileImage
          userId={userId}
          username={username}
          letterStyle={s`text-xl`}
          placeholderStyle={s`h-full border-2 border-primary`}
          imageStyle={s`h-full border-2 border-primary`}
        />
        <ParagraphSmall numberOfLines={1} style={s`mx-2 flex-1`}>
          {username}
        </ParagraphSmall>
        <ParagraphBold style={s`mr-4 text-primary`}>{points}</ParagraphBold>
      </View>
    </View>
  );
};
