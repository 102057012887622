import { createNotImplementedMethod } from '../utils';
import {
  BadgeProgress,
  CastFeedbackRequest,
  Feedback,
  GetFeedbacksRequest,
  GetUserFeedbacksRequest,
} from './feedback.models';

export interface IFeedbackApi {
  readonly castFeedback: (req: CastFeedbackRequest) => Promise<BadgeProgress>;
  readonly getFeedbacks: (req: GetFeedbacksRequest) => Promise<Feedback[]>;
  readonly getUserFeedbacks: (
    req: GetUserFeedbacksRequest
  ) => Promise<Feedback[]>;
}

export class NotImplementedFeedbackApi implements IFeedbackApi {
  public readonly castFeedback = createNotImplementedMethod();
  public readonly getFeedbacks = createNotImplementedMethod();
  public readonly getUserFeedbacks = createNotImplementedMethod();
}
