import { s } from 'react-native-wind';
import React from 'react';
import { Description, Layout, ParagraphBold } from '../../components';
import { GenericScreenProps } from '../../navigation/core/types';
import { AUTH_CODE_SCREEN } from '../../navigation/authentication/authentication.types';
import { useBackNavbarAction } from '../../hooks';
import { useLanguage } from '../../locale';
import { useAuthCodeController } from './controllers/useAuthCodeController';
import { CodeInput } from '../../portability/components/CodeInput/CodeInput';

type AuthCodeScreenProps = {} & GenericScreenProps<typeof AUTH_CODE_SCREEN>;

export const AuthCodeScreen: React.FC<AuthCodeScreenProps> = ({ route }) => {
  const [t] = useLanguage();

  const backAction = useBackNavbarAction();
  const { resendEmail } = useAuthCodeController();

  return (
    <Layout
      leftAction={backAction}
      title={t('authCode.title')}
      showSeparator={false}
      description={
        <>
          <Description style={s`font-medium`}>
            {t('authCode.description', {
              email: route.params.email,
            })}
          </Description>{' '}
          <ParagraphBold onPress={resendEmail} style={s`text-secondary`}>
            {t('authCode.resendCode')}
          </ParagraphBold>
        </>
      }
    >
      <CodeInput />
    </Layout>
  );
};
