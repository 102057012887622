export enum StorageKeys {
  ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY',
  REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY',
  SELECTED_LANGUAGE = 'SELECTED_LANGUAGE',
  TREES_KEY = 'TREES_KEY',
  ADVICE_SHEET_SHOWN = 'ADVICE_SHEET_SHOWN',
  SHOULD_REDIRECT_TO_SLACK = 'SHOULD_REDIRECT_TO_SLACK',
}

export interface IStorageProvider {
  readonly getItem: (key: StorageKeys) => Promise<string | null>;
  readonly setItem: (key: StorageKeys, value: string) => Promise<void>;
  readonly removeItem: (key: StorageKeys) => Promise<void>;
  readonly getItemValue: <T = unknown>(key: StorageKeys) => Promise<T | null>;
  readonly setItemValue: <T = unknown>(
    key: StorageKeys,
    value: T
  ) => Promise<void>;
}

export class NotImplementedStorageProvider implements IStorageProvider {
  public readonly getItem = () => {
    throw new Error('Not implemented');
  };
  public readonly setItem = () => {
    throw new Error('Not implemented');
  };
  public readonly removeItem = () => {
    throw new Error('Not implemented');
  };
  public readonly getItemValue = () => {
    throw new Error('Not implemented');
  };
  public readonly setItemValue = () => {
    throw new Error('Not implemented');
  };
}
