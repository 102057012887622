import { customize } from 'react-native-wind';

customize({
  theme: {
    colors: {
      primary: '#6AC982',
      'primary-light': '#E0EFE6',
      secondary: '#EFC319',
      'secondary-light': '#FDF9E8',
      sad: '#F19E70',
      warning: '#F7CE46',
      happy: '#CFDC8D',
      card: '#F4F5F7',
      danger: '#D14941',
      'red-light': '#FCEDED',
      'red-vivid': '#F75255',
      'text-primary': '#313131',
      'text-secondary': '#A3A3A3',
      'very-sad': '#BC4027',
      'fake-white': '#F8F8F8',
      'light-grey': '#E8E8E8',
      'light-green': '#E5F9ED',
      slack: '#4A154B',
    },
    spacing: {
      'page-padding': 27,
    },
  },
});
