import * as React from 'react';
import { View, TouchableOpacity, Image, Text, StyleSheet } from 'react-native';
import { ArrowRightIcon } from 'react-native-heroicons/outline';
import { s } from 'react-native-wind';
import { Paragraph, ParagraphSmall } from '../../../components';
import { Config } from '../../../config';
import { useLanguage } from '../../../locale';
import { usePersonalSectionController } from '../controllers/usePersonalSection.controller';
import { TreeBadgeIcon } from '../../../icons';
import { ALL_BADGES_SCREEN } from '../../../navigation/authenticated/authenticated.types';
import { useNavigation } from '@react-navigation/native';
import { AppNavigation } from '../../../navigation/core/types';
import { GAME_SCREEN } from '../../../navigation/authenticatedWithBottomTab/authenticated.types';

type PersonalGamificationSectionProps = {};

const PersonalGamificationSection: React.FC<
  PersonalGamificationSectionProps
> = () => {
  const navigation = useNavigation<AppNavigation<typeof GAME_SCREEN>>();
  const [t] = useLanguage();

  const { currentImage, currentPoints, targetPoints, otherBadgesExist } =
    usePersonalSectionController();

  const percentage = React.useMemo(() => {
    if (currentPoints === undefined || targetPoints === undefined) return null;
    return (currentPoints / targetPoints) * 100;
  }, [currentPoints, targetPoints]);

  return (
    <>
      {otherBadgesExist || true ? (
        <View style={s`flex-row justify-end`}>
          <TouchableOpacity
            style={s`flex-row items-center`}
            onPress={() => navigation.navigate(ALL_BADGES_SCREEN)}
          >
            <Paragraph style={s`text-primary mr-1`}>
              {t('gamification.myBadge')}
            </Paragraph>
            <ArrowRightIcon color={Config.colorPrimary} />
          </TouchableOpacity>
        </View>
      ) : null}
      {percentage !== null ? (
        <View style={s`mt-4 flex-row items-center`}>
          <View style={s`flex-1`}>
            <View style={s`w-full h-2 border border-primary rounded-full`}>
              <View
                style={[s`h-full bg-primary`, { width: `${percentage}%` }]}
              />
            </View>
            <View style={s`mt-1`}>
              <ParagraphSmall>
                Punti:{' '}
                <Text style={styles.bold}>
                  {`${currentPoints}`} / {`${targetPoints}`}
                </Text>
              </ParagraphSmall>
            </View>
          </View>
          <View
            style={s`rounded-full border-2 border-primary bg-primary-light h-12 w-12 ml-4 items-center justify-center`}
          >
            <TreeBadgeIcon size={24} />
          </View>
        </View>
      ) : null}
      <View style={s`flex-1 items-center justify-end`}>
        <Image style={s`h-full w-full resize-contain`} source={currentImage} />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  bold: {
    fontFamily: Config.fontSemibold,
  },
});

export default PersonalGamificationSection;
