import * as React from 'react';
import { View } from 'react-native';
import { s } from 'react-native-wind';
import CatEmptyStateImage from '../../../../icons/CatEmptyStateImage';
import { Paragraph } from '../../../components';
import { useLanguage } from '../../../locale';

type NoChallengeSectionProps = {};

const NoChallengeSection: React.FC<NoChallengeSectionProps> = () => {
  const [t] = useLanguage();

  return (
    <View style={s`flex-1 items-center justify-center`}>
      <CatEmptyStateImage height={200} width={'100%'} />
      <Paragraph style={s`text-center mt-4`}>
        {t('gamification.companySection.noData')}
      </Paragraph>
    </View>
  );
};

export default NoChallengeSection;
