import React from 'react';
import type { BottomSheetViewProps } from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheetView/types';

export type { BottomSheetViewProps };

export const BottomSheetView: React.FC<BottomSheetViewProps> = ({
  children,
}) => {
  return <>{children}</>;
};
