import { createSlice } from '@reduxjs/toolkit';
import { GeneralState } from './general.models';
import {
  addFullPageLoaderHandler,
  addLoaderHandler,
  removeLoaderHandler,
  removeFullPageLoaderHandler,
  setIsAuthorizedHandler,
  setIsInitializedHandler,
  setIsInErrorHandler,
  setIsOnboardingFinishedHandler,
  setIsSummaryHandler,
  setSelectedTeamHandler,
  setSelectedCompanyHandler,
  setIsNoNetworkMomentHandler,
} from './general.reducer';

export const generalInitialState: GeneralState = {
  fullPageLoaders: [],
  isInError: false,
  loaders: [],
  isAuthorized: false,
  isInitialized: false,
  isOnboardingFinished: false,
  isSummarySelected: true,
  selectedTeam: null,
  selectedCompany: null,
  isNoNetworkMoment: false,
};

const GENERAL_SLICE = 'generalSlice';

const resetGeneralToDefaultHandler = () => {
  return {
    ...generalInitialState,
    isInitialized: true,
  };
};

const generalSlice = createSlice({
  name: GENERAL_SLICE,
  initialState: generalInitialState,
  reducers: {
    addFullPageLoader: addFullPageLoaderHandler,
    removeFullPageLoader: removeFullPageLoaderHandler,
    addLoader: addLoaderHandler,
    removeLoader: removeLoaderHandler,
    setIsAuthorized: setIsAuthorizedHandler,
    setIsOnboardingFinished: setIsOnboardingFinishedHandler,
    setIsInitialized: setIsInitializedHandler,
    setIsInError: setIsInErrorHandler,
    setIsSummary: setIsSummaryHandler,
    setSelectedTeam: setSelectedTeamHandler,
    setSelectedCompany: setSelectedCompanyHandler,
    resetGeneralToDefault: resetGeneralToDefaultHandler,
    setIsNoNetworkMoment: setIsNoNetworkMomentHandler,
  },
});

export const {
  addFullPageLoader,
  removeFullPageLoader,
  addLoader,
  removeLoader,
  setIsAuthorized,
  setIsInitialized,
  setIsInError,
  resetGeneralToDefault,
  setIsOnboardingFinished,
  setIsSummary,
  setSelectedTeam,
  setSelectedCompany,
  setIsNoNetworkMoment,
} = generalSlice.actions;
export const generalReducer = generalSlice.reducer;
