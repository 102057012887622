import { s } from 'react-native-wind';
import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle } from 'react-native';
import { Config } from '../config';

type ParagraphSmallProps = {
  children: string | React.ReactNode;
  numberOfLines?: number;
  style?: StyleProp<TextStyle>;
  onPress?: () => void;
};

export const ParagraphSmall: React.FC<ParagraphSmallProps> = ({
  children,
  style,
  numberOfLines,
  onPress,
}) => {
  const paragraphStyle = StyleSheet.flatten([styles.text, style]);
  return (
    <Text
      numberOfLines={numberOfLines}
      onPress={onPress}
      style={paragraphStyle}
    >
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: Config.fontRegular,
    ...s`text-sm text-text-primary`,
  },
});
