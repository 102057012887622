import { s } from 'react-native-wind';
import React from 'react';
import {
  Button,
  ButtonType,
  Paragraph,
  Subtitle,
  UmModal,
} from '../../../components';
import { useLanguage } from '../../../locale';

type ProfileDeleteConfirmModalProps = {
  isOpen: boolean;
  onCloseModal: () => void;
};

export const ProfileDeleteConfirmModal: React.FC<
  ProfileDeleteConfirmModalProps
> = ({ isOpen, onCloseModal }) => {
  const [t] = useLanguage();

  return (
    <UmModal isOpen={isOpen}>
      <Subtitle>{t('profile.confirmModal.title')}</Subtitle>
      <Paragraph style={s`mt-4`}>
        {t('profile.confirmModal.description')}
      </Paragraph>
      <Button
        onPress={onCloseModal}
        buttonType={ButtonType.Primary}
        containerStyle={s`mt-8 w-full`}
      >
        {t('profile.confirmModal.ok')}
      </Button>
    </UmModal>
  );
};
