import * as React from 'react';
import { BottomTab, TabRoute } from '../core/types';
import {
  AuthenticatedBottomTabPages,
  FEEDBACKS_SCREEN,
  GAME_SCREEN,
  PROFILE_SCREEN,
  STATS_SCREEN,
  TEAM_SETTINGS_SCREEN,
} from './authenticated.types';
import { createBottomTab } from '../core/createBottomTab';
import { ProfileScreen } from '../../screens/profile/profile.screen';
import StatsScreen from '../../screens/stats/stats.screen';
import FeedbacksScreens from '../../screens/feedback/feedbacks.screen';
import { TeamSettingsScreen } from '../../screens/teamSettings/teamSettings.screen';
import {
  ChartEmptyIcon,
  ChartFilledIcon,
  GiftEmptyIcon,
  GiftFilledIcon,
  LikeEmptyIcon,
  LikeFilledIcon,
  ProfileEmptyIcon,
  ProfileFilledIcon,
  SettingsEmptyIcon,
  SettingsFilledIcon,
} from '../../icons';
import GamificationScreen from '../../screens/gamification/gamification.screen';

export const authenticatedBottomTabRoutes: TabRoute<AuthenticatedBottomTabPages>[] =
  [
    {
      name: STATS_SCREEN,
      component: StatsScreen,
      focusedTabBarIcon: <ChartFilledIcon size={24} />,
      notFocusedTabBarIcon: <ChartEmptyIcon size={24} />,
      tabBarLabel: 'bottomTab.stats',
    },
    {
      name: FEEDBACKS_SCREEN,
      component: FeedbacksScreens,
      focusedTabBarIcon: <LikeFilledIcon size={24} />,
      notFocusedTabBarIcon: <LikeEmptyIcon size={24} />,
      tabBarLabel: 'bottomTab.feedback',
    },
    {
      name: GAME_SCREEN,
      component: GamificationScreen,
      focusedTabBarIcon: <GiftFilledIcon size={24} />,
      notFocusedTabBarIcon: <GiftEmptyIcon size={24} />,
      tabBarLabel: 'bottomTab.progress',
    },
    {
      name: TEAM_SETTINGS_SCREEN,
      component: TeamSettingsScreen,
      focusedTabBarIcon: <SettingsFilledIcon size={24} />,
      notFocusedTabBarIcon: <SettingsEmptyIcon size={24} />,
      tabBarLabel: 'bottomTab.teamSettings',
    },
    {
      name: PROFILE_SCREEN,
      focusedTabBarIcon: <ProfileFilledIcon size={24} />,
      notFocusedTabBarIcon: <ProfileEmptyIcon size={24} />,
      component: ProfileScreen,
      tabBarLabel: 'bottomTab.profile',
    },
  ];

export const authenticatedBottomTab: BottomTab<AuthenticatedBottomTabPages> = {
  routes: authenticatedBottomTabRoutes,
  initialScreen: STATS_SCREEN,
};

export const AuthenticatedBottomTab = createBottomTab(authenticatedBottomTab);
