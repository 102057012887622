import React, { useMemo } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { CardStyleInterpolators } from '@react-navigation/stack';
import { ScreensProps, Stack } from './types';

export type StackProps = {
  initialScreen?: string;
};

export const createStack =
  <T extends keyof ScreensProps>(stack: Stack<T>) =>
  () => {
    const StackNav = createNativeStackNavigator();

    const navigatiorOptions = useMemo(() => {
      return {
        headerShown: false,
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      };
    }, []);

    return (
      <StackNav.Navigator
        initialRouteName={stack.initialScreen}
        screenOptions={navigatiorOptions}
      >
        {stack.routes.map(({ name, component, defaultParams }) => (
          <StackNav.Screen
            key={name}
            name={name}
            component={component}
            initialParams={defaultParams}
          />
        ))}
      </StackNav.Navigator>
    );
  };
