import React, { createContext, useMemo } from 'react';
import { MobileErrorHandler } from '../../services/ErrorHandler/ErrorHandler';
import {
  IErrorHandler,
  NotImplementedErrorHandler,
} from '../../services/ErrorHandler/IErrorHandler';

export type ErrorHandlerContextProps = {
  errorHandler: IErrorHandler;
};

export const ErrorHandlerContext = createContext<ErrorHandlerContextProps>({
  errorHandler: new NotImplementedErrorHandler(),
});

export const ErrorHandlerProvider: React.FC = ({ children }) => {
  const errorHandler = useMemo(() => {
    return new MobileErrorHandler();
  }, []);

  return (
    <ErrorHandlerContext.Provider value={{ errorHandler }}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};
