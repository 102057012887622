import { StyleSheet } from 'react-native';
import { Config } from '../config';

export const fontsStyles = StyleSheet.create({
  light: {
    fontFamily: Config.fontLight,
  },
  regular: {
    fontFamily: Config.fontRegular,
  },
  medium: {
    fontFamily: Config.fontMedium,
  },
  semibold: {
    fontFamily: Config.fontSemibold,
  },
});
