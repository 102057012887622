import { s } from 'react-native-wind';
import * as React from 'react';
import { Calendar, DateData } from 'react-native-calendars';
import { Config } from '../../../config';
import { DayProps } from 'react-native-calendars/src/calendar/day';
import { CalendarDay, Subtitle } from '../../../components';
import { Feedback } from '../../../api/feedback/feedback.models';
import { useLanguage } from '../../../locale';
import { useTeamCalendarSectionController } from '../controllers/useTeamCalendarSectionController';

type TeamCalendarSectionProps = {
  userId?: string;
  teamId?: string;
  onDayPress: (feedbacks: Feedback[]) => void;
  isSummary: boolean;
};

export const TeamCalendarSection: React.FC<TeamCalendarSectionProps> = ({
  teamId,
  userId,
  onDayPress,
  isSummary,
}) => {
  const [t] = useLanguage();

  const { monthFeedbacks, setMonth } = useTeamCalendarSectionController({
    teamId,
    userId,
    isSummary,
  });

  const renderDay: React.FunctionComponent<
    DayProps & { date?: DateData | undefined }
  > = React.useCallback(
    ({ date, state }) => (
      <CalendarDay
        onDayClick={onDayPress}
        date={date}
        state={state}
        monthFeedbacks={monthFeedbacks}
      />
    ),
    [monthFeedbacks, onDayPress]
  );

  return (
    <>
      <Subtitle style={s`mb-4`}>{t('stats.moodCalendar')}</Subtitle>
      <Calendar
        onMonthChange={setMonth}
        theme={{
          arrowColor: Config.colorSecondary,
          arrowHeight: 20,
          arrowWidth: 20,
        }}
        dayComponent={renderDay}
      />
    </>
  );
};
