import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { Config } from '../config';

type CrownIconProps = {
  size?: number;
  color?: string;
};

export const CrownIcon: React.FC<CrownIconProps> = ({ color, size }) => {
  return (
    <MaterialCommunityIcons
      name="crown"
      size={size ?? 24}
      color={color ?? Config.colorSecondary}
    />
  );
};
