import { s } from 'react-native-wind';
import React, { memo, useMemo } from 'react';
import { Image, StyleProp, TouchableOpacity, ViewProps } from 'react-native';
import { FeedbackValue } from '../api/feedback/feedback.models';

import veryHappyFaceImageEmpty from '../../assets/very-happy-empty.png';
import veryHappyFaceImage from '../../assets/very-happy-face.png';
import happyFaceImageEmpty from '../../assets/happy-empty.png';
import happyFaceImage from '../../assets/happy-face.png';
import sadFaceImageEmpty from '../../assets/sad-empty.png';
import sadFaceImage from '../../assets/sad-face.png';
import verySadFaceImageEmpty from '../../assets/very-sad-empty.png';
import verySadFaceImage from '../../assets/very-sad-face.png';

type UmFaceProps = {
  value: FeedbackValue;
  isSelected: boolean;
  isFixedSize: boolean;
  isTouchable?: boolean;
  containerStyle?: StyleProp<ViewProps>;
  onPress?: () => void;
};

export const UmFace: React.FC<UmFaceProps> = memo(
  ({
    isSelected,
    isTouchable = true,
    isFixedSize,
    value,
    onPress,
    containerStyle,
  }) => {
    const image = useMemo(() => {
      if (isSelected) {
        switch (value) {
          case 1:
            return verySadFaceImage;
          case 2:
            return sadFaceImage;
          case 3:
            return happyFaceImage;
          case 4:
            return veryHappyFaceImage;
        }
      }

      switch (value) {
        case 1:
          return verySadFaceImageEmpty;
        case 2:
          return sadFaceImageEmpty;
        case 3:
          return happyFaceImageEmpty;
        case 4:
          return veryHappyFaceImageEmpty;
      }
    }, [isSelected, value]);

    return (
      <TouchableOpacity
        style={[containerStyle, { aspectRatio: 1 }]}
        onPress={onPress}
        disabled={!isTouchable}
      >
        <Image
          style={[
            s`${isFixedSize ? 'w-12 h-12' : 'w-full h-full'} resize-contain`,
          ]}
          source={image}
        />
      </TouchableOpacity>
    );
  }
);
