import * as React from 'react';
import { GenericScreenProps } from '../../navigation/core/types';
import { HARMONIA_ASSISTANT_SCREEN } from '../../navigation/authenticated/authenticated.types';
import { ActivityIndicator, FlatList, Image, View } from 'react-native';
import { Layout, ParagraphBold, ParagraphSmall } from '../../components';
import { useBackNavbarAction } from '../../hooks';
import { s } from 'react-native-wind';
import HarmoniaBottomBar from './components/HarmoniaBottomBar';
import MessageBubble, { BubblePosition } from './components/MessageBubble';
import { useHarmoniaAssistant } from './controllers/harmoniaAssistant.controller';
import { HarmoniaMessageAuthor } from '../../api/harmonia/harmonia.models';
import { Config } from '../../config';
import conversationLoader from '../../../assets/loader-conversation.gif';
import emptyStateImage from '../../../assets/empty-state-conversation.png';
import { useLanguage } from '../../locale';

type HarmoniaAssistantScreenProps = {} & GenericScreenProps<
  typeof HARMONIA_ASSISTANT_SCREEN
>;

const HarmoniaAssistantScreen: React.FC<HarmoniaAssistantScreenProps> = () => {
  const [t] = useLanguage();
  const scrollViewRef = React.useRef<FlatList>(null);
  const back = useBackNavbarAction();

  const {
    areMessagesLoading,
    loadPrevMessages,
    isListEmpty,
    isWaitingMessage,
    messagesToShow,
    newMessageText,
    sendHarmoniaMessage,
    setNewMessageText,
    resetScrollToEnd,
    shouldScrollToEnd,
  } = useHarmoniaAssistant();

  React.useEffect(() => {
    if (shouldScrollToEnd) {
      scrollViewRef.current?.scrollToIndex({ animated: true, index: 0 });
      resetScrollToEnd();
    }
  }, [resetScrollToEnd, shouldScrollToEnd]);

  return (
    <Layout
      leftAction={back}
      isScrollView={false}
      bottomBar={
        <HarmoniaBottomBar
          isSendDisabled={isWaitingMessage}
          text={newMessageText}
          setText={setNewMessageText}
          sendMessage={sendHarmoniaMessage}
        />
      }
      containerStyle={s`py-0 px-0`}
    >
      <FlatList
        inverted
        data={messagesToShow}
        renderItem={({ index: i, item: m }) => {
          const isViewed = m.viewedAt;
          const nextMessage = messagesToShow[i + 1];
          let notViewedComponent: null | React.ReactNode = null;

          if (nextMessage && nextMessage.viewedAt && !isViewed) {
            notViewedComponent = (
              <View style={s`my-3`}>
                <ParagraphBold style={s`text-center`}>
                  {t('harmonia.notViewed')}
                </ParagraphBold>
                <View style={s`w-full h-0.25 bg-text-secondary mt-0.5`} />
              </View>
            );
          }

          const messagePosition =
            m.author === HarmoniaMessageAuthor.user
              ? BubblePosition.Right
              : BubblePosition.Left;
          return (
            <>
              <MessageBubble
                key={m.messageId || `harmoniaMessage#${i}`}
                position={messagePosition}
                dateTime={m.createdAt}
              >
                {m.message}
              </MessageBubble>
              {notViewedComponent}
            </>
          );
        }}
        style={s`flex-1`}
        contentContainerStyle={[s`flex-grow py-4 px-page-padding`, { gap: 8 }]}
        ref={scrollViewRef}
        ListEmptyComponent={
          isListEmpty ? (
            <View style={s`items-center justify-center`}>
              <Image
                source={emptyStateImage}
                style={s`w-3/4 resize-contain h-2/4`}
              />
              <View style={s`mt-4 p-2 rounded-xl bg-card`}>
                <ParagraphSmall style={s`text-center`}>
                  {t('harmonia.emptyMessage')}
                </ParagraphSmall>
              </View>
            </View>
          ) : null
        }
        ListFooterComponent={
          areMessagesLoading ? (
            <ActivityIndicator size="small" color={Config.colorPrimary} />
          ) : null
        }
        ListHeaderComponent={
          isWaitingMessage ? (
            <View style={s`flex-row items-center`}>
              <View
                style={s`justify-center items-center p-1.5 bg-card border border-light-grey rounded-full w-14 h-14`}
              >
                <Image source={conversationLoader} style={s`w-10 h-10`} />
              </View>
              <ParagraphSmall style={s`ml-4 flex-1`}>
                {t('harmonia.message3')}
              </ParagraphSmall>
            </View>
          ) : null
        }
        onEndReached={messagesToShow ? loadPrevMessages : undefined}
      />
    </Layout>
  );
};

export default HarmoniaAssistantScreen;
