import React, { forwardRef } from 'react';
import { View } from 'react-native';
import { s } from 'react-native-wind';
import {
  BottomSheetRef,
  Button,
  ButtonType,
  Paragraph,
  Subtitle,
  UmBottomSheet,
} from '../../../components';
import { useBottomSheetActions } from '../../../hooks/useBottomSheetActions';
import { useLanguage } from '../../../locale';

type GamificationHelpBottomSheetProps = {};

export const GamificationHelpBottomSheet: React.ForwardRefExoticComponent<
  GamificationHelpBottomSheetProps & React.RefAttributes<BottomSheetRef>
> = forwardRef((_, forwardedRef) => {
  const [t] = useLanguage();
  const { bottomSheetRef, closeBottomSheet } =
    useBottomSheetActions(forwardedRef);

  return (
    <UmBottomSheet ref={bottomSheetRef} isContentHeight>
      <Subtitle>{t('leaderboard.helpBottomSheet.title')}</Subtitle>
      <Paragraph style={s`mt-4`}>
        {t('leaderboard.helpBottomSheet.pointDescription')}
      </Paragraph>
      <Paragraph style={s`mt-2`}>
        {t('leaderboard.helpBottomSheet.rewardDescription')}
      </Paragraph>
      <View style={s`flex-row mt-4`}>
        <Button
          buttonType={ButtonType.Tertiary}
          onPress={closeBottomSheet}
          containerStyle={s`flex-1 mr-4`}
        >
          {t('leaderboard.helpBottomSheet.close')}
        </Button>
      </View>
    </UmBottomSheet>
  );
});
