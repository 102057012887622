import {
  SignInRequest,
  TokensResponse,
  SignUpRequest,
  VerifyCodeRequest,
  SignInResponse,
} from './authorization.models';

export interface IAuthorizationApi {
  readonly signIn: (loginBody: SignInRequest) => Promise<SignInResponse>;
  readonly signUp: (signUpBody: SignUpRequest) => Promise<{}>;
  readonly verifyCode: (
    verifyCodeBody: VerifyCodeRequest
  ) => Promise<TokensResponse>;
  readonly refresh: (refreshToken: string) => Promise<TokensResponse>;
}

export class NotImplementedAuthorizationApi implements IAuthorizationApi {
  readonly signIn = () => {
    throw new Error('Not implemented');
  };
  readonly signUp = () => {
    throw new Error('Not implemented');
  };
  readonly verifyCode = () => {
    throw new Error('Not implemented');
  };
  readonly refresh = () => {
    throw new Error('Not implemented');
  };
}
