import { IHttpClient } from '../../services/HttpClient/IHttpClient';
import { IUserApi } from './IUserApi';
import {
  GetUploadUrlResponse,
  GetWhoAmIRequest,
  GetWhoAmIResponse,
  UpdateCountryRequest,
  UpdateProfileRequest,
  UploadImageProfileRequest,
  UserDetails,
} from './user.models';

export class UserApi implements IUserApi {
  constructor(private readonly httpClient: IHttpClient) {}

  public readonly getWhoAmI = (
    req: GetWhoAmIRequest
  ): Promise<GetWhoAmIResponse> => {
    return this.httpClient.get('/user/who-am-i', {
      params: {
        email: req.email,
      },
      isAuthorized: false,
    });
  };

  public readonly getMyDetails = (): Promise<UserDetails> => {
    return this.httpClient.get<UserDetails>('/user/my', {
      isAuthorized: true,
    });
  };

  public readonly uploadProfileImage = async ({
    fileExt,
    localFileUri,
  }: UploadImageProfileRequest): Promise<string> => {
    const imageToUpload = await (await fetch(localFileUri)).blob();
    const { s3PutObjectUrl, getUrl } =
      await this.httpClient.get<GetUploadUrlResponse>(
        '/user/profileimage/getuploadurl',
        {
          params: {
            fileExt,
          },
          isAuthorized: true,
        }
      );
    await fetch(s3PutObjectUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'image/png',
      },
      body: imageToUpload,
    });
    return getUrl;
  };

  public readonly updateMy = (
    req: UpdateProfileRequest | UpdateCountryRequest
  ) => {
    return this.httpClient.put<void>('/user/my', {
      isAuthorized: true,
      body: req,
    });
  };

  public readonly deleteMy = () => {
    return this.httpClient.delete<void>('/user/my', {
      isAuthorized: true,
    });
  };
}
