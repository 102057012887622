import { s } from 'react-native-wind';
import React, { ReactNode } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { LogoIcon } from '../icons/Logo';

export type NavbarAction = {
  icon: ReactNode;
  action: () => void;
};

type ScreenNavbarProps = {
  leftAction?: NavbarAction;
  rightAction?: NavbarAction;
};

export const ScreenNavbar: React.FC<ScreenNavbarProps> = ({
  leftAction,
  rightAction,
}) => {
  return (
    <>
      <View style={styles.wrapper}>
        <View
          style={[
            styles.container,
            s`flex-row py-4 items-center px-page-padding bg-white rounded-bl-3xl rounded-br-3xl`,
          ]}
        >
          {leftAction ? (
            <TouchableOpacity
              style={s`w-7 h-7 justify-center`}
              onPress={leftAction.action}
            >
              {leftAction.icon}
            </TouchableOpacity>
          ) : (
            <View style={s`w-7 h-7`}></View>
          )}
          <View style={s`flex-1 items-center justify-center`}>
            <LogoIcon width={36} height={36} />
          </View>

          {rightAction ? (
            <TouchableOpacity style={s`w-7 h-7`} onPress={rightAction.action}>
              {rightAction.icon}
            </TouchableOpacity>
          ) : (
            <View style={s`w-7 h-7`}></View>
          )}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    overflow: 'hidden',
    paddingBottom: 5,
  },
  container: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.14,
    shadowRadius: 2.22,

    elevation: 3,
  },
});
