import { s } from 'react-native-wind';
import * as React from 'react';
import { View } from 'react-native';

type UmBottomBarProps = {
  children?: React.ReactNode;
};

export const UmBottomBar: React.FC<UmBottomBarProps> = ({ children }) => {
  return (
    <View
      style={s`h-20 w-full border-t border-t-light-grey flex-row px-page-padding items-center`}
    >
      {children}
    </View>
  );
};
