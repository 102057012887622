import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { s } from 'react-native-wind';
import { Button, Layout } from '../../components';
import { TextField } from '../../components/TextField';
import { useLanguage } from '../../locale';
import { useNameController } from './controllers/useNameController';

type NameScreenProps = {};

const NameScreen: React.FC<NameScreenProps> = () => {
  const [t] = useLanguage();

  const { name, updateName, setName } = useNameController();

  return (
    <Layout
      title={t('onboarding.name.title')}
      description={t('onboarding.name.description')}
      showNavbar={false}
      showSeparator={false}
    >
      <TextField
        containerStyle={s`mt-8`}
        placeholder={t('onboarding.name.name')}
        value={name}
        onChangeText={setName}
        autoCapitalize="words"
      />
      <View style={s`flex-1`} />
      <View
        style={[
          s`border-t border-light-grey px-page-padding pt-4`,
          styles.footer,
        ]}
      >
        <Button onPress={updateName}>{t('onboarding.name.next')}</Button>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  footer: {
    marginHorizontal: -27,
  },
});

export default NameScreen;
