import * as React from 'react';
import Pulse from 'react-native-pulse';
import { s } from 'react-native-wind';
import { TouchableOpacity, View } from 'react-native';
import { MessageBadgeIcon } from '../icons';
import { ParagraphSmall } from './ParagraphSmall';
import { Config } from '../config';

type HarmoniaButtonProps = {
  unseenMessages?: string;
  navigateToHarmonia: () => void;
};

const HarmoniaButton: React.FC<HarmoniaButtonProps> = ({
  unseenMessages,
  navigateToHarmonia,
}) => {
  const areUnseenMessages = unseenMessages && unseenMessages !== '0';

  return (
    <View style={s`items-center justify-center`}>
      {areUnseenMessages ? (
        <Pulse
          color={Config.colorPrimary}
          diameter={84}
          numPulses={2}
          duration={1000}
          speed={50}
        />
      ) : null}
      <TouchableOpacity
        onPress={navigateToHarmonia}
        style={s`w-10 h-10 bg-primary rounded-full justify-center items-center p-1`}
      >
        <MessageBadgeIcon size={20} />
        {areUnseenMessages ? (
          <View
            style={[
              s`absolute w-5 h-5 rounded-full bg-danger justify-center items-center`,
              { top: -6, right: -6 },
            ]}
          >
            <ParagraphSmall style={s`text-xs text-white`}>
              {unseenMessages}
            </ParagraphSmall>
          </View>
        ) : null}
      </TouchableOpacity>
    </View>
  );
};

export default HarmoniaButton;
