import { Image, ImageSourcePropType } from 'react-native';
import { Subtitle } from './Subtitle';
import { View } from 'react-native-animatable';
import React from 'react';
import { s } from 'react-native-wind';

type FaceType = 'veryHappy' | 'happy' | 'sad' | 'verySad';

export interface FaceImageProps {
  type: FaceType;
  votes: number;
}

const imagePath: Record<FaceType, ImageSourcePropType> = {
  veryHappy: require('../../assets/very-happy-empty.png'),
  happy: require('../../assets/happy-empty.png'),
  sad: require('../../assets/sad-empty.png'),
  verySad: require('../../assets/very-sad-empty.png'),
};

// TODO: duplication of UmFace
export const FaceImage: React.FC<FaceImageProps> = ({ type, votes }) => {
  return (
    <View style={s`items-center`}>
      <Image style={s`w-16 h-16 resize-contain`} source={imagePath[type]} />
      <Subtitle style={s`text-3xl mt-2`}>{`${votes}`}</Subtitle>
    </View>
  );
};
