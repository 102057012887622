import React from 'react';
import { View, Text, Image, StyleSheet, Linking } from 'react-native';
import { s } from 'react-native-wind';
import { Subtitle } from '../../../components';
import slackUmmyIcon from '../../../../assets/slack-ummy.png';
import slackIcon from '../../../../assets/slack.png';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useLanguage } from '../../../locale';
import { Config } from '../../../config';

interface SlackRequestAccessProps {
  isLoading?: boolean;
}

const SlackRequestAccess = ({ isLoading }: SlackRequestAccessProps) => {
  const [t] = useLanguage();
  return (
    <View style={s`flex flex-col justify-center h-full`}>
      <Image style={styles.image} source={slackUmmyIcon} />
      <Subtitle>{t('slack.requestScreen.title')}</Subtitle>
      <Text style={s`my-3 flex-1`}>{t('slack.requestScreen.paragraph')}</Text>

      {!isLoading && (
        <TouchableOpacity onPress={() => Linking.openURL(Config.slackUrl)}>
          <View
            style={s`flex flex-row items-center rounded-full p-4 justify-center bg-slack`}
          >
            <Image style={styles.icon} source={slackIcon} />
            <Text style={s`font-bold text-md text-white`}>
              {t('slack.requestScreen.cta')}
            </Text>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 180,
    height: 180,
    alignSelf: 'center',
    marginBottom: '20%',
    marginTop: '20%',
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 10,
  },
});

export default SlackRequestAccess;
