import dayjs from 'dayjs';
import React from 'react';
import { Dimensions } from 'react-native';
import {
  G,
  Rect,
  Text,
  Line as SvgLine,
  Circle,
  NumberProp,
} from 'react-native-svg';
import { LineChartDataExtended } from '../../stats.models';

interface TooltipProps {
  x?: (i: number) => number;
  y?: (i: number) => number;
  ticks?: NumberProp[];
  list: LineChartDataExtended[];
  positionX: number;
}
const Tooltip = ({ x, y, ticks, list, positionX }: TooltipProps) => {
  if (positionX < 0) {
    return null;
  }
  const apx = (size = 0) => (Dimensions.get('window').width / 750) * size;

  return (
    <G x={x?.(positionX)} key="tooltip">
      <G
        x={positionX > list.length / 2 ? -apx(300 + 10) : apx(10)}
        y={(y?.(list[positionX].value) ?? 0) - apx(10)}
      >
        <Rect
          y={-apx(24 + 24 + 20) / 2}
          rx={apx(12)}
          ry={apx(12)}
          width={apx(
            dayjs(list[positionX].label).format('dddd DD MMMM').length * 13 + 20
          )}
          height={apx(52)}
          stroke="rgba(254, 190, 24, 0.27)"
          fill="white"
        />

        <Text x={apx(20)} fill="#181c1f" opacity={0.65} fontSize={apx(24)}>
          {dayjs(list[positionX].label).format('dddd DD MMMM')}
        </Text>
      </G>

      <G x={x?.(0)}>
        <SvgLine
          y1={ticks?.[0]}
          y2={ticks?.[Number(ticks?.length)]}
          stroke="#FEBE18"
          strokeWidth={apx(4)}
          strokeDasharray={[6, 3]}
        />

        <Circle
          cy={y?.(list[positionX].value)}
          r={apx(20 / 2)}
          stroke="#fff"
          strokeWidth={apx(2)}
          fill="#FEBE18"
        />
      </G>
    </G>
  );
};

export default Tooltip;
