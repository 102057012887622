import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
import { IconProps } from './types';

type ChartEmptyIconProps = IconProps & SvgProps;

const ChartEmptyIcon = ({
  width,
  height,
  size,
  ...props
}: ChartEmptyIconProps) => (
  <Svg
    viewBox="0 0 512 512"
    width={width || size}
    height={height || size}
    fill="none"
    {...props}
  >
    <Path
      fill="#C2C2C2"
      d="M164.272 449.163H56.356V52.043a9.476 9.476 0 0 1 9.476-9.476h88.965a9.476 9.476 0 0 1 9.476 9.476v397.121l-.001-.001ZM309.958 449.163H202.042V175.546a9.476 9.476 0 0 1 9.476-9.476h88.965a9.476 9.476 0 0 1 9.476 9.476v273.617h-.001Z"
    />
    <Path
      fill="#F4063F"
      d="M300.482 166.07h-29.071a9.475 9.475 0 0 1 9.475 9.476v256.717h29.071V175.546a9.474 9.474 0 0 0-9.475-9.476Z"
    />
    <Path
      fill="#C2C2C2"
      d="M455.644 449.163H347.728v-212.47c0-5.233 4.242-9.476 9.475-9.476h88.965a9.476 9.476 0 0 1 9.476 9.476v212.47Z"
    />
    <Path
      fill="#646D73"
      d="M504.273 469.433H7.727A7.726 7.726 0 0 1 0 461.706V439.99a7.726 7.726 0 0 1 7.727-7.727h496.545a7.726 7.726 0 0 1 7.727 7.727v21.716a7.725 7.725 0 0 1-7.726 7.727Z"
    />
    <Path
      fill="#474F54"
      d="M504.273 432.263h-21.344v37.171h21.344a7.726 7.726 0 0 0 7.727-7.727V439.99a7.726 7.726 0 0 0-7.727-7.727Z"
    />
    <Path
      fill="#A3A3A3"
      d="M154.796 42.567h-29.071a9.475 9.475 0 0 1 9.475 9.475v371.545H56.356v8.677h107.916V52.043a9.476 9.476 0 0 0-9.476-9.476ZM300.482 166.07h-29.071a9.475 9.475 0 0 1 9.475 9.476v248.04h-78.845v8.677h107.916V175.546a9.474 9.474 0 0 0-9.475-9.476ZM446.169 227.219h-29.071a9.475 9.475 0 0 1 9.475 9.475v186.893h-78.845v8.677h107.916v-195.57a9.475 9.475 0 0 0-9.475-9.475Z"
    />
  </Svg>
);
export default ChartEmptyIcon;
