import * as React from 'react';
import { StyleSheet, Text } from 'react-native';
import { View } from 'react-native-animatable';
import { s } from 'react-native-wind';
import { Button, Checkbox, Layout, ParagraphSmall } from '../../components';
import { useLanguage } from '../../locale';
import { usePrivacyController } from './controllers/usePrivacyController';

type PrivacyScreenProps = {};

const PrivacyScreen: React.FC<PrivacyScreenProps> = () => {
  const [t] = useLanguage();

  const {
    openPrivacyPolicy,
    openTermsAndConditions,
    isPrivacyAccepted,
    tooglePrivacy,
    updateTerms,
  } = usePrivacyController();

  return (
    <Layout
      showNavbar={false}
      showSeparator={false}
      title={t('onboarding.privacy.title')}
      isScrollView={false}
      description={t('onboarding.privacy.description')}
    >
      <View style={s`mt-8 flex-row items-center`}>
        <Checkbox
          isChecked={isPrivacyAccepted}
          onCheckboxPress={tooglePrivacy}
        />
        <Text style={s`ml-4 flex-1`}>
          <ParagraphSmall>{t('authentication.acceptTerms1')}</ParagraphSmall>
          <ParagraphSmall
            onPress={openTermsAndConditions}
            style={s`text-primary underline`}
          >
            {t('authentication.termsAndConditions')}
          </ParagraphSmall>
          <ParagraphSmall>{t('authentication.acceptTerms2')}</ParagraphSmall>
          <ParagraphSmall
            onPress={openPrivacyPolicy}
            style={s`text-primary underline`}
          >
            {t('authentication.privacyPolicy')}
          </ParagraphSmall>
          <ParagraphSmall>{t('authentication.acceptTerms3')}</ParagraphSmall>
        </Text>
      </View>
      <View style={s`flex-1`} />
      <View
        style={[
          s`border-t border-light-grey px-page-padding pt-4`,
          styles.footer,
        ]}
      >
        <Button isDisabled={!isPrivacyAccepted} onPress={updateTerms}>
          {t('onboarding.privacy.confirm')}
        </Button>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  footer: {
    marginHorizontal: -27,
  },
});

export default PrivacyScreen;
