import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Linking } from 'react-native';
import { useUpdateProfileMutation } from '../../../hooks/api/user.api';
import { AppNavigation } from '../../../navigation/core/types';
import {
  ONBOARDING_NAME,
  ONBOARDING_PRIVACY_TERMS,
} from '../../../navigation/onboarding/onboarding.types';

export const usePrivacyController = () => {
  const [isPrivacyAccepted, setIsPrivacyAccepted] = React.useState(false);
  const navigation =
    useNavigation<AppNavigation<typeof ONBOARDING_PRIVACY_TERMS>>();

  const { mutateAsync: updateProfile } = useUpdateProfileMutation();

  const openTermsAndConditions = React.useCallback(() => {
    Linking.openURL('https://qomprendo.com/terms-and-conditions');
  }, []);

  const openPrivacyPolicy = React.useCallback(() => {
    Linking.openURL('https://qomprendo.com/privacy-policy');
  }, []);

  const tooglePrivacy = React.useCallback(() => {
    setIsPrivacyAccepted((prev) => !prev);
  }, []);

  const updateTerms = React.useCallback(async () => {
    await updateProfile({
      terms: isPrivacyAccepted,
    });
    navigation.navigate(ONBOARDING_NAME);
  }, [isPrivacyAccepted, navigation, updateProfile]);

  return {
    openPrivacyPolicy,
    openTermsAndConditions,
    tooglePrivacy,
    isPrivacyAccepted,
    updateTerms,
  };
};
