import { s } from 'react-native-wind';
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { Subtitle, UmFaces } from '../../../components';
import { Team } from '../../../api/team/team.models';
import { FeedbackValue } from '../../../api/feedback/feedback.models';

type TeamCardProps = {
  team: Team;
  onFacePress: (vote: FeedbackValue, teamId: string) => Promise<void>;
  selectedVote?: FeedbackValue;
  containerStyle?: StyleProp<ViewStyle>;
};

export const TeamCard: React.FC<TeamCardProps> = ({
  team,
  containerStyle,
  onFacePress,
  selectedVote = null,
}) => {
  const onPressFace = React.useCallback(
    async (feedbackValue: FeedbackValue) => {
      onFacePress(feedbackValue, team.id);
    },
    [onFacePress, team.id]
  );

  return (
    <View
      style={[s`border border-text-secondary rounded-2xl p-4`, containerStyle]}
    >
      <Subtitle>{team.name}</Subtitle>
      <UmFaces
        onPressFace={onPressFace}
        containerStyle={s`mt-4`}
        selectedVote={selectedVote}
      />
    </View>
  );
};
