import React from 'react';
import {
  Button,
  ButtonType,
  Description,
  Layout,
  Subtitle,
} from '../../components';
import { RemoteConfigError } from '../../hooks/useRemoteConfig';
import { s } from 'react-native-wind';
import { View } from 'react-native';

interface RemoteConfigScreenProps {
  error: RemoteConfigError;
}

const RemoteConfigScreen = ({ error }: RemoteConfigScreenProps) => {
  return (
    <Layout
      safeAreaEdges={['bottom', 'left', 'right', 'top']}
      showNavbar={false}
    >
      <View style={s`flex-col flex items-center h-full`}>
        <View style={s`flex-1`} />
        <Subtitle style={s`mb-4`}>{error.title}</Subtitle>
        <Description>{error.message}</Description>
        <View style={s`flex-1`} />
        {error.cta && error.ctaAction && (
          <Button
            buttonType={ButtonType.Primary}
            containerStyle={s`mt-4 w-full`}
            onPress={error.ctaAction}
          >
            {error.cta}
          </Button>
        )}
        {error.secondaryCta && error.secondaryCtaAction && (
          <Button
            buttonType={ButtonType.Primary}
            containerStyle={s`mt-4 w-full`}
            onPress={error.secondaryCtaAction}
          >
            {error.secondaryCta}
          </Button>
        )}
      </View>
    </Layout>
  );
};

export default RemoteConfigScreen;
