/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */

import './BottomSheet.css';

import React from 'react';
import type { BottomSheetProps } from '@gorhom/bottom-sheet';
import type { BottomSheetMethods } from '@gorhom/bottom-sheet/lib/typescript/types';
import { BottomSheet as ReactSprintBottomSheet } from 'react-spring-bottom-sheet';
import { StyleProp } from 'react-native';

export type { BottomSheetProps };

export const BottomSheet: React.ForwardRefExoticComponent<
  Omit<BottomSheetProps, 'style'> & {
    style: StyleProp<any>;
    enablePanDownToClose?: boolean;
    index?: number;
  } & React.RefAttributes<BottomSheetMethods>
> = React.forwardRef(
  ({ children, style, onClose, enablePanDownToClose, index }, forwardedRef) => {
    const [open, setOpen] = React.useState(index === 0);

    const close = React.useCallback(() => {
      setOpen(false);

      if (onClose) {
        onClose();
      }
    }, [onClose]);

    React.useImperativeHandle(
      forwardedRef,
      () => ({
        close: () => setOpen(false),
        collapse: () => {},
        expand: () => setOpen(true),
        forceClose: () => {},
        snapToIndex: () => {},
        snapToPosition: () => {},
      }),
      []
    );

    return (
      <ReactSprintBottomSheet
        style={style}
        open={open}
        snapPoints={({ minHeight }) => minHeight}
        onDismiss={close}
        blocking={!enablePanDownToClose}
      >
        {children}
      </ReactSprintBottomSheet>
    );
  }
);
